import { HttpParams } from '@angular/common/http';

/**
 * Helper function that transforms objects with basic types or arrays of the basic types to http parameters.
 *
 * @param {[key: string]: string|string[]|number|number[]|boolean|boolean[]} obj - the parameters objects -
 * it has the type unknown as this way we do enable to feed in any defined object and check for the proper
 * typing within the function.
 * */
export function objectToHttpParams(obj: unknown): HttpParams {
  let params = new HttpParams();
  if (obj && typeof obj === 'object' && !Array.isArray(obj)) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        params = params.set(key, obj[key].toString());
      }
    }
  } else {
    console.error(
      'ERROR in function objectToHttpParams: ' +
        'Object type should be compatible with {[key: string]: string|string[]|number|number[]|boolean|boolean[]}'
    );
  }
  return params;
}
