import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TabContentComponent } from './tab-content/tab-content.component';
import { TabComponent } from './tab/tab.component';
import { TabsComponent } from './tabs.component';

@NgModule({
  declarations: [TabsComponent, TabComponent, TabContentComponent],
  imports: [CommonModule],
  exports: [TabComponent, TabsComponent, TabContentComponent],
})
export class TabsModule {}
