import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State, WARRANTY_TRACKER_FEATURE_KEY, warrantyAdapter } from '../reducers/warranty-tracker.reducer';
import { WarrantyTrackerInterface } from '@twaice-fe/shared/models';

export const getWarrantyTrackerState = createFeatureSelector<State>(WARRANTY_TRACKER_FEATURE_KEY);

const { selectAll, selectEntities } = warrantyAdapter.getSelectors();

export const getWarrantyDataLoaded = createSelector(getWarrantyTrackerState, (state: State) => state.isLoading);

export const getWarrantyTrackerError = createSelector(getWarrantyTrackerState, (state: State) => state.error);

export const getWarrantyTrackerList = createSelector(getWarrantyTrackerState, (state: State) => {
  let data = selectAll(state);

  if (state.config.searchString) {
    data = data.filter((item) => searchInAllKeys(item, state.config.searchString ?? ''));
  }

  if (state.config.order) {
    const [key, direction] = state.config.order.split(':');
    data = sortDataByConfig(data, key as keyof WarrantyTrackerInterface, direction as 'asc' | 'desc');
  }

  return data;
});

export const getWarrantyTrackerEntities = createSelector(getWarrantyTrackerState, (state: State) => selectEntities(state));

export const getSelectedId = createSelector(getWarrantyTrackerState, (state: State) => state.selectedId);

export const getSelected = createSelector(getWarrantyTrackerEntities, getSelectedId, (entities, selectedId) =>
  selectedId ? entities[selectedId] : undefined
);

function searchInAllKeys(item: WarrantyTrackerInterface, searchString: string): boolean {
  if (!searchString) return false;

  const formattedSearchString = searchString.trim().toLowerCase();

  const valueStr = String(item.fullName).toLowerCase();
  return valueStr.includes(formattedSearchString);
}

function sortDataByConfig(
  data: WarrantyTrackerInterface[],
  key: keyof WarrantyTrackerInterface,
  direction: 'asc' | 'desc'
): WarrantyTrackerInterface[] {
  const isAscending = direction === 'asc';

  return [...data].sort((a, b) => {
    const valA = a[key];
    const valB = b[key];

    if (valA === undefined) return isAscending ? -1 : 1;
    if (valB === undefined) return isAscending ? 1 : -1;

    if (typeof valA === 'number' && typeof valB === 'number') {
      return isAscending ? valA - valB : valB - valA;
    }

    if (typeof valA === 'string' && typeof valB === 'string') {
      return isAscending
        ? valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' })
        : valB.localeCompare(valA, undefined, { numeric: true, sensitivity: 'base' });
    }

    return 0;
  });
}
