import { AggregationType, KpiAlertModels } from '@twaice-fe/shared/models';
import { BarChartData } from '@twaice-fe/frontend/shared/utilities';

export interface KPIChartData {
  data?: BarChartData;
  alert?: KpiAlertModels;
}

export interface KPIDistributionData {
  kpiName: DistributionKpisEnum;
  kpiChartData?: number[];
  kpiTableIds?: Record<string, string>;
  kpiTableValues?: Record<string, number>;
}

export interface KPITableData {
  [key: string]: string | number;
}

// the unused keys: AVG, COUNT, NONE, SUM
// are put to prevent tslint error and to better understand how to add extra info kpis in the future
export const chartInfoTitleMapping = {
  [AggregationType.MAX]: 'Highest',
  [AggregationType.MIN]: 'Lowest',
  [AggregationType.AVG]: '',
  [AggregationType.COUNT]: '',
  [AggregationType.NONE]: 'Spread',
  [AggregationType.SUM]: '',
};

export interface FeHealthContainer {
  containerID?: string;
  containerName?: string;
  value: number;
}

export enum DistributionKpisEnum {
  STATE_OF_HEALTH_CAPACITY = 'sohC',
  EQUIVALENT_FULL_CYCLES = 'efc',
  CELL_VOLTAGE_SPREADS = 'cellVoltageSpread',
  DIRECT_CURRENT_RESISTANCE = 'dcr',
  MODULE_TEMPERATURE_SPREADS = 'moduleTemperatureSpread',
}

export interface FilteredHealthKpiData {
  selected: KpiDistributionInterface[];
  disabled: KpiDistributionInterface[];
}

export interface KpiDistributionInterface {
  containerID: string;
  containerName: string;
  sohC: string;
  efc: string;
  dcr: string;
  moduleTemperatureSpread: string;
  cellVoltageSpread: string;
}
