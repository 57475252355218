import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { SideNavItemComponent } from './side-nav-item/side-nav-item.component';
import { SideNavLayoutComponent } from './side-nav-layout.component';
import { SideNavSeparatorComponent } from './side-nav-separator/side-nav-separator.component';
import { SideNavComponent } from './side-nav/side-nav.component';

@NgModule({
  declarations: [SideNavLayoutComponent, SideNavItemComponent, SideNavSeparatorComponent, SideNavComponent],
  imports: [CommonModule, RouterModule, SvgIconModule],
  exports: [SideNavLayoutComponent, SideNavItemComponent, SideNavSeparatorComponent, SideNavComponent],
})
export class SideNavLayoutModule {}
