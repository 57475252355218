import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  INCIDENTS_RULES_FEATURE_KEY,
  State,
  incidentsDefinitionAdapter,
  incidentsDelaysAdapter,
  incidentsThresholdAdapter,
} from '../reducers/incidents-rules.reducer';

export const getIncidentRulesState = createFeatureSelector<State>(INCIDENTS_RULES_FEATURE_KEY);
export const getIncidentThresholdState = createSelector(getIncidentRulesState, (state) => state.thresholdRules);
export const getIncidentRulesDefinitionState = createSelector(getIncidentRulesState, (state) => state.incidentRulesDefinitions);
export const getIncidentRulesDelaysState = createSelector(getIncidentRulesState, (state) => state.incidentDelayNotifications);

const thresholdSelectors = incidentsThresholdAdapter.getSelectors();
const definitionSelectors = incidentsDefinitionAdapter.getSelectors();
const delaysSelectors = incidentsDelaysAdapter.getSelectors();

export const getIncidentRulesList = createSelector(getIncidentThresholdState, thresholdSelectors.selectAll);
export const getIncidentRulesDefinitionList = createSelector(getIncidentRulesDefinitionState, definitionSelectors.selectAll);
export const getIncidentRulesDelaysList = createSelector(getIncidentRulesDelaysState, delaysSelectors.selectAll);
