import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import mixpanel from 'mixpanel-browser';
import { filter, of, switchMap, tap } from 'rxjs';

import { AuthService } from '@twaice-fe/frontend/shared/services';
import { authActions, userTrackingActions } from '../actions';
import { configsSelectors } from '../selectors';

@Injectable()
export class UserTrackingEffects {
  setup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userTrackingActions.mixpanelSetup),
      switchMap(() => this.authService.getUserObservable()),
      filter((user) => !!user),
      tap((user) => {
        mixpanel.identify(user.username);
        mixpanel.register_once({
          customer_id: user.customerID,
          internal_user: user.username?.includes('@twaice.com'),
        });
        mixpanel.people.set('$name', user.username);
        mixpanel.people.set('$email', user.username);
        mixpanel.set_group('customer_id', [user.customerID]);
      }),
      switchMap(() => this.store.select(configsSelectors.getConfigsState)),
      filter((state) => !!state?.customerType),
      tap((state) => {
        mixpanel.register_once({
          customer_type: state.customerType,
          available_solutions: state.solutions,
          customer_bk: state.customerBk,
        });
        mixpanel.set_group('customer_type', [state.customerType]);
      }),
      switchMap(() => of(userTrackingActions.mixpanelEventSuccess()))
    )
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.loginSuccess),
      tap(() => mixpanel.track('Log In')),
      switchMap(() => of(userTrackingActions.mixpanelEventSuccess()))
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.logout),
      tap(() => {
        mixpanel.track('Log Out');
        mixpanel.reset();
      }),
      switchMap(() => of(userTrackingActions.mixpanelEventSuccess()))
    )
  );

  signUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.setInitialPasswordSuccess),
      tap(() => mixpanel.track('Sign Up')),
      switchMap(() => of(userTrackingActions.mixpanelEventSuccess()))
    )
  );

  constructor(
    private readonly actions$: Actions,
    protected store: Store,
    private authService: AuthService
  ) {}
}
