/* eslint-disable @typescript-eslint/naming-convention -- API side defined naming */
import { TWResponse } from './response.models';
import { IsBooleanString, IsOptional } from '@twaice-fe/shared/utilities';

export class PredictionDataTile {
  min: number;
  max: number;
  expected: number;
}

export class PredictionError {
  code: number;
  message: string;
}

export class PredictionWidgetError {
  error_code: number;
  error_message: string;
}

export class SohEstimationData {
  soh_upper_bound: number[];
  soh_lower_bound: number[];
  time: number[];
  soh: number[];
}

export class PredictionInputDateRange {
  prediction_input_start_date: number;
  prediction_input_end_date: number;
}

export class PredictionData {
  estimation: SohEstimationData;
  time: number[];
  soh: number[];
  soh_upper_bound: number[];
  soh_lower_bound: number[];

  prediction_input_date_range?: PredictionInputDateRange;
  confidence?: number;
  soh_end_of_life_threshold?: number;
  end_of_warranty?: number;

  end_of_life?: PredictionDataTile;
  remaining_capacity?: PredictionDataTile;
  remaining_distance?: PredictionDataTile;

  error?: PredictionError;
}

export class PredictionMetadata {
  prediction_model_id: string;
  sensor_id: string;
  system_id: string;
  prediction_id: string;
  start: number;
  uploaded_at: string;
}

export class Prediction extends PredictionMetadata {
  data: PredictionData;
}

export class PredictionResponse {
  status?: string;
  data: Prediction;
}

export class PredictionListResponse {
  status?: string;
  data: Prediction[];
}

export class PredictionSoHSeries {
  x_axis: number[];
  soh: number[];
  soh_lower_bound: number[];
  soh_upper_bound: number[];
  axis_type: string;
}

export class PredictionSoHWidget {
  prediction_id: string;
  soh_estimation: PredictionSoHSeries;
  soh_prediction?: PredictionSoHSeries;
  error: PredictionWidgetError;
  confidence: number;
  soh_end_of_life_threshold: number;
}

export class PredictionWidgetResponse {
  status?: string;
  data: PredictionSoHWidget;
}

export class PredictionDataRequestParameters {
  systemID: string;

  containerID?: string;

  sensorID?: string;

  modelID?: string;

  @IsOptional()
  @IsBooleanString()
  estimationOnly?: boolean;

  @IsOptional()
  predictionID?: string;
}

export class PredictionModel {
  system_id: string;
  container_id: string;
  container_name: string;
  prediction_model_id: string;
  prediction_model_name: string;
  sensor_id: string;
  sensor_name: string;
  prediction_id?: string;
}

export class PredictionModelListResponse extends TWResponse {
  data: PredictionModel[];
}

export class PredictionModelResponse extends TWResponse {
  data: PredictionModel;
}

export class PredictionInfo {
  title: string;
  measurand?: string;
  expected?: number;
  min?: number;
  max?: number;
  precision?: number;
}

/* Items compatible with the new  */

export class DisplayData {
  timestamps: number[];
  values: number[];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  valuesUB?: number[];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  valuesLB?: number[];
  precision?: number;
}

export class PredictionSet {
  estimation?: DisplayData;
  prediction?: DisplayData;
}
