import { Injectable } from '@angular/core';
import { T } from '@transifex/angular';

@Injectable()
export class LoadingTipsService {
  @T('Leverage “Analysis” to have a better understanding of the incident with asset behaviour view over time.', {
    _key: 'shared.components.loading-tips.mobility-single-alert-one',
  })
  private mobilitySingleAlertTipOne: string;

  @T('“Analysis” shows you insights to understand if the vehicle behaviour is in critical conditions zone over a period.', {
    _key: 'shared.components.loading-tips.mobility-single-alert-two',
  })
  private mobilitySingleAlertTipTwo: string;

  @T('Check incident history, if the asset consistently appear here you can identify safety trends and faulty vehicles', {
    _key: 'shared.components.loading-tips.mobility-single-alert-three',
  })
  private mobilitySingleAlertTipThree: string;

  private tips: Record<string, string[]>;

  constructor() {
    this.tips = {
      ENERGY_HEALTH: [
        'TWAICE SoH is a dynamic metric so it may gradually increase over time.',
        'Voltage and SoC imbalances are often the root cause of performance issues.',
        'TWAICE DCR is a normalized metric that takes influences of both SoC and temperature into account',
      ],
      MOBILITY_SINGLE_ALERT: [this.mobilitySingleAlertTipOne, this.mobilitySingleAlertTipTwo, this.mobilitySingleAlertTipThree],
    };
  }

  getTips(identifier: 'ENERGY_HEALTH' | 'MOBILITY_SINGLE_ALERT'): string[] {
    return this.tips[identifier] ?? [];
  }
}
