export function hexToRgb(hex: string, transparency?: number) {
  hex = hex.substring(1);
  const bigint = parseInt(hex, 16);
  /* eslint-disable no-bitwise -- we actually want to do bitwise operation here  */
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  /* eslint-enable no-bitwise  */
  return transparency ? `rgba(${r}, ${g}, ${b}, ${transparency})` : `rgb(${r}, ${g}, ${b})`;
}
