<div class="min-w-[450px]">
  <div class="flex items-center py-24 px-24" mat-dialog-title>
    <twaice-fe-svg-icon name="icon_tree" class="pt-4 mr-8" size="24px" />
    <h4 class="m-0">Storage metadata</h4>
  </div>

  <mat-divider></mat-divider>
  <div mat-dialog-content class="flex flex-col gap-40 py-24">
    <div class="flex items-center gap-40">
      <div class="flex flex-col justify-start">
        <p class="font-normal text-sm leading-base text-tertiary-text m-0">Location</p>
        <p class="text-base font-normal leading-lg text-primary-text">{{ data?.location ?? '--' }}</p>
      </div>

      <div class="flex flex-col justify-start">
        <p class="font-normal text-sm leading-base text-tertiary-text m-0">Start</p>
        <p class="text-base font-normal leading-lg text-primary-text">
          {{ data?.commercialOperationDate ?? '--' }}
        </p>
      </div>
    </div>

    <div>
      <h5 class="text-tertiary-text">Energy setup</h5>
      <div class="flex items-center gap-40">
        <div class="flex flex-col justify-start">
          <p class="font-normal text-sm leading-base text-tertiary-text m-0">Initial Capacity</p>
          <p class="text-base font-normal leading-lg text-primary-text">
            {{ data?.nominalEnergyCapacity ?? '--' }}
          </p>
        </div>

        <div class="flex flex-col justify-start">
          <p class="font-normal text-sm leading-base text-tertiary-text m-0">Power</p>
          <p class="text-base font-normal leading-lg text-primary-text">{{ data?.maximumPower ?? '--' }}</p>
        </div>
      </div>
    </div>

    <div *ngIf="data?.containersInfo?.length > 0">
      <h5 class="text-tertiary-text">Anatomy</h5>
      <div class="flex items-center gap-40">
        <div class="flex flex-col justify-start" *ngFor="let container of data.containersInfo | keyvalue: keepOrder">
          <p class="font-normal text-sm leading-base text-tertiary-text m-0">{{ container.value.name }}</p>

          <twaice-fe-data-display
            class="text-base font-normal leading-lg text-primary-text"
            [value]="container.value.count"
            numberFormat="1.0-0"
            notAvailableText="-"
            data-test="nav-menu-container-item-value"
          ></twaice-fe-data-display>
        </div>
      </div>
    </div>
  </div>
</div>
