import { Action, createReducer, on } from '@ngrx/store';
import * as ConfigsActions from '../actions/config.actions';

import { ConfigsInterface, CustomerType, FeatureFlagsEnum, Solution } from '@twaice-fe/shared/models';

export const CONFIGS_FEATURE_KEY = 'configs';

export interface State extends ConfigsInterface {
  featureFlags: string[] | FeatureFlagsEnum[];
  solutions: Solution[];
  customerType?: CustomerType;
  baseUrl?: string;
  customerBk?: string;
}

export const initialState: State = {
  featureFlags: [],
  solutions: [],
};

const configReducer = createReducer(
  initialState,
  on(ConfigsActions.loadFeatureFlagsSuccess, (state, { featureFlags }) => ({
    ...state,
    featureFlags: [...featureFlags],
  })),
  on(ConfigsActions.loadSolutionConfigSuccess, (state, { solutions }) => ({
    ...state,
    solutions: [...solutions],
  })),
  on(ConfigsActions.loadConfigSuccess, (state, { solutions, featureFlags, customerType, baseUrl, customerBk }) => ({
    ...state,
    solutions: [...solutions],
    featureFlags: [...featureFlags],
    customerType,
    baseUrl,
    customerBk,
  }))
);
export function reducer(state: State | undefined, action: Action) {
  return configReducer(state, action);
}
