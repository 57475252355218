/**
 * Convert ms to time passed
 * @param ms
 * @returns 1day || 4months ...
 */
export const msToTime = (ms: number) => {
  const seconds = Number((ms / 1000).toFixed(1));
  const minutes = Number((ms / (1000 * 60)).toFixed(0));
  const hours = Number((ms / (1000 * 60 * 60)).toFixed(0));
  const days = Number((ms / (1000 * 60 * 60 * 24)).toFixed(0));
  const months = Number((ms / (1000 * 60 * 60 * 24 * 30)).toFixed(0));
  if (seconds < 60) return seconds + ' Secs';
  if (minutes < 60) return minutes + ' Mins';
  if (hours < 24) return hours + ' hrs';
  if (days < 30) return `${days} ${days === 1 ? 'day' : 'days'}`;
  return `${months} ${months > 1 ? 'months' : 'month'}`;
};

/**
 * format a date object to a string with "HH:MM:SS" format
 * @param date
 * @returns "HH:MM:SS" format
 */
export const formatTime = (date: Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false, // Use 24-hour format
  };
  return new Intl.DateTimeFormat('de-DE', options).format(date);
};

export const getRandomDelay = ({ minDelay, maxDelay }) => minDelay + (maxDelay - minDelay) * Math.random();
