export const SYSTEM_ID_EISENACH = '0bfda070-09be-4152-ae99-9f44d7ca6d55';
export const SYSTEM_ID_EUREF_ESS = 'd23cee8c-c744-4879-9664-1ed8306fd1dc';
export const SYSTEM_ID_ICKING_I = 'd4297ee9-9e7c-487f-a06c-80b7d459785d';
export const SYSTEM_ID_ICKING_II = 'a7f366d5-05b8-4e13-a61b-c1127b238cbd';
export const SYSTEM_ID_INDUSTRY_I = '94998b29-6d02-4d04-8bd8-7f0c318fb845';
export const SYSTEM_ID_LILY = 'c9087033-d2b7-426c-a3a7-adb37ee8af3f';
export const SYSTEM_ID_SMAREG_I = '2ea2a975-e97f-4789-b0cb-e9af75861eec';
export const SYSTEM_ID_SMAREG_II = 'c61d5bad-fcc6-4e7a-adda-2f6b1c720393';
export const SYSTEM_ID_SMAREG_3 = 'cee6b35c-43ec-4294-b35a-efc4604d751a';
export const SYSTEM_ID_SMAREG_4 = '13d30c3c-0788-4cbd-9bf6-d5f8861dc18f';
export const SYSTEM_ID_SMAREG_5 = '3be12ad2-ad90-4734-9e68-c24be3bf52ed';
export const SYSTEM_ID_SMAREG_6 = 'b85aa105-51f8-4953-874e-d7a2a01171ef';
export const SYSTEM_ID_SMAREG_7 = '0aaffb0b-24f5-44a9-8667-6ff6b44dbe5d';
export const SYSTEM_ID_SMAREG_8 = '1aeda5fc-9965-461d-a19e-a510ce15d9de';
export const SYSTEM_ID_SMAREG_9 = '26cbd611-a696-4a09-80ee-52d562053bc3';
export const SYSTEM_ID_TAUTENHAIN = '8620c508-d39b-481d-828e-e28c03889c09';
export const SYSTEM_ID_TWAICE = '5ec502d6-17f5-485f-a528-e2a5c8037c7e';
export const SYSTEM_ID_UFFING = '08b1404b-7d2e-4d3e-b961-dc55aac4d1ec';
export const SYSTEM_ID_WESSLING_I = 'cf2a8e60-7344-4771-b5c7-ce76473ca3b4';

export const SYSTEM_ID_SOLAREDGE = 'c71ab15f-46db-42a9-a71b-c61a23d459c2';
export const SYSTEM_ID_PERCIVAL = '89b244e1-e41f-489d-9c17-11eadb5855cf';
export const SYSTEM_ID_MUSTANG = 'f52811ac-5e21-4f98-95c7-bf2e6d67509f';
export const SYSTEM_ID_LAZARUS = '737704f0-9e3f-418f-8bf6-e9abd1515a26';
export const SYSTEM_ID_LUNA = '53d9fca3-1ad3-403b-8f62-7c461ecd0776';
export const SYSTEM_ID_PORTERSTOWN = '3ac664a8-a426-4fa6-88a9-0109e1420964';
export const SYSTEM_ID_CAMERON = '73bcb143-fbf5-4fea-a525-0fd46d463b17';
export const SYSTEM_ID_TRAFIGURA = '597fb142-14f6-4a30-9772-8ba8c110b419';

export const SYSTEM_ID_ESSENT = 'b09aa5ec-4196-43b4-ac6f-8f4d722e86a6';
export const SYSTEM_ID_RTB = '334bc28a-c8ff-4981-91f0-1ae4cf121243';
export const SYSTEM_ID_HLI = '59c38156-f4d2-433b-a526-ca178781ea82';
export const SYSTEM_ID_SWHWABMUENCHEN = 'b896b934-a7d1-4ea2-9638-9e8d51b077e6';
export const SYSTEM_ID_IPHOFEN = 'cbee817e-0a75-4454-bc42-9b40f9dc8113';
export const SYSTEM_ID_TIR_JON = '51de8d22-a975-4a6f-ad9f-51a7a2cc8a6f';
export const SYSTEM_ID_HIRWAUN = '7d7c9c6a-794d-44d8-97ce-0eaa9b5b289c';
export const SYSTEM_ID_PURE_ENERGIE_1 = 'f72e7d98-e712-4f6c-9699-0c283f5baa75';

export const SYSTEM_ID_ENERGY_DEMO = 'c9087033-d2b7-426c-a3a7-adb37ee8af3f'; // Birch
export const SYSTEM_ID_ENERGY_DEMO_UK = '3b56a044-5cf5-4d83-b91b-390759e3bfca'; //Oak
export const SYSTEM_ID_ENERGY_US_DEMO_1 = '6c571de8-d6c0-4b1e-9a65-9d73ee5995be'; // Elm
export const SYSTEM_ID_ENERGY_US_DEMO_2 = 'd47fd013-2f8f-4bda-a3ed-25c0d7a39f52'; // Cactus
export const SYSTEM_ID_ENERGY_US_DEMO_3 = '8e6b10d9-9e3a-4cae-bd27-77e274d008f2'; //Redwood

export const CUSTOMER_BK_ENERGY_DEMO = 'twaice-energy-demo';
