import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { actions, selectors } from '@twaice-fe/frontend/shared/store';
import { extractAllParamsFromRouter } from '@twaice-fe/frontend/shared/utilities';
import { OverviewSystem, Sensor, System, TwResponse, UiConfig } from '@twaice-fe/shared/models';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { INTERNAL_UI_CONFIG_OVERRIDE, SKIP_REQUESTED_SYSTEMS_INTERCEPTOR_HEADER } from '@twaice-fe/shared/constants';
import { authSelectors } from 'libs/frontend/shared/store/src/selectors';
import { BehaviorSubject, Observable, Subscription, combineLatest, firstValueFrom } from 'rxjs';
import { distinctUntilChanged, filter, first, map, skipWhile, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
const { systemSelectors } = selectors;
const { systemActions } = actions;

@Injectable({
  providedIn: 'root',
})
export class SystemsService implements OnDestroy {
  userSubscription: Subscription;

  private systemList$: Observable<OverviewSystem[]> = new BehaviorSubject<OverviewSystem[]>([]);
  private systemUiConfig: BehaviorSubject<UiConfig> = new BehaviorSubject<UiConfig>(null);
  private uiConfig: UiConfig;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    protected store: Store
  ) {
    this.systemList$ = this.store.select(systemSelectors.getSystemList);
    this.updateCurrentSystemUiConfiguration();

    combineLatest([this.systemList$, this.route.params, this.store.select(systemSelectors.getSelected)])
      .pipe(
        skipWhile(() => !this.authService.isLoggedIn),
        first(([systemList]) => !!(systemList && systemList.length))
      )
      .subscribe(([systemList]) => {
        const params = extractAllParamsFromRouter(this.router);
        const systemBk = params['systemBk'];
        // if the route still contains a legacy system ID query param we use it and set that system as active
        const legacySystemId = new URLSearchParams(window.location.search).get('systemID');

        if (!systemBk) return this.setCurrentSystem(systemList.find((s) => s.id === legacySystemId) || systemList[0]);

        const systemMatch = systemList.find((system) => system.systemBk === systemBk);

        if (systemMatch) {
          this.setCurrentSystem(systemMatch);
          return;
        }

        alert('ERROR: Invalid URL parameters! You tried to access a system that your user credentials do not have access to.');

        return this.store.dispatch(systemActions.selectSystem({ systemId: systemList[0].id }));
      });

    this.authService.waitForAuthComplete().then(() => {
      this.userSubscription = this.authService
        .getUserObservable()
        .pipe(distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)))
        .subscribe((user) => {
          if (!user) return;
          this.store.dispatch(systemActions.fetchSystems({ includeMetadata: true }));
        });
    });
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  fetchSystems({ page, limit, ...includes }, fetchAll: boolean = false): Observable<TwResponse<OverviewSystem>> {
    try {
      const options = {
        params: { page, limit, ...includes },
        ...(fetchAll ? { headers: { [SKIP_REQUESTED_SYSTEMS_INTERCEPTOR_HEADER]: 'true' } } : {}),
      };
      return this.http.get<TwResponse<OverviewSystem>>('systems', options);
    } catch (error) {
      this.router.navigate(['/']);
      const snackBarRef = this.snackBar.open(
        'Error occurred while trying to get a system list. Logging out might resolve it: ',
        'Log out',
        {
          verticalPosition: 'top',
        }
      );

      snackBarRef.onAction().subscribe(() => this.handleLogout());
    }
  }

  async handleLogout() {
    this.resetConfig();
    await this.authService.logout();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchSystemEfcData = (params: any) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return this.http.get<TwResponse<any>>('systems/efc-data', { params });
    } catch (error) {
      throw Error(error);
    }
  };

  getSystemList(): Observable<System[]> {
    return this.systemList$;
  }

  getCurrentSystem(): Observable<System> {
    return this.store.select(systemSelectors.getSelected).pipe(filter((system) => !!system));
  }

  getCurrentTopLevelContainerId(): Observable<string> {
    return this.store.select(systemSelectors.getSelected).pipe(map((system) => system.rootContainerId));
  }

  async setCurrentSystem(system: System) {
    if (!system) return;

    const current = await firstValueFrom(this.store.select(systemSelectors.getSelected));

    // only set the next value if the system actually changes(or you can trigger unnecessary reloading)
    if (!current || current.id !== system.id) {
      this.store.dispatch(systemActions.selectSystem({ systemId: system.id }));
    }

    // not to reset the UI config if UIConfig is already set
    if (!this.uiConfig) {
      this.updateCurrentSystemUiConfiguration();
    } else {
      //updateQueryParameter(this.router, this.route, ['systemID'], [system.id]);
    }
  }

  getCurrentSystemIdObservable(): Observable<string> {
    return this.store.select(systemSelectors.getSelectedId).pipe(filter((id) => id !== null));
  }

  /*
   * A function that returns a promise of a string for system ID. We want it to be a promise rather then observable, since we want it to
   * provide onetime value to the current user, but we want to wait for the value to actually be returned by the http request
   * */
  getCurrentSystemId(): Promise<string> {
    return firstValueFrom(this.getCurrentSystemIdObservable());
  }

  getCurrentSystemUiConfiguration(): Promise<UiConfig> {
    if (!this.systemUiConfig.getValue()) {
      return this.systemUiConfig
        .pipe(first((system) => system !== null))
        .toPromise()
        .catch((error) => {
          console.error('Router processor', error);
          this.router.navigate(['/missing-configuration']);
        })
        .then((config: UiConfig) => config);
    } else {
      return Promise.resolve(this.systemUiConfig.getValue());
    }
  }

  getSystemUiConfiguration(): Observable<UiConfig> {
    return this.systemUiConfig.asObservable();
  }

  updateCurrentSystemUiConfiguration(): void {
    const uiConfigOverride = JSON.parse(localStorage.getItem(INTERNAL_UI_CONFIG_OVERRIDE));
    if (uiConfigOverride) {
      this.uiConfig = uiConfigOverride.uiConfig;
      this.systemUiConfig.next(uiConfigOverride.uiConfig);
      return;
    }

    this.store
      .select(authSelectors.getUser)
      .pipe(
        filter((user) => !!user),
        switchMap((user) =>
          this.http.get<UiConfig>(
            `configuration?customerBK=${user['attributes']['custom:customer_bk'] || user['attributes']['custom:customer_id']}`
          )
        )
      )
      .subscribe({
        next: (config) => {
          this.uiConfig = config;
          this.systemUiConfig.next(config);
        },
        error: (error) => this.systemUiConfig.error(error),
      });
  }

  resetConfig() {
    this.systemUiConfig.next(null);
    this.uiConfig = null;
  }

  getStringSensors({
    systemID,
    stringID,
    includeVirtualSensors,
  }: {
    systemID: string;
    stringID: string;
    includeVirtualSensors: boolean;
  }): Promise<Sensor[]> {
    const url = `systems/${systemID}/${stringID}/sensors`;

    return firstValueFrom(this.http.get<Sensor[]>(url, { params: { includeVirtualSensors: includeVirtualSensors } }));
  }
}
