import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  BarChartData,
  BarChartDataProcessing,
  BarSelectionService,
  BarViewService,
  ChartLayout,
  ChartLayoutService,
  FilterBarValues,
  HoverEventService,
} from '@twaice-fe/frontend/shared/utilities';
import { KpiAlertModels } from '@twaice-fe/shared/models';
import { Selection } from 'd3';
import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { HealthBarChartTooltip } from './health-bar-chart-tooltip';
import { barChartDimensions, barChartXAxisInfo, barChartYAxisInfo, initialBarChartLayout } from './health-bar-chart.config';

@Component({
  selector: 'twaice-fe-health-bar-chart',
  templateUrl: './health-bar-chart.component.html',
  styleUrls: ['./health-bar-chart.component.scss'],
  providers: [BarViewService, HoverEventService, BarSelectionService],
  encapsulation: ViewEncapsulation.None,
})
export class HealthBarChartComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() layout: ChartLayout;

  @Input() data: BarChartData;

  @Input() alert?: KpiAlertModels;

  @Output() selectBar: EventEmitter<FilterBarValues[]> = new EventEmitter();

  @ViewChild('barChart', { static: true }) barChart: ElementRef<HTMLDivElement>;

  private chartContainer: HTMLDivElement;

  private chartSVGContainer: Selection<SVGGElement, unknown, null, undefined>;

  private windowResizeSubscription: Subscription;

  private healthBarTooltip: HealthBarChartTooltip;

  isLoading = true;

  barChartData: BarChartData[];

  constructor(
    private chartLayoutService: ChartLayoutService,
    private barViewService: BarViewService,
    private barChartDataProcessing: BarChartDataProcessing,
    private barSelectionService: BarSelectionService
  ) {}

  ngOnInit(): void {
    this.chartContainer = this.barChart.nativeElement;

    if (this.layout) {
      this.layout = { ...this.layout, ...initialBarChartLayout };

      if (this.layout.hasSelection) {
        this.barSelectionService
          .getFilterBarValues()
          .pipe(filter((filterBarValues) => !!filterBarValues))
          .subscribe((filterBarValues) => this.selectBar.emit(filterBarValues));
      }
    }
  }

  ngAfterViewInit(): void {
    // setup resize event to keep the charts responsive
    this.windowResizeSubscription = fromEvent(window, 'resize').subscribe(() => {
      if (this.chartContainer && this.chartContainer.clientWidth && this.chartContainer.clientHeight) {
        this.initChart();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['layout']) {
      this.layout = { ...this.layout, ...initialBarChartLayout };
    }

    this.isLoading = true;

    if (this.alert) {
      this.isLoading = false;
      return;
    }

    this.chartContainer = this.barChart.nativeElement;

    this.initChart();
  }

  ngOnDestroy() {
    this.windowResizeSubscription.unsubscribe();
  }

  private initChart() {
    this.chartLayoutService.clear(this.chartContainer);

    this.barViewService.clear(this.chartContainer);

    this.initChartLayout();

    this.initChartContent();
  }

  private initChartLayout() {
    if (this.layout && this.data) {
      this.barChartData = [];

      this.data.x.forEach((list) => this.barChartData.push({ x: [list], y: [] }));
      const xAxisInfo = this.chartLayoutService.setXAxisInfo({
        // TODO: Remove this property after finding a way to handle the case of time axis
        xAxisInfo: {
          ...barChartXAxisInfo,
          isAxisInTime: this.layout.isXAxisInTime,
          ticksCount: this.layout.xTickCount ?? barChartXAxisInfo.ticksCount,
        },
        layout: this.layout,
        x: this.data.x,
      });

      this.barChartData.forEach((data) => {
        data.x = this.barChartDataProcessing.splitListIntoChunks({
          kpiData: data.x[0],
          hasTimeAxis: xAxisInfo.isAxisInTime,
          minValue: xAxisInfo.tickValues[0],
          step: xAxisInfo.tickValues[1] - xAxisInfo.tickValues[0],
          tickCount: xAxisInfo.ticksCount,
        });

        data.y = data.x.map((chunk) => chunk.length);
      });

      const mergedY = this.barChartData.reduce((result, current) => current.y.map((y, i) => (result[i] || 0) + y), []);

      this.layout.yValueRange = [0, Math.max(...[].concat(...mergedY))];

      const yAxisInfo = this.chartLayoutService.setYAxisInfo({
        yAxisInfo: barChartYAxisInfo,
        layout: this.layout,
        y: [mergedY],
      });

      this.chartSVGContainer = this.chartLayoutService.draw({
        chartContainer: this.chartContainer,
        chartDimensions: barChartDimensions,
        xAxisInfo,
        yAxisInfo,
        layout: this.layout,
      });
    }
  }

  private initChartContent() {
    if (this.layout) {
      if (this.barChartData && this.data) {
        if (this.layout.hasTooltip) {
          this.healthBarTooltip = new HealthBarChartTooltip();
        }

        this.chartSVGContainer = this.barViewService.draw({
          chartSVGContainer: this.chartSVGContainer,
          chartLayoutService: this.chartLayoutService,
          data: this.barChartData,
          options: {
            color: this.layout.chartColors,
            events: {
              onHover: (event) => this.healthBarTooltip?.onHover(event, this.chartContainer),
            },
            enableLayoutInteraction: true,
          },
        });

        if (this.layout.hasSelection) {
          this.barSelectionService.attachEvent({
            chartSVGContainer: this.chartSVGContainer,
          });
        }

        this.isLoading = false;
      }
    }
  }
}
