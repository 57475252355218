import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { DemoLinkEmbedComponent } from './demo-link-embed.component';
import { DemoLinkDirective } from './demo-link.directive';

@NgModule({
  declarations: [DemoLinkDirective],
  imports: [CommonModule, NzModalModule, DemoLinkEmbedComponent],
  exports: [DemoLinkDirective],
})
export class DemoLinkModule {}
