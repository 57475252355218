import { keysToCamel } from '@twaice-fe/shared/utilities';

/* eslint-disable @typescript-eslint/naming-convention */
export class TWResponse {
  status?: string;
  message?: string;
  data: unknown;
}

export class TWSingleResponse<T> {
  status?: string;
  message?: string;
  data: T;
}

export class PaginatedData<T> {
  items: T[];
  next: string;
  page: number;
  page_size: number;
  prev: string;
  total_pages: number;
  total_items: number;
  total_unfiltered_items?: number;
}

export class TWPaginatedResponse<T> extends TWResponse {
  data: PaginatedData<T>;
}

//!! refactor all response types to use the classes below (gradual refactor)
// Use this for now
export class PaginatedResponse<T> {
  items: T[];
  next: string;
  page: number;
  pageSize: number;
  prev: string;
  totalPages: number;
  totalItems: number;

  constructor(inputData?: Record<string, unknown>) {
    if (inputData) {
      this.deserialize(inputData);
    }
  }

  deserialize(input: Record<string, unknown>) {
    Object.assign(this, keysToCamel(input));
    return this;
  }
}

export class TwResponse<T> extends TWResponse {
  data: PaginatedResponse<T>;

  constructor(input?) {
    super();
    if (input) {
      this.deserialize(input);
    }
  }

  deserialize(input) {
    Object.assign(this, { ...input, data: new PaginatedResponse(input.data) });
    return this;
  }
}
