<mat-card class="max-w-full h-[200px] hover:bg-secondary cursor-pointer" [class.bg-secondary]="selected" appearance="outlined">
  <mat-card-header class="p-16">
    <div class="w-full">
      <div class="flex items-center justify-between w-full h-32 py-2">
        <div class="flex items-center gap-16">
          <twaice-fe-svg-icon name="icon_server"></twaice-fe-svg-icon>
          <span class="font-normal leading-lg text-base text-primary-text">{{ cardContent.storageName }}</span>
        </div>

        <div>
          <mat-chip-list *ngIf="cardContent.incidents?.tags" (click)="onEvent($event)" class="flex items-center flex-wrap gap-4">
            <mat-chip *ngFor="let item of cardContent.incidents?.tags">
              <div class="flex items-center px-8 py-4 gap-4 cursor-pointer" (click)="navigate(item.link, $event)">
                <twaice-fe-svg-icon
                  *ngIf="item.icon"
                  [name]="item.icon"
                  width="16px"
                  height="16px"
                  [color]="item?.iconColor"
                ></twaice-fe-svg-icon>
                <span>{{ item.text }}</span>
              </div>
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>

      <div *ngIf="!isFleet" class="flex flex-row items-center justify-between py-8 text-sm leading-base">
        <div class="flex items-center gap-8">
          <span class="text-tertiary-text">Location</span>
          <twaice-fe-data-display class="text-primary-text" [value]="this.config.location.text"></twaice-fe-data-display>
        </div>
        <div class="flex items-center gap-24">
          <div class="flex items-center gap-8">
            <span class="text-tertiary-text">Initial Capacity</span>
            <twaice-fe-data-display
              class="text-primary-text"
              [value]="this.config.nominalEnergyCapacity.text"
            ></twaice-fe-data-display>
          </div>

          <div class="flex items-center gap-8">
            <span class="text-tertiary-text">Max Power</span>
            <twaice-fe-data-display
              class="text-primary-text"
              [value]="this.config.nominalEnergyCapacity.text"
            ></twaice-fe-data-display>
          </div>
        </div>
      </div>
    </div>
  </mat-card-header>

  <mat-card-content *ngIf="!isFleet" class="flex border-t-1 border-dashed border-primary-border p-0">
    <div class="w-[50%] border-r-1 border-dashed border-primary-border p-16">
      <div class="flex items-center gap-8">
        <twaice-fe-svg-icon name="icon_cycles" size="16px"></twaice-fe-svg-icon>
        <span class="text-primary-text leading-base text-sm">Cycles</span>
      </div>

      <div class="flex items-center justify-between">
        <div class="flex flex-col justify-start">
          <span class="text-tertiary-text">Last 30 days</span>
          <twaice-fe-data-display numberFormat="1.0-0" [value]="cardContent.cycleLast30Days"></twaice-fe-data-display>
        </div>

        <div class="flex flex-col justify-start">
          <span class="text-tertiary-text">Total</span>
          <twaice-fe-data-display numberFormat="1.0-0" [value]="cardContent.totalCycles"></twaice-fe-data-display>
        </div>
      </div>
    </div>

    <div class="w-[50%] p-16">
      <div class="flex items-center gap-8">
        <twaice-fe-svg-icon name="icon_wave" size="16px"></twaice-fe-svg-icon>
        <span class="text-primary-text leading-base text-sm">State of Health</span>
      </div>

      <div class="flex items-center justify-between">
        <div class="flex flex-col justify-start">
          <span class="text-tertiary-text">Lowest</span>
          <twaice-fe-data-display numberFormat="1.0-0" measurand="%" [value]="cardContent.sohMin"></twaice-fe-data-display>
        </div>
        <div class="flex flex-col justify-start">
          <span class="text-tertiary-text">Highest</span>
          <twaice-fe-data-display numberFormat="1.0-0" measurand="%" [value]="cardContent.sohMax"></twaice-fe-data-display>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
