import { Pipe, PipeTransform } from '@angular/core';

const MAX_CHARACTERS_TOTAL = 17;

interface CompactItem {
  value: string;
  fullText: string;
}

@Pipe({ name: 'compact', pure: true, standalone: true })
export class CompactPipe implements PipeTransform {
  transform(items: string[], maxCharacters?: number): CompactItem[] {
    if (!items || items.length === 0) {
      return [];
    }
    return this.compactArray(items, maxCharacters || MAX_CHARACTERS_TOTAL);
  }

  private compactArray(items: string[], maxCharacters: number): CompactItem[] {
    const compactedItems = this.getFirstItemsLessThan(maxCharacters, items);
    const restItemsCount = items.length - compactedItems.length;
    if (restItemsCount > 0) {
      compactedItems.push({ value: `+${restItemsCount}...`, fullText: items.slice(compactedItems.length).join(', ') });
    }
    return compactedItems;
  }

  private getFirstItemsLessThan(maxAccumulativeLength: number, array: string[]): CompactItem[] {
    const compactedItems = [];
    let charactersCount = 0;
    for (let i = 0; i < array.length; i++) {
      const item = array[i];
      let valueItem = item;
      charactersCount += item.length;

      if (charactersCount > maxAccumulativeLength) {
        if (i === 0) {
          valueItem = item.slice(0, maxAccumulativeLength).concat('...');
          return [{ value: valueItem, fullText: item }];
        }
        return compactedItems;
      }
      compactedItems.push({ value: valueItem, fullText: item });
    }
    return compactedItems;
  }
}
