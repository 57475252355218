import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  INTERNAL_UI_CONFIG_OVERRIDE,
  REQUESTED_SYSTEMS_HEADER,
  SKIP_REQUESTED_SYSTEMS_INTERCEPTOR_HEADER,
} from '@twaice-fe/shared/constants';

@Injectable()
export class RequestedSystemsInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.headers.has(SKIP_REQUESTED_SYSTEMS_INTERCEPTOR_HEADER)) {
      const headers = req.headers.delete(SKIP_REQUESTED_SYSTEMS_INTERCEPTOR_HEADER);
      const clonedReq = req.clone({ headers });
      return next.handle(clonedReq);
    }

    const uiConfigOverride = localStorage.getItem(INTERNAL_UI_CONFIG_OVERRIDE);
    if (!uiConfigOverride) {
      return next.handle(req);
    }

    const modifiedReq = req.clone({
      setHeaders: {
        [REQUESTED_SYSTEMS_HEADER]: JSON.parse(uiConfigOverride).selectedSystemIds.join(),
      },
    });

    return next.handle(modifiedReq);
  }
}
