import { createAction, props } from '@ngrx/store';
import {
  SafetyRecommendationComponentDetailsInterface,
  SafetyRecommendationComponentDetailsParamsInterface,
  SafetyRecommendationDataInterface,
  SystemIngestionStatistics,
} from '@twaice-fe/shared/models';

/**
 * Safety Recommendation actions
 */
export const fetchSafetyRecommendationData = createAction('[Energy Analytics] Fetch safety recommendation data');

export const loadSafetyRecommendationDataSuccess = createAction(
  '[Energy Analytics/API] Load safety recommendation data Success',
  props<{ safetyRecommendationData: SafetyRecommendationDataInterface }>()
);

export const loadSafetyRecommendationDataFailure = createAction(
  '[Energy Analytics/API] Load safety recommendation data Failure',
  props<{ error: any }>()
);

export const cancelSafetyRecommendationDataRequest = createAction(
  '[Energy Analytics/API] Cancel safety recommendation data http request'
);

/**
 * System Ingestion Statistics Actions
 */

export const fetchSystemIngestionStatisticsData = createAction('[Energy Analytics] Fetch system ingestion statistics data');

export const loadSystemIngestionStatisticsSuccess = createAction(
  '[Energy Analytics/API] Load system ingestion statistics data Success',
  props<{ systemIngestionStatistics: SystemIngestionStatistics }>()
);

export const loadSystemIngestionStatisticsFailure = createAction(
  '[Energy Analytics/API] Load system ingestion statistics data Failure',
  props<{ error: any }>()
);

export const cancelSystemIngestionStatisticsRequest = createAction(
  '[Energy Analytics/API] Cancel system ingestion statistics data http request'
);

export const fetchSafetyRecommendationComponentDetails = createAction(
  '[Energy Analytics] Fetch safety recommendation component details',
  props<SafetyRecommendationComponentDetailsParamsInterface>()
);

export const loadSafetyRecommendationComponentDetailsSuccess = createAction(
  '[Energy Analytics/API] Load safety recommendation component details Success',
  props<{ safetyRecommendationComponentDetails: SafetyRecommendationComponentDetailsInterface }>()
);

export const loadSafetyRecommendationComponentDetailsFailure = createAction(
  '[Energy Analytics/API] Load safety recommendation component details Failure',
  props<{ error: any }>()
);

export const cancelSafetyRecommendationComponentDetailsRequest = createAction(
  '[Energy Analytics/API] Cancel safety recommendation component details http request'
);
