// TODO - delete and replace with the real alert once we have it
import { KpiAlertModels } from '@twaice-fe/shared/models';

export const missingCalculationAlert: KpiAlertModels = {
  type: 0,
  title: 'This KPI will be here soon.',
  subTitle: 'We are working on it. You will see updates soon.',
};

export const missingDataAlert: KpiAlertModels = {
  type: 1,
  title: 'It looks like the sensor is not receiving data.',
  subTitle: 'We are already working on this, for any questions, please talk to your account representative.',
};
