import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, EventType, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SystemsService } from '@twaice-fe/frontend/shared/services';
import { selectors } from '@twaice-fe/frontend/shared/store';
import { removeQueryParameter, updateQueryParameter } from '@twaice-fe/frontend/shared/utilities';
import { TimeRangeEnum, areDatesEqual, getRanges, getUnixTimestampFromDate } from '@twaice-fe/shared/utilities';
import { endOfMonth, startOfMonth } from 'date-fns';
import { Subject, distinctUntilChanged, filter, takeUntil } from 'rxjs';
import { TimeRangeInterface } from './time-selector.models';

const { routerSelectors } = selectors;

@Component({
  selector: 'twaice-fe-time-selector',
  templateUrl: './time-selector.component.html',
  styleUrl: './time-selector.component.scss',
})
export class TimeSelectorComponent implements OnInit, OnDestroy {
  @Input() defaultTimeRange = TimeRangeEnum.LAST_SEVEN_DAYS;
  timeRangeMapping: TimeRangeInterface[] = [];
  selectedTimeRange: TimeRangeInterface;
  selectedTimeRangeIndex: number;
  timeResolutionMapping: TimeRangeInterface[] = [
    { id: 'dailyView', value: 'date', label: 'Daily view', disabled: false },
    { id: 'monthlyView', value: 'month', label: 'Monthly view', disabled: false },
  ];
  selectedTimeResolution: TimeRangeInterface;
  selectedTimeResolutionIndex: number;
  pickerValue: [Date, Date];
  systemID: string;
  private destroy$ = new Subject<void>();

  constructor(
    protected store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private systemService: SystemsService
  ) {}

  ngOnInit(): void {
    this.systemService
      .getCurrentSystemIdObservable()
      .pipe(
        filter((id) => id != null),
        takeUntil(this.destroy$)
      )
      .subscribe(async (systemID) => {
        if (this.systemID && this.systemID !== systemID) {
          await removeQueryParameter(this.router, this.route, ['timeRange', 'startDate', 'endDate']);
        }

        this.systemID = systemID;
        await this.handleTimeRangeFromQueryParams();
      });

    this.router.events
      .pipe(
        filter((routerEvent) => routerEvent.type === EventType.NavigationEnd),
        distinctUntilChanged((prev: NavigationEnd, curr: NavigationEnd) => prev.url.split('?')[0] === curr.url.split('?')[0]),
        takeUntil(this.destroy$)
      )
      .subscribe(async (event) => {
        await this.handleTimeRangeFromQueryParams();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { timeRange, startDate, endDate, ...params } = this.route.snapshot.queryParams;
    this.router.navigate([], {
      queryParams: params,
    });
  }

  async onDatePickerChange(result: Date[], isFromPicker = true) {
    if (result[0] == null || result[1] == null) {
      return;
    }
    this.pickerValue = [result[0], result[1]];
    if (this.selectedTimeResolution?.id === 'monthlyView' && areDatesEqual(result[0], result[1])) {
      const start = startOfMonth(result[0]);
      const end = endOfMonth(result[1]);
      this.pickerValue = [start, end];
    }

    // If the date range is selected from the picker, then set time range to custom
    if (isFromPicker) {
      this.selectedTimeRangeIndex = TimeRangeEnum.CUSTOM;
      this.timeRangeMapping[TimeRangeEnum.CUSTOM].value = this.pickerValue;
      this.selectedTimeRange = this.timeRangeMapping[TimeRangeEnum.CUSTOM];
    }

    await this.updateQueryParameter(
      ['timeRange', 'startDate', 'endDate'],
      [this.selectedTimeRange?.id, getUnixTimestampFromDate(this.pickerValue[0]), getUnixTimestampFromDate(this.pickerValue[1])]
    );
  }

  async onTimeResolutionChange(selectedTimeResolutionIndex: number) {
    this.selectedTimeResolution = this.timeResolutionMapping[selectedTimeResolutionIndex];
  }

  async onTimeRangeChange(selectedTimeRangeIndex: number) {
    this.selectedTimeRange = this.timeRangeMapping[selectedTimeRangeIndex];
    this.pickerValue = this.selectedTimeRange.value as [Date, Date];
    await this.onDatePickerChange(this.selectedTimeRange.value as [Date, Date], false);
  }

  private async handleTimeRangeFromQueryParams() {
    this.timeRangeMapping = getRanges(this.systemID);
    const search = new URL(window.location.href).searchParams;
    const timeRange = search.get('timeRange') ?? this.timeRangeMapping[this.defaultTimeRange].id;

    if (timeRange === 'custom') {
      this.timeRangeMapping[TimeRangeEnum.CUSTOM].value = [
        new Date(+search.get('startDate') * 1000),
        new Date(+search.get('endDate') * 1000),
      ];
    }

    const indexTimeRange = this.timeRangeMapping.findIndex((item) => item.id === timeRange);
    if (indexTimeRange !== -1) {
      this.selectedTimeRangeIndex = indexTimeRange;
      this.selectedTimeRange = this.timeRangeMapping[indexTimeRange];
    } else {
      this.selectedTimeRangeIndex = TimeRangeEnum.LAST_SEVEN_DAYS;
      this.selectedTimeRange = this.timeRangeMapping[TimeRangeEnum.LAST_SEVEN_DAYS];
    }

    this.pickerValue = this.selectedTimeRange.value as [Date, Date];

    await this.updateQueryParameter(
      ['timeRange', 'startDate', 'endDate'],
      [timeRange, getUnixTimestampFromDate(this.pickerValue[0]), getUnixTimestampFromDate(this.pickerValue[1])]
    );
  }

  private updateQueryParameter(paramNames: string[], values: unknown[]): Promise<boolean> {
    return updateQueryParameter(this.router, this.route, paramNames, values);
  }
}
