import { Margin, Shape } from 'plotly.js-basic-dist';
import { PlotlyShape } from '../plotly';

export class PredictionChartShapes {
  static getPlotBorders(color): PlotlyShape[] {
    return [
      {
        type: 'line',
        x0: 0,
        y0: 0,
        x1: 0,
        y1: 1,
        yref: 'paper',
        xref: 'paper',
        line: {
          color: color,
          width: 1,
        },
      },
      {
        type: 'line',
        x0: 0,
        y0: 1,
        x1: 1,
        y1: 1,
        yref: 'paper',
        xref: 'paper',
        line: {
          color: color,
          width: 0.25,
        },
      },
      {
        type: 'line',
        x0: 0,
        y0: 0,
        x1: 1,
        y1: 0,
        yref: 'paper',
        xref: 'paper',
        line: {
          color: color,
          width: 1,
        },
      },
      {
        type: 'line',
        x0: 1,
        y0: 0,
        x1: 1,
        y1: 1,
        yref: 'paper',
        xref: 'paper',
        line: {
          color: color,
          width: 0.25,
        },
      },
    ];
  }

  static getRangeIndicator(
    chartElementID: string,
    dateRange: number[] = [],
    chartMargin: Partial<Margin>,
    name = 'rangeIndicator',
    color = '#81CBCF',
    lineWidth = 5
  ): PlotlyShape {
    if (dateRange[0] && dateRange[1]) {
      const plotHeight = document.getElementById(chartElementID).offsetHeight - chartMargin.b - chartMargin.t;
      const shift = lineWidth / 2 / plotHeight;

      return {
        type: 'line',
        x0: new Date(dateRange[0]).toISOString(),
        y0: -shift,
        x1: new Date(dateRange[1]).toISOString(),
        y1: -shift,
        yref: 'paper',
        xref: 'x',
        line: {
          color: color,
          width: lineWidth,
        },
        name: name,
      };
    }
  }

  static deleteShapeWithName(shapeArray: Array<Partial<Shape>>, name: string) {
    // if we find the existing shape(which can happen on window resize event), we delete it before adding an updated one
    const index = shapeArray.findIndex((shape: Partial<Shape>) => shape.name === name);
    if (index) {
      shapeArray.splice(index, 1);
    }
  }
}
