import { CommonModule, DatePipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SideNavService } from '@twaice-fe/frontend/shared/services';
import {
  ChartAxisService,
  ChartLayout,
  ChartLayoutService,
  ClickEvent,
  ClickEventService,
  HoverEvent,
  HoverEventService,
  InitializeChartUtility,
  StackedBarChartData,
  StackedBarColor,
  StackedBarInfo,
  StackedBarViewService,
  updateQueryParameter,
} from '@twaice-fe/frontend/shared/utilities';
import { KpiAlertModels } from '@twaice-fe/shared/models';
import { Selection } from 'd3';
import { Subject, Subscription, fromEvent } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { AlertPanelModule } from '../alert-panel/alert-panel.module';
import { ProgressSpinnerModule } from '../progress-spinner/progress-spinner.module';
import { positionTooltip } from './energy-stacked-bar-chart-tooltip';
import {
  initialStackedBarChartLayout,
  stackedBarChartDimensions,
  stackedBarChartXAxisInfo,
  stackedBarChartYAxisInfo,
  xPadding,
  yPadding,
} from './energy-stacked-bar-chart.config';
import { FakeLoadingComponent } from '../fake-loading/fake-loading.component';

@Component({
  selector: 'twaice-fe-energy-stacked-bar',
  standalone: true,
  imports: [CommonModule, ProgressSpinnerModule, AlertPanelModule, FakeLoadingComponent],
  providers: [
    ChartLayoutService,
    InitializeChartUtility,
    ChartAxisService,
    HoverEventService,
    ClickEventService,
    StackedBarViewService,
    DatePipe,
  ],
  templateUrl: './energy-stacked-bar-chart.component.html',
  styleUrl: './energy-stacked-bar-chart.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class EnergyStackedBarChartComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() layout: ChartLayout;
  @Input() data: StackedBarChartData[];
  @Input() info: StackedBarInfo;
  @Input() alert?: KpiAlertModels;
  @Input() timeRange?: string;
  @Output() chartClicked: EventEmitter<StackedBarChartData> = new EventEmitter<StackedBarChartData>();
  @ViewChild('stackedBarChart', { static: true }) stackedBarChart: ElementRef<HTMLDivElement>;
  @ViewChild('tooltip', { static: false }) tooltipRef: ElementRef<HTMLDivElement>;
  tooltipContent: StackedBarChartData;
  isLoading = true;
  private chartContainer: HTMLDivElement;
  private chartSVGContainer: Selection<SVGGElement, unknown, null, undefined>;
  private windowResizeSubscription: Subscription;
  private destroy$ = new Subject<void>();
  private selectedDay: number;

  constructor(
    private chartLayoutService: ChartLayoutService,
    private stackedBarChartService: StackedBarViewService,
    private sideNavService: SideNavService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((queryParams) => {
      this.selectedDay = +queryParams['selectedDay'];
    });
  }

  ngOnInit(): void {
    this.chartContainer = this.stackedBarChart.nativeElement;
    if (this.layout) {
      this.layout = { ...this.layout, ...initialStackedBarChartLayout };
    }
  }

  ngAfterViewInit(): void {
    this.sideNavService.sideNavState$.pipe(delay(400), takeUntil(this.destroy$)).subscribe(() => this.onResize());
    this.sideNavService.insightsDrawerState$.pipe(delay(300), takeUntil(this.destroy$))
        .subscribe(() => this.onResize());

    this.windowResizeSubscription = fromEvent(window, 'resize').subscribe(() => this.onResize());
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes['layout'] || changes['data']) && this.data && !this.alert) {
      const x = this.data?.map((obj) => obj.x as number);
      this.layout = {
        ...this.layout,
        ...initialStackedBarChartLayout,
        xValueRange: [Math.min(...x), Math.max(...x)],
        xTickValues: x,
      };
    }
    this.isLoading = true;
    if (this.alert) {
      this.isLoading = false;
      return;
    }
    this.chartContainer = this.stackedBarChart.nativeElement;
    this.initChart();
  }

  ngOnDestroy() {
    this.windowResizeSubscription.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  keepOrder() {
    return 0;
  }

  private onResize() {
    if (this.chartContainer && this.chartContainer.clientWidth && this.chartContainer.clientHeight) {
      this.initChart();
    }
  }

  private initChart() {
    this.chartLayoutService.clear(this.chartContainer);
    this.stackedBarChartService.clear(this.chartContainer);

    if (this.data?.length) {
      this.initChartLayout();
      this.initChartContent();
    }
  }

  private initChartLayout() {
    if (this.layout && this.data) {
      const x = this.data.map((obj) => obj.x as number);
      const xAxisInfo = this.chartLayoutService.setXAxisInfo({
        xAxisInfo: { ...stackedBarChartXAxisInfo, reduceTicks: this.timeRange === 'ninetyDays' },
        layout: this.layout,
        x: [x],
      });

      const yAxisInfo = this.chartLayoutService.setYAxisInfo({
        yAxisInfo: { ...stackedBarChartYAxisInfo },
        layout: {
          ...this.layout,
          yValueRange: [0, Math.max(...this.data.map((d) => Object.values(d.y).reduce((c, a) => c + a, 0)))],
        },
        y: this.data.map((obj) => Object.values(obj.y)),
      });

      this.chartSVGContainer = this.chartLayoutService.draw({
        chartContainer: this.chartContainer,
        chartDimensions: stackedBarChartDimensions,
        xAxisInfo,
        yAxisInfo,
        layout: this.layout,
      });
      const barWidth =
        (this.chartContainer.clientWidth - stackedBarChartDimensions.marginLeft - stackedBarChartDimensions.marginRight) /
        x.length;

      this.chartLayoutService.clear(this.chartContainer);

      this.chartSVGContainer = this.chartLayoutService.draw({
        chartContainer: this.chartContainer,
        chartDimensions: {
          ...stackedBarChartDimensions,
          marginLeft: barWidth + 4 * xPadding + 2,
          axisOffsetRight: -barWidth / 2 - 4 * xPadding + 2,
        },
        xAxisInfo,
        yAxisInfo,
        layout: this.layout,
      });
    }
  }

  private initChartContent() {
    if (this.layout) {
      this.stackedBarChartService.draw({
        chartSVGContainer: this.chartSVGContainer,
        chartLayoutService: this.chartLayoutService,
        data: this.data,
        options: {
          color: Object.fromEntries(Object.entries(this.info).map(([key, value]) => [key, value.color])) as StackedBarColor,
          xPadding: xPadding,
          yPadding: yPadding,
          events: {
            onHover: this.layout.hasTooltip ? (event) => this.onHover(event) : null,
            onClick: async ($event) => {
              await updateQueryParameter(this.router, this.route, ['selectedDay'], [$event.data.x]);
              this.onClick($event);
            },
          },
        },
      });

      const indexSelectedDay = this.selectedDay ? this.data.findIndex((d) => d.x === this.selectedDay) : this.data.length - 1;
      this.triggerClickEvent(indexSelectedDay);
      this.isLoading = false;
    }
  }

  private onHover(event: HoverEvent<StackedBarChartData>) {
    const { data, cell } = event;

    if (!event || !data || !cell) {
      this.tooltipRef.nativeElement.style.display = 'none';
      return;
    }

    const barGroup = <SVGElement>cell.node();

    barGroup.style.cursor = 'url("assets/icons/applications/icon-arrow-tooltip.svg"), auto';

    const xPosition = barGroup.getAttribute('x');
    const yPosition = barGroup.getAttribute('y');
    const barWidth = barGroup.getAttribute('width');

    this.tooltipContent = data;

    this.tooltipRef.nativeElement = positionTooltip({
      x: +xPosition,
      y: -yPosition,
      barWidth: +barWidth,
      tooltip: this.tooltipRef.nativeElement,
      chartContainer: this.chartContainer,
    });
  }

  private onClick(event: ClickEvent<StackedBarChartData>) {
    this.chartClicked.emit(event.data);
  }

  private triggerClickEvent(index: number) {
    this.chartSVGContainer.select(`#bar-group-${index} .interaction-bar`).dispatch('click');
  }
}
