import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatetimeSelectorComponent } from './datetime-selector.component';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [DatetimeSelectorComponent],
  exports: [DatetimeSelectorComponent],
  imports: [CommonModule, ReactiveFormsModule, MatIconModule, MatInputModule],
})
export class DatetimeSelectorModule {}
