import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { OverviewSystem } from '@twaice-fe/shared/models';

import * as SystemsActions from '../actions/systems.actions';
import { ListConfigurationInterface } from '../types/list-configuration.interface';

export const SYSTEMS_FEATURE_KEY = 'systems';

export interface State extends EntityState<OverviewSystem> {
  config: ListConfigurationInterface;
  selectedId?: string; // which Systems record has been selected
  isLoading: boolean; // has the Systems list been loaded
  isEfcLoading?: boolean;
  error?: string | null; // last known error (if any)
}

export interface SystemsPartialState {
  readonly [SYSTEMS_FEATURE_KEY]: State;
}

export const systemsAdapter: EntityAdapter<OverviewSystem> = createEntityAdapter<OverviewSystem>();
export const initialState: State = systemsAdapter.getInitialState({
  selectedId: '',
  config: {
    limit: 10,
    page: 1,
    totalPages: 1,
    columns: [],
  },
  isLoading: false,
  isEfcLoading: false,
});

const systemsReducer = createReducer(
  initialState,
  on(SystemsActions.fetchSystems, (state) => ({ ...state, isLoading: true, error: null })),
  on(SystemsActions.fetchSystemsEfcData, (state) => ({ ...state, isEfcLoading: true, isLoading: true, error: null })),
  on(SystemsActions.loadSystemsSuccess, (state, { data }) =>
    Object.keys(state?.entities)?.length > 0
      ? {
          ...state,
          entities: {
            ...state.entities,
            ...Object.assign(
              {},
              ...data.items.map(({ id, ...rest }) => ({
                [id]: {
                  ...state.entities[id],
                  ...rest,
                  kpis: {
                    ...state.entities[id]?.kpis,
                    ...rest?.kpis,
                  },
                  metadata: {
                    ...state.entities[id]?.metadata,
                    ...rest?.metadata,
                  },
                  warrantyMetadata: {
                    ...state.entities[id]?.warrantyMetadata,
                    ...rest?.warrantyMetadata,
                  },
                  systemStatistics: {
                    ...state.entities[id]?.systemStatistics,
                    ...rest?.systemStatistics,
                  },
                },
              }))
            ),
          },
          config: { ...state.config, page: data.page, limit: data.pageSize, totalPages: data.totalPages },
          isLoading: state.isEfcLoading ?? false,
        }
      : systemsAdapter.upsertMany(data.items, {
          ...state,
          config: { ...state.config, page: data.page, limit: data.pageSize, totalPages: data.totalPages },
          isLoading: false,
        })
  ),
  on(...[SystemsActions.loadSystemsEfcDataSuccess, SystemsActions.loadSystemsHealthKpiDataSuccess], (state, { data }) => ({
    ...state,
    entities: {
      ...state.entities,
      ...Object.assign(
        {},
        ...data.items.map(({ id, kpis, systemStatistics }) => ({
          [id]: {
            ...state.entities[id],
            kpis: {
              ...state.entities[id]?.kpis,
              ...kpis,
            },
            systemStatistics: {
              ...state.entities[id]?.systemStatistics,
              ...systemStatistics,
            },
          },
        }))
      ),
    },
    config: { ...state.config, page: data.page, limit: data.pageSize, totalPages: data.totalPages },
  })),
  on(SystemsActions.loadSystemsHealthKpiDataSuccess, (state, { data }) => ({ ...state, isLoading: state.isEfcLoading ?? false })),
  on(SystemsActions.loadSystemsEfcDataSuccess, (state, { data }) => ({
    ...state,
    isEfcLoading: false,
    isLoading: false,
  })),
  on(SystemsActions.loadSystemsFailure, (state, { error }) => ({ ...state, error, isLoading: false })),
  on(SystemsActions.updateSystemListConfiguration, (state, { config, isLoading }) => ({
    ...state,
    isLoading: isLoading ?? false,
    config: { ...state.config, ...config },
  })),
  on(SystemsActions.systemsColumnPicker, (state, { columns }) => ({
    ...state,
    config: {
      ...state.config,
      columns,
    },
  })),
  on(SystemsActions.selectSystem, (state, { systemId }) => ({
    ...state,
    selectedId: systemId,
  })),
  on(SystemsActions.systemLoadingSuccess, (state) => ({ ...state, isLoading: false }))
);

export function reducer(state: State | undefined, action: Action) {
  return systemsReducer(state, action);
}
