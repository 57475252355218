import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService as AuthGuard, LoggedOutGuardService, SystemsService } from '@twaice-fe/frontend/shared/services';
import { DashboardLayoutComponent } from './containers/dashboard-layout/dashboard-layout.component';
import { solutionRoutes } from './solution.routes';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { MissingConfigurationComponent } from './views/error/missing-configuration.component';
import { LoggedOutComponent } from './views/logged-out/logged-out.component';

export const routes: Routes = [
  {
    path: '404',
    component: P404Component,
  },
  {
    path: '500',
    component: P500Component,
  },
  {
    path: 'missing-configuration',
    component: MissingConfigurationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'privacy-policy',
    component: DashboardLayoutComponent,
    loadChildren: () => import('./views/privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyModule),
  },
  {
    path: 'settings',
    component: DashboardLayoutComponent,
    loadChildren: () => import('./views/settings/settings.module').then((m) => m.SettingsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    loadChildren: () => import('./views/logged-out/logged-out.module').then((m) => m.LoggedOutModule),
    canActivate: [LoggedOutGuardService],
  },
  {
    path: 'login/mfa',
    component: LoggedOutComponent,
    loadChildren: () =>
      import('./views/logged-out/multi-factor-auth/multi-factor-auth.module').then((m) => m.MultiFactorAuthModule),
  },
  {
    path: 'customer/:customerBk/system/:systemBk',
    component: DashboardLayoutComponent,
    canActivate: [AuthGuard],
    children: solutionRoutes,
  },
  {
    path: '',
    component: DashboardLayoutComponent,
    canActivate: [AuthGuard],
    children: solutionRoutes,
  },
  { path: '**', component: P404Component, canActivate: [AuthGuard] },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'disabled',
      onSameUrlNavigation: 'reload',
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
  providers: [SystemsService],
})
export class AppRoutingModule {}
