import {
  ComponentStateInterface,
  KpiStateDetailsInterface,
  SafetyRecommendationComponentDetailsInterface,
  SafetyRecommendationDataInterface,
  SystemIngestionStatistics,
} from '@twaice-fe/shared/models';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { energyAnalyticsActions } from '../actions';

export const ENERGY_ANALYTICS_FEATURE_KEY = 'energy-analytics';

interface RemoteState {
  isLoading: boolean;
  error: string | null;
}

export interface SafetyRecommendationDataState extends RemoteState {
  systemState: string;
  numberOfStableStrings: number;
  requiredActions: EntityState<ComponentStateInterface>;
  recommendedActions: EntityState<ComponentStateInterface>;
}

export interface SystemIngestionStatisticsState extends RemoteState {
  lastSeen: Date | null;
}

export interface SafetyRecommendationComponentDetailsState {
  componentBk: string;
  kpiStates: KpiStateDetailsInterface[];
  isLoading: boolean;
  error: string | null;
}

export interface State {
  customerBk: string;
  systemBk: string;
  safetyRecommendationData: SafetyRecommendationDataState;
  systemIngestionStatistics: SystemIngestionStatisticsState;
  safetyRecommendationComponentDetails: SafetyRecommendationComponentDetailsState;
}

export const safetyActionAdapter: EntityAdapter<ComponentStateInterface> = createEntityAdapter<ComponentStateInterface>({
  selectId: (safetyActionData) => safetyActionData.componentBk,
});

export const initialSafetyRecommendationDataState: SafetyRecommendationDataState = {
  systemState: '',
  numberOfStableStrings: 0,
  requiredActions: safetyActionAdapter.getInitialState(),
  recommendedActions: safetyActionAdapter.getInitialState(),
  error: null,
  isLoading: false,
};

const initialSystemIngestionStatisticsState: SystemIngestionStatisticsState = {
  isLoading: false,
  error: null,
  lastSeen: null,
};
export const initialSafetyRecommendationComponentDetailsState: SafetyRecommendationComponentDetailsState = {
  componentBk: '',
  kpiStates: [],
  error: null,
  isLoading: false,
};

export const initialState: State = {
  customerBk: '',
  systemBk: '',
  safetyRecommendationData: { ...initialSafetyRecommendationDataState },
  systemIngestionStatistics: { ...initialSystemIngestionStatisticsState },
  safetyRecommendationComponentDetails: { ...initialSafetyRecommendationComponentDetailsState },
};

type ActionReducer<T = unknown> = (state: State, payload: T) => State;

const reduceFetchSafetyRecommendationData: ActionReducer = (state) => ({
  ...state,
  safetyRecommendationData: { ...initialSafetyRecommendationDataState, isLoading: true },
});

const reduceLoadSafetyRecommendationDataSuccess: ActionReducer<{
  safetyRecommendationData: SafetyRecommendationDataInterface;
}> = (state, { safetyRecommendationData }) => ({
  ...state,
  safetyRecommendationData: {
    ...state.safetyRecommendationData,
    ...safetyRecommendationData,
    requiredActions: safetyActionAdapter.upsertMany(
      safetyRecommendationData.requiredActions,
      state.safetyRecommendationData.requiredActions
    ),
    recommendedActions: safetyActionAdapter.upsertMany(
      safetyRecommendationData.recommendedActions,
      state.safetyRecommendationData.recommendedActions
    ),
    isLoading: false,
    error: null,
  },
});

const reduceLoadSafetyRecommendationDataFailure: ActionReducer<{ error: string }> = (state, { error }) => ({
  ...state,
  safetyRecommendationData: {
    ...state.safetyRecommendationData,
    isLoading: false,
    error,
  },
});

const reduceFetchSystemIngestionStatisticsData: ActionReducer = (state) => ({
  ...state,
  systemIngestionStatistics: { ...initialSystemIngestionStatisticsState, isLoading: true },
});

const reduceLoadSystemIngestionStatisticsDataSuccess: ActionReducer<{ systemIngestionStatistics: SystemIngestionStatistics }> = (
  state,
  { systemIngestionStatistics }
) => ({
  ...state,
  systemIngestionStatistics: { ...state.systemIngestionStatistics, ...systemIngestionStatistics, isLoading: false, error: null },
});

const reduceLoadSystemIngestionStatisticsDataFailure: ActionReducer<{ error: string }> = (state, { error }) => ({
  ...state,
  systemIngestionStatistics: {
    ...state.systemIngestionStatistics,
    isLoading: false,
    error,
  },
});

const reduceFetchSafetyRecommendationComponentDetails: ActionReducer<{ componentBk: string }> = (state, { componentBk }) => ({
  ...state,
  safetyRecommendationComponentDetails: { ...initialSafetyRecommendationComponentDetailsState, componentBk, isLoading: true },
});

const reduceLoadSafetyRecommendationComponentDetailsSuccess: ActionReducer<{
  safetyRecommendationComponentDetails: SafetyRecommendationComponentDetailsInterface;
}> = (state, { safetyRecommendationComponentDetails }) => ({
  ...state,
  safetyRecommendationComponentDetails: {
    ...state.safetyRecommendationComponentDetails,
    ...safetyRecommendationComponentDetails,
    isLoading: false,
    error: null,
  },
});

const reduceLoadSafetyRecommendationDetailsFailure: ActionReducer<{ error: string }> = (state, { error }) => ({
  ...state,
  safetyRecommendationComponentDetails: {
    ...state.safetyRecommendationComponentDetails,
    isLoading: false,
    error,
  },
});

export const energyAnalyticsReducer = createReducer(
  initialState,
  on(energyAnalyticsActions.fetchSafetyRecommendationData, reduceFetchSafetyRecommendationData),
  on(energyAnalyticsActions.loadSafetyRecommendationDataSuccess, reduceLoadSafetyRecommendationDataSuccess),
  on(energyAnalyticsActions.loadSafetyRecommendationDataFailure, reduceLoadSafetyRecommendationDataFailure),
  on(energyAnalyticsActions.fetchSystemIngestionStatisticsData, reduceFetchSystemIngestionStatisticsData),
  on(energyAnalyticsActions.loadSystemIngestionStatisticsSuccess, reduceLoadSystemIngestionStatisticsDataSuccess),
  on(energyAnalyticsActions.loadSystemIngestionStatisticsFailure, reduceLoadSystemIngestionStatisticsDataFailure),
  on(energyAnalyticsActions.fetchSafetyRecommendationComponentDetails, reduceFetchSafetyRecommendationComponentDetails),
  on(
    energyAnalyticsActions.loadSafetyRecommendationComponentDetailsSuccess,
    reduceLoadSafetyRecommendationComponentDetailsSuccess
  ),
  on(energyAnalyticsActions.loadSafetyRecommendationComponentDetailsFailure, reduceLoadSafetyRecommendationDetailsFailure)
);
