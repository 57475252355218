import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartInfoComponent } from './chart-info.component';
import { ChartInfoItemComponent } from './chart-info-item/chart-info-item.component';
import { DataDisplayModule } from '../data-display/data-display.module';
import { ProgressSpinnerModule } from '../progress-spinner/progress-spinner.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { TooltipModule } from '../tooltip/tooltip.module';

@NgModule({
  declarations: [ChartInfoComponent, ChartInfoItemComponent],
  imports: [
    CommonModule,
    DataDisplayModule,
    ProgressSpinnerModule,
    MatButtonModule,
    MatIconModule,
    NzProgressModule,
    SvgIconModule,
    TooltipModule,
  ],
  exports: [ChartInfoComponent, ChartInfoItemComponent],
})
export class ChartInfoModule {}
