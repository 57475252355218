import { Injectable } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, from, map, of, switchMap, tap } from 'rxjs';

import { Router } from '@angular/router';
import { AmplifyInternalService } from '@twaice-fe/frontend/shared/services';
import { authActions } from '../actions';

import { CognitoUser } from '@twaice-fe/shared/models';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable()
export class AuthEffects {
  /**
   * This is a not great but we have to store this object here _outside_ of the store
   * Amplify calls different methods on the object when we need to pass it back so
   * we can't just pipe it through the serialisation/deserialisation process of the store
   */
  mfaIntermediateUser: CognitoUser | null;

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.login),
      switchMap(({ email, password }) =>
        from(
          Auth.signIn({
            username: email,
            password: password,
          })
        ).pipe(
          tap((user) => this.amplifyInternalService.updateFirstLogInTimestamp(user)),
          filter((user) => !!user),
          switchMap((user) => {
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
              if (!this.router.url.includes('login/set-initial-password')) {
                // we only navigate if we are somewhere else
                this.router.navigateByUrl(`/login/set-initial-password?usr=${user.getUsername()}&loginAttempt=1`);
              }
            }

            if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
              if (!this.router.url.includes('login/mfa')) {
                this.router.navigateByUrl(`/login/mfa`);
                this.mfaIntermediateUser = user;
                return of(authActions.loginMFAPending());
              }
            }

            return of(authActions.loginSuccess({ user: JSON.parse(JSON.stringify(user)) }));
          }),
          catchError((error) => of(authActions.loginError({ error: { message: error.message } })))
        )
      )
    )
  );

  logout$ = createEffect(() => this.actions$.pipe(ofType(authActions.logout)), { dispatch: false });

  setInitialPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.setInitialPassword),
      switchMap(({ username, temporaryPassword, newPassword, mfaRequested }) =>
        from(
          Auth.signIn({
            username: username,
            password: temporaryPassword,
          })
        ).pipe(
          switchMap((user) =>
            from(Auth.completeNewPassword(user, newPassword)).pipe(
              tap(() => {
                if (mfaRequested) {
                  this.router.navigate(['/login/mfa/setup']);
                }
              }),
              switchMap(() => of(authActions.setInitialPasswordSuccess())),
              catchError((error) => of(authActions.setInitialPasswordError({ error: { message: error.message } })))
            )
          ),
          catchError((error) => of(authActions.setInitialPasswordError({ error: { message: error.message } })))
        )
      )
    )
  );

  requestNewPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.requestNewPassword),
      switchMap(({ email }) =>
        from(Auth.forgotPassword(email)).pipe(
          tap(() => this.message.success('Password reset request successfull!')),
          switchMap(() => of(authActions.requestNewPasswordSuccess())),
          catchError((error) => of(authActions.requestNewPasswordError({ error: { message: error.message } })))
        )
      )
    )
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.resetPassword),
      switchMap(({ email, code, password }) =>
        from(Auth.forgotPasswordSubmit(email, code, password)).pipe(
          switchMap(() => of(authActions.resetPasswordSuccess())),
          catchError((error) => of(authActions.resetPasswordError({ error: { message: error.message } })))
        )
      )
    )
  );

  changePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.changePassword),
      switchMap((result) => from(Auth.currentAuthenticatedUser()).pipe(map((user) => [result, user]))),
      switchMap(([{ newPassword, oldPassword }, user]) =>
        from(Auth.changePassword(user, oldPassword, newPassword)).pipe(
          tap(() => this.message.success('Password changed successfully!')),
          switchMap(() => of(authActions.changePasswordSuccess())),
          catchError((error) => of(authActions.changePasswordError({ error: { message: error.message } })))
        )
      )
    )
  );

  setupMfa$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.setupMfa),
      switchMap((result) => from(Auth.currentAuthenticatedUser()).pipe(map((user) => [result, user]))),
      switchMap(([_, user]) => from(Auth.setupTOTP(user))),
      switchMap((code) => of(authActions.setupMfaSuccess({ code })))
    )
  );

  verifyMfaSetupToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.verifyMfaSetupToken),
      switchMap((result) => from(Auth.currentAuthenticatedUser()).pipe(map((user) => [result, user]))),
      switchMap(([{ answer }, user]) =>
        from(Auth.verifyTotpToken(user, answer)).pipe(
          tap(() => this.router.navigate(['/'])),
          catchError((error) => of(authActions.mfaCodeError({ error: { message: error.message } })))
        )
      ),
      switchMap((result) => from(Auth.currentAuthenticatedUser()).pipe(map((user) => [result, user]))),
      switchMap(([_, user]) => from(Auth.setPreferredMFA(user, 'TOTP'))),
      switchMap(() => of(authActions.verifyMfaSetupTokenSuccess()))
    )
  );

  confirmMfaSignIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.confirmMfaSignIn),
      switchMap(({ answer }) => {
        return from(Auth.confirmSignIn(this.mfaIntermediateUser, answer, 'SOFTWARE_TOKEN_MFA')).pipe(
          tap((user) => this.amplifyInternalService.updateFirstLogInTimestamp(user)),
          switchMap((user) => of(authActions.loginSuccess({ user: JSON.parse(JSON.stringify(user)) }))),
          tap(() => {
            this.mfaIntermediateUser = null;
            this.router.navigate(['/']);
          }),
          catchError((error) => of(authActions.mfaCodeError({ error: { message: error.message } })))
        );
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    protected store: Store,
    private router: Router,
    private amplifyInternalService: AmplifyInternalService,
    private message: NzMessageService
  ) {}
}
