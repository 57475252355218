import { Injectable } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { Store } from '@ngrx/store';
import { actions } from '@twaice-fe/frontend/shared/store';
import { CognitoUser } from '@twaice-fe/shared/models';
import { Hub } from 'aws-amplify';

const { authActions } = actions;

@Injectable({
  providedIn: 'root',
})
export class AmplifyInternalService {
  constructor(private store: Store) {
    Hub.listen('auth', () => {
      this.emitUser();
    });

    // emit user initially to determine if a session exists
    this.emitUser();
  }

  private async emitUser(): Promise<void> {
    try {
      const user = await Auth.currentAuthenticatedUser();

      this.store.dispatch(authActions.userChanged({ user: JSON.parse(JSON.stringify(user)) }));
    } catch (error) {
      this.store.dispatch(authActions.userChanged({ user: null }));
    }
  }

  async updateUserAttribute(attributes: Record<string, string>): Promise<string> {
    const user = await Auth.currentAuthenticatedUser();
    return Auth.updateUserAttributes(user, attributes);
  }

  updateFirstLogInTimestamp(user: CognitoUser, force: 'FORCE' | 'SOFT' = 'SOFT'): void {
    if (!user?.['attributes']) return;

    // if attribute is not set in cognito, just ignore & don't update
    if (!('custom:password_change_ts' in user['attributes'])) {
      return;
    }

    const timestampNeedsToBeSet = user['attributes']['custom:password_change_ts'] === '0' || force === 'FORCE';

    if (timestampNeedsToBeSet) {
      this.updateUserAttribute({ 'custom:password_change_ts': String(Date.now()) });
    }
  }

  private handleLogin(user: CognitoUser): void {
    this.updateFirstLogInTimestamp(user);
  }
}
