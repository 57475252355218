import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'twaice-fe-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent {
  @Input()
  type: 'default' | 'inverse' | 'warning' | 'error' | 'succes' | 'primary' = 'default';

  @Input()
  subtle = false;
}
