/* eslint-disable @typescript-eslint/naming-convention -- API defined property names */

import { TWResponse } from './response.models';
import { IsOptional } from 'class-validator';

export class Container {
  id: string;
  parent?: string;
  name: string;
  level: number;
  depth: number;
  level_name: string;
  system_id?: string;
  parent_id?: string;
  meta_data: any;
  children?: Map<string, Container>;
}

export class ContainerResponse extends TWResponse {
  data: Container;
}

export class ContainerListResponse extends TWResponse {
  data: Container[];
}

export class ContainerTreeResponse extends TWResponse {
  data: Map<string, Container>;
}

export class SubTreeRequestParams {
  @IsOptional()
  depth?: number;
  @IsOptional()
  with_sensors?: boolean;
}
