import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ENERGY_ANALYTICS_FEATURE_KEY, safetyActionAdapter, State } from '../reducers/energy-analytics.reducer';
import { ActionStateEnum } from '@twaice-fe/shared/models';

export const getEnergyAnalyticsState = createFeatureSelector<State>(ENERGY_ANALYTICS_FEATURE_KEY);
const safetyActionSelectors = safetyActionAdapter.getSelectors();
export const getRequiredActions = createSelector(getEnergyAnalyticsState, (state) =>
  safetyActionSelectors.selectAll(state.safetyRecommendationData.requiredActions)
);
export const getRequiredActionsEntities = createSelector(getEnergyAnalyticsState, (state) =>
  safetyActionSelectors.selectEntities(state.safetyRecommendationData.requiredActions)
);

export const getRecommendedActions = createSelector(getEnergyAnalyticsState, (state) =>
  safetyActionSelectors.selectAll(state.safetyRecommendationData.recommendedActions)
);

export const getRecommendedActionsEntities = createSelector(getEnergyAnalyticsState, (state) =>
  safetyActionSelectors.selectEntities(state.safetyRecommendationData.recommendedActions)
);

export const getSafetyRecommendationError = createSelector(
  getEnergyAnalyticsState,
  (state) => state.safetyRecommendationData.error
);
export const getSafetyRecommendationLoadingState = createSelector(
  getEnergyAnalyticsState,
  (state) => state.safetyRecommendationData.isLoading
);

export const getSafetyRecommendationOverviewState = createSelector(getEnergyAnalyticsState, (state) => ({
  [ActionStateEnum.REQUIRED]: state.safetyRecommendationData.requiredActions.ids.length,
  [ActionStateEnum.RECOMMENDED]: state.safetyRecommendationData.recommendedActions.ids.length,
  [ActionStateEnum.STABLE]: state.safetyRecommendationData.numberOfStableStrings,
}));

export const getComponentByComponentBk = ({ componentBk, actionType }: { componentBk: string; actionType: ActionStateEnum }) =>
  createSelector(
    actionType === ActionStateEnum.REQUIRED ? getRequiredActionsEntities : getRecommendedActionsEntities,
    (entities) => entities[componentBk] || undefined
  );

export const getSystemIngestionStatisticsLastSeenTimestamp = createSelector(
  getEnergyAnalyticsState,
  (state) => state.systemIngestionStatistics.lastSeen
);

export const getSafetyRecommendationComponentDetails = createSelector(
  getEnergyAnalyticsState,
  (state) => state.safetyRecommendationComponentDetails
);

export const getSafetyRecommendationComponentDetailsEntities = createSelector(
  getSafetyRecommendationComponentDetails,
  (state) => state.kpiStates
);

export const getSafetyRecommendationComponentDetailsError = createSelector(
  getSafetyRecommendationComponentDetails,
  (state) => state.error
);

export const getSafetyRecommendationComponentDetailsLoadingState = createSelector(
  getSafetyRecommendationComponentDetails,
  (state) => state.isLoading
);
