import { Injectable } from '@angular/core';
import { scaleLinear, Selection } from 'd3';
import { Subscription } from 'rxjs';
import { HeatmapEventService } from '../../chart-events/heatmap-event.service';
import { ChartLayoutService } from '../../chart-helpers/chart-layout.service';
import { ChartViewInterface } from '../chart-view.interface';
import { HeatmapCellData, HeatmapChartData, HeatmapViewOptions } from '../models/chart-view.models';

@Injectable()
export class HeatmapViewService implements ChartViewInterface<HeatmapChartData, HeatmapViewOptions> {
  private eventSubscription: Subscription;

  constructor(private eventService: HeatmapEventService) {}

  draw({
    chartSVGContainer,
    chartLayoutService,
    data,
    options,
  }: {
    chartSVGContainer: Selection<SVGGElement, unknown, null, undefined>;
    chartLayoutService: ChartLayoutService;
    data: HeatmapChartData;
    options: HeatmapViewOptions;
  }): Selection<SVGGElement, unknown, null, undefined> {
    const {
      color,
      xPadding,
      yPadding,
      cornerRadius,
      events: { onHover },
    } = {
      ...{ xPadding: 0, yPadding: 0, cornerRadius: 0, events: { onHover: () => {} } },
      ...options,
    };
    const yScale = chartLayoutService.getYScale();
    const xScale = chartLayoutService.getXScale();

    const matrixSize = data.data.length - 1;

    const indexedData: HeatmapCellData[] = data.data.flatMap((row, rowIndex) =>
      row.map((value, columnIndex) => ({ rowIndex, columnIndex, value }))
    );

    let colorScale;
    if (Array.isArray(color)) {
      colorScale = scaleLinear()
        // d3 typing is wrong, linear scales can be constructed an string[] AND number[]
        .range(color as any)
        .domain([0, Math.max(...data.data.flat())]);
    } else {
      colorScale = color;
    }

    chartSVGContainer
      .append('g')
      .attr('id', 'heatmap')
      .attr('class', 'heatmap')
      .attr('data-test', 'heatmap')
      .selectAll('g')
      .data(indexedData)
      .join('rect')
      .attr('x', (d) => xScale(d.columnIndex) + xPadding / 2)
      .attr('y', (d) => yScale(matrixSize - d.rowIndex + 1) + yPadding / 2)
      .attr('rx', cornerRadius)
      .attr('ry', cornerRadius)
      .attr('width', () => xScale(1) - xScale(0) - xPadding)
      .attr('height', () => yScale(0) - yScale(1) - yPadding)
      .style('fill', (d) => colorScale(d.value));

    this.eventService.attachEvent({ chartSVGContainer: chartSVGContainer.select('#heatmap') });
    this.eventSubscription = this.eventService.getHoverEvents().subscribe(onHover);

    return chartSVGContainer;
  }

  clear(chartSVGContainer: Selection<SVGGElement, unknown, null, undefined>): void {
    chartSVGContainer.selectAll('#heatmap').remove();
    this.eventSubscription.unsubscribe();
  }
}
