/* Simple interface as we do not want to be importing this from @angular/router as we are using it also in BE4FE */

interface Params {
  [key: string]: any;
}

export function getArrayQueryParamsByName(params: Params, paramName: string): string[] {
  let queryParams: string[] = [];
  if (params[paramName] && params[paramName].length) {
    if (typeof params[paramName] === 'string') {
      queryParams = [params[paramName]];
    } else {
      queryParams = params[paramName];
    }
  }
  return [...new Set(queryParams)];
}

export function getArrayFromQueryParams(queryParams: string | string[] = []): string[] {
  if (typeof queryParams === 'string') {
    queryParams = [queryParams];
  }
  return queryParams;
}

export function getQueryParamAsNumber(params: Params, paramName: string, defaultValue = 0): number {
  if (params[paramName] && params[paramName].length) {
    return parseInt(params[paramName], 10);
  }
  return defaultValue;
}

export function getQueryParamAsDate(params: Params, paramName: string): Date {
  return new Date(getQueryParamAsNumber(params, paramName));
}

export const normalizeQueryParams = (value: Record<string, string>): Record<string, string> => {
  if (!(value instanceof Object) || Array.isArray(value)) {
    return value;
  }

  const normalizedQueryParams = {
    ...value,
    ...(value['limit'] ? { page_size: value['limit'] } : {}),
    ...(value['systemBk'] ? { system_bk: value['systemBk'] } : {}),
    ...(value['isActive'] ? { is_active: value['isActive'] } : {}),
    ...(value['order']
      ? {
          sort_by: value['order'].split(':')[0],
          sort_direction: value['order'].split(':')[1] === 'asc' ? 'ascending' : 'descending',
        }
      : {}),
  };

  delete normalizedQueryParams['order'];
  delete normalizedQueryParams['limit'];
  delete normalizedQueryParams['systemBk'];

  // TODO automatically reformat camelCase to snakeCase

  return normalizedQueryParams;
};
