<div class="options-filter-container">
  <label id="option-label">{{ filterConfig?.label }}</label>
  <mat-radio-group aria-labelledby="options-filter" class="options-group" [formControl]="selectedOption">
    <mat-radio-button
      class="option-item"
      *ngFor="let option of filterConfig.options | keyvalue: keepOrder"
      [value]="option.value"
    >
      {{ option.key | titlecase }}
    </mat-radio-button>
  </mat-radio-group>
</div>
