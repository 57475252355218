import { ConnectedPosition, Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { blue500, blueGray500 } from '@twaice-fe/frontend/shared/utilities';
import { TooltipArrowComponent, TooltipComponent } from './tooltip.component';

@Directive({
  selector: '[twaiceFeTooltip]',
})
export class TooltipDirective implements OnInit, OnDestroy {
  // if there is no text available for the tooltip, it will simply not display
  @Input() twaiceFeTooltip: string | string[];

  @Input() dashedUnderline: boolean;

  private overlayRefTooltipContent: OverlayRef;
  private tooltipContentRef: ComponentRef<TooltipComponent>;
  private overlayRefTooltipArrow: OverlayRef;
  private tooltipArrowRef: ComponentRef<TooltipArrowComponent>;
  private positionTooltip: ConnectedPosition = {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top',
    offsetY: 10,
  };

  private readonly arrowHeight = 15;

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef
  ) {}

  @HostListener('mouseover')
  show() {
    if (!this.twaiceFeTooltip) {
      return;
    }

    if (this.dashedUnderline) {
      this.elementRef.nativeElement.style.textDecorationColor = blue500;
      this.elementRef.nativeElement.style.color = blue500;
    }

    //attach the component if it has not already attached to the overlay
    if (
      this.overlayRefTooltipContent &&
      !this.overlayRefTooltipContent.hasAttached() &&
      this.overlayRefTooltipArrow &&
      !this.overlayRefTooltipArrow.hasAttached()
    ) {
      this.tooltipContentRef = this.overlayRefTooltipContent.attach(new ComponentPortal(TooltipComponent));
      this.tooltipArrowRef = this.overlayRefTooltipArrow.attach(new ComponentPortal(TooltipArrowComponent));
      this.tooltipContentRef.instance.text = this.twaiceFeTooltip;
    }

    this.tooltipContentRef.instance.tooltipContentHeightEvent.subscribe((tooltipContentHeight) => {
      if (
        window.innerHeight - this.elementRef.nativeElement.getBoundingClientRect().bottom <
        tooltipContentHeight + this.arrowHeight
      ) {
        this.positionTooltip.offsetY = -35;
        this.positionTooltip.overlayY = 'bottom';
        this.tooltipArrowRef.instance.position = 'top';
      } else {
        this.positionTooltip.offsetY = 10;
        this.positionTooltip.overlayY = 'top';
        this.tooltipArrowRef.instance.position = 'bottom';
      }
    });
    this.overlayRefTooltipContent.updatePositionStrategy(
      this.overlay.position().flexibleConnectedTo(this.elementRef).withPositions([this.positionTooltip])
    );
    this.overlayRefTooltipArrow.updatePositionStrategy(
      this.overlay.position().flexibleConnectedTo(this.elementRef).withPositions([this.positionTooltip])
    );
  }

  @HostListener('mouseleave')
  hide() {
    if (this.dashedUnderline) {
      this.elementRef.nativeElement.style.textDecorationColor = blueGray500;
      this.elementRef.nativeElement.style.color = null;
    }

    this.closeToolTip();
  }

  ngOnInit() {
    if (this.dashedUnderline) {
      this.elementRef.nativeElement.style.textDecoration = `underline ${blueGray500} dashed 1px`;
      this.elementRef.nativeElement.style.textUnderlineOffset = '5px';
      this.elementRef.nativeElement.style.cursor = 'help';
    }

    const positionStrategyTooltipContent = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([this.positionTooltip]);
    this.overlayRefTooltipContent = this.overlay.create({ positionStrategy: positionStrategyTooltipContent });
    const positionStrategyTooltipArrow = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([this.positionTooltip]);
    this.overlayRefTooltipArrow = this.overlay.create({ positionStrategy: positionStrategyTooltipArrow });
  }

  ngOnDestroy() {
    this.closeToolTip();
  }

  private closeToolTip() {
    if (this.overlayRefTooltipContent && this.overlayRefTooltipArrow) {
      this.overlayRefTooltipContent.detach();
      this.overlayRefTooltipArrow.detach();
    }
  }
}
