import { TimeInterval } from '@twaice-fe/shared/utilities';
import { timeFormat } from 'd3';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type TickFormatOptionsType = {
  value: number;
  unit?: string;
  precision?: number;
};

const timeFormats = {
  millisecond: timeFormat('.%L'),
  second: timeFormat(':%S'),
  minute: timeFormat('%H:%M'),
  hour: timeFormat('%H:%M'),
  day: timeFormat('%a %d %H:%M'),
  week: timeFormat('%b %d'),
  month: timeFormat('%B'),
  monthYear: timeFormat('%b %Y'),
  year: timeFormat('%Y'),
};

const getFormat = (interval: number, isFirstTick: boolean) => {
  if (interval < TimeInterval.SECOND) return timeFormats.millisecond;
  if (interval < TimeInterval.MINUTE) return isFirstTick ? timeFormats.minute : timeFormats.second;
  if (interval < TimeInterval.HOUR) return isFirstTick ? timeFormats.hour : timeFormats.minute;
  if (interval < TimeInterval.DAY) return isFirstTick ? timeFormats.day : timeFormats.hour;
  if (interval < TimeInterval.ONE_WEEK) return timeFormats.day;
  if (interval < TimeInterval.THREE_MONTHS) return timeFormats.week;
  if (interval < TimeInterval.SIX_MONTHS) return timeFormats.month;
  if (interval < TimeInterval.THREE_YEARS) return timeFormats.monthYear;
  return timeFormats.year;
};

export const tickFormat = (interval: number, date: Date, isFirstTick = false) => {
  const format = getFormat(interval, isFirstTick);
  return format(date);
};

export const formatTick = ({ value, unit = '', precision }: TickFormatOptionsType): string => {
  const formattedValue = precision != null ? value.toFixed(precision) : value.toString();
  return `${formattedValue} ${unit}`.trim();
};
