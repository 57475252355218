import {
  AxisInfo,
  blue500,
  ChartDimensions,
  ChartLayout,
  gray100,
  XAxisDirection,
  YAxisDirection,
} from '@twaice-fe/frontend/shared/utilities';

export const barChartDimensions: ChartDimensions = {
  marginTop: 10,
  marginBottom: 45,
  marginRight: 10,
  marginLeft: 70,
  axisOffsetTop: 0,
  axisOffsetBottom: 0,
  axisOffsetRight: -60,
  axisOffsetLeft: 0,
};

export const barChartXAxisInfo: AxisInfo = {
  axisDirection: XAxisDirection.BOTTOM,
  ticksCount: 5,
  hasAxisLineDomain: false,
  hasAxisGrid: false,
  autoTicks: false,
  ticksWithPrecision: true,
};

export const barChartYAxisInfo: AxisInfo = {
  axisDirection: YAxisDirection.LEFT,
  ticksCount: 4,
  hasAxisLineDomain: false,
  hasAxisGrid: true,
  autoTicks: false,
  ticksWithPrecision: false,
};

export const initialBarChartLayout: ChartLayout = {
  yTickValuesPrecision: 0,
  chartColors: [blue500, gray100],
  hasSelection: true,
  hasTooltip: true,
};
