import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, take } from 'rxjs';

export interface Breadcrumb {
  url: string | (() => string);
  label: string | (() => string);
  // any since we don't care what gets emitted, we just pop on emission
  destroy$?: Observable<any>;
}

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsService {
  private breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);

  getBreadcrumbs(): Observable<Breadcrumb[]> {
    return this.breadcrumbs$.asObservable();
  }

  pushBreadcrumb(breadcrumb: Breadcrumb): void {
    this.breadcrumbs$.next([...this.breadcrumbs$.getValue(), breadcrumb]);

    if (breadcrumb.destroy$) {
      breadcrumb.destroy$.pipe(take(1)).subscribe(() => {
        const breadcrumbs = this.breadcrumbs$.getValue();
        this.breadcrumbs$.next(breadcrumbs.filter((b) => !(b.url === breadcrumb.url && b.label === breadcrumb.label)));
      });
    }
  }

  popBreadcrumb(): void {
    const breadcrumbs = this.breadcrumbs$.getValue();
    breadcrumbs.pop();
    this.breadcrumbs$.next(breadcrumbs);
  }

  clearBreadcrumbs(): void {
    this.breadcrumbs$.next([]);
  }
}
