import { createAction, props } from '@ngrx/store';
import { CognitoUser } from '@twaice-fe/shared/models';

/**
 * Login / Logout
 */
export const login = createAction('[Auth] Login', props<{ email: string; password: string }>());
export const loginSuccess = createAction('[Auth] Login Success', props<{ user: CognitoUser }>());
export const loginError = createAction('[Auth] Login Error', props<{ error: any }>());
export const loginMFAPending = createAction('[Auth] Login MFA Pending');
export const logout = createAction('[Auth] Logout');

/**
 * Initial Password
 */
export const setInitialPassword = createAction(
  '[Auth] SetInitialPassword',
  props<{ username: string; temporaryPassword: string; newPassword: string; mfaRequested: boolean }>()
);
export const setInitialPasswordSuccess = createAction('[Auth] Set Initial Password Success');
export const setInitialPasswordError = createAction('[Auth] Set Initial Password Error', props<{ error: any }>());

/**
 * Request New Password
 */
export const requestNewPassword = createAction('[Auth] Request New Password', props<{ email: string }>());
export const requestNewPasswordSuccess = createAction('[Auth] Request New Password Success');
export const requestNewPasswordError = createAction('[Auth] Request New Password Error', props<{ error: any }>());

/**
 * Reset Password
 */
export const resetPassword = createAction('[Auth] Reset Password', props<{ email: string; code: string; password: string }>());
export const resetPasswordSuccess = createAction('[Auth] Reset Password Success');
export const resetPasswordError = createAction('[Auth] Reset Password Error', props<{ error: any }>());

/**
 * Change Password
 */
export const changePassword = createAction('[Auth] Change Password', props<{ oldPassword: string; newPassword: string }>());
export const changePasswordSuccess = createAction('[Auth] Change Password Success');
export const changePasswordError = createAction('[Auth] Change Password Error', props<{ error: any }>());

/**
 * Multi-factor Authentication
 */
export const setupMfa = createAction('[Auth] Setup Mfa');
export const setupMfaSuccess = createAction('[Auth] Setup Mfa Success', props<{ code: string }>());
export const verifyMfaSetupToken = createAction('[Auth] Verify Mfa Setup Token', props<{ answer: string }>());
export const verifyMfaSetupTokenSuccess = createAction('[Auth] Verify Mfa Setup Token Success');
export const confirmMfaSignIn = createAction('[Auth] Confirm Mfa Sign-In', props<{ answer: string }>());
export const mfaCodeError = createAction('[Auth] Mfa Error', props<{ error: any }>());

/**
 * Generic user change
 */
export const userChanged = createAction('[Auth] UserChanged', props<{ user: CognitoUser }>());
