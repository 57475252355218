export const keysToSnake = (obj: Record<string, any>): any => {
  if (!Array.isArray(obj) && obj instanceof Object) {
    const newObject = {};
    Object.keys(obj).forEach((key) => (newObject[camelToSnake(key)] = keysToSnake(obj[key])));
    return newObject;
  } else if (Array.isArray(obj)) {
    obj = obj.map((item) => keysToSnake(item));
  }

  return obj;
};

const camelToSnake = (key: string): string =>
  (key || '').replace(/([A-Z])/g, (match, group) => `_${group.toLowerCase()}`).replace(/^_/, '');
