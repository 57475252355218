import { XAxisDirection, YAxisDirection } from '@twaice-fe/frontend/shared/utilities';

export const xAxisConfig = {
  axisDirection: XAxisDirection.BOTTOM,
  isAxisInTime: true,
  ticksCount: 10,
  hasAxisLineDomain: false,
  hasAxisGrid: false,
  autoTicks: true,
};

export const yAxisConfig = {
  axisDirection: YAxisDirection.LEFT,
  isAxisInTime: false,
  ticksCount: 5,
  hasAxisLineDomain: false,
  hasAxisGrid: true,
  autoTicks: false,
  ticksWithPrecision: true,
};

export const dimensions = {
  marginTop: 20,
  marginBottom: 25,
  marginRight: 10,
  marginLeft: 50,
  axisOffsetTop: 0,
  axisOffsetBottom: 10,
  axisOffsetRight: -50,
  axisOffsetLeft: 0,
};
