import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertPanelComponent } from './alert-panel.component';
import { MatIconModule } from '@angular/material/icon';
import { CustomIconModule } from '../icon/custom-icon.module';

@NgModule({
  declarations: [AlertPanelComponent],
  imports: [CommonModule, MatIconModule, CustomIconModule],
  exports: [AlertPanelComponent],
})
export class AlertPanelModule {}
