import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { DialogComponent } from './dialog.component';
import { CustomIconModule } from '../icon/custom-icon.module';

@NgModule({
  declarations: [DialogComponent],
  imports: [CommonModule, MatIconModule, CustomIconModule],
  exports: [DialogComponent],
})
export class DialogModule {}
