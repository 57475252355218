import { Injectable } from '@angular/core';
import { Selection, stack, stackOrderNone } from 'd3';
import { ChartLayoutService } from '../../chart-helpers/chart-layout.service';
import { ChartViewInterface } from '../chart-view.interface';
import { MobilityStackedBarChartData, MobilityStackedBarViewOptions } from '../models/chart-view.models';

@Injectable()
export class MobilityBarViewService implements ChartViewInterface<MobilityStackedBarChartData[], MobilityStackedBarViewOptions> {
  draw({
    chartSVGContainer,
    chartLayoutService,
    data,
    options,
  }: {
    chartSVGContainer: Selection<SVGGElement, unknown, null, undefined>;
    chartLayoutService: ChartLayoutService;
    data: MobilityStackedBarChartData[];
    options: MobilityStackedBarViewOptions;
  }): Selection<SVGGElement, unknown, null, undefined> {
    const { order, color, xPadding, yPadding } = { ...{ xPadding: 0, yPadding: 0 }, ...options };
    const yScale = chartLayoutService.getYScale();
    const xScale = chartLayoutService.getXScale();

    // categories are the individual "blocks" of each stacked bar
    const categories = new Set<string>();
    data.forEach((d) => {
      Object.keys(d.y).forEach((k) => categories.add(k));
    });

    const stackedData = stack()
      // typing on this is wrong from d3 side
      // we are guaranteed to have a "key" field, corresponding to a member of our categories
      .order(order ? (d) => d.map((a: any) => order.indexOf(a.key)) : stackOrderNone)
      .keys(categories)(data.map((d) => ({ ...d.y, x: d.x })));

    chartSVGContainer
      .append('g')
      .attr('id', 'stacked-bars')
      .attr('class', 'stacked-bars')
      .attr('data-test', 'stacked-bars')
      .selectAll('g')
      .data(stackedData)
      .join('g')
      .attr('fill', (d) => color[d.index])
      .selectAll('rect')
      .data((d) => d)
      .join('rect')
      .attr('x', (d) => xScale(Number(d.data.x)) + xPadding / 2)
      .attr('y', (d) => yScale(d[1]))
      .attr('height', (d) => {
        // we add "padding" to layers by slightly reducing height
        // technically this falsifies our data viz but the difference is negligible
        const padding = d[0] !== 0 ? yPadding : 0;
        return yScale(d[0]) - yScale(d[1]) - padding;
      })
      .attr('width', xScale(1) - xScale(0) - xPadding);

    return chartSVGContainer;
  }

  clear(chartSVGContainer: Selection<SVGGElement, unknown, null, undefined>): void {
    chartSVGContainer.selectAll('#lines').remove();
  }
}
