import { formatTick } from './time-scale-utiltiy';
import { FilterBarValues } from './../chart-events/models/chart-view.models';
import { DatePipe } from '@angular/common';

export const formatFilterValuesToString = ({
  values,
  title = '',
  unit = '',
  precision = 0,
  dateFormat,
}: {
  values: FilterBarValues;
  title?: string;
  unit?: string;
  precision?: number;
  dateFormat?: string;
}): string => {
  if (!values) return null;

  if (values.min === 0 && values.max === 0) return title + ' missing component data';

  const formatFilterValue = (value) =>
    dateFormat ? new DatePipe('en-DE').transform(Date.now(), dateFormat) : formatTick({ value, unit, precision });

  return title + ' ' + `${formatFilterValue(values.min)} - ${formatFilterValue(values.max)}`;
};
