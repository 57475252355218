import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SystemPortfolioOverviewTableComponent } from './system-portfolio-overview-table.component';
import { DataTableModuleV2 } from '../datatable/datatable.module';
import { SystemOverviewTableCardComponent } from './system-overview-table-card/system-overview-table-card.component';
import { MatCardModule } from '@angular/material/card';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { MatDividerModule } from '@angular/material/divider';
import { DataDisplayModule } from '../data-display/data-display.module';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { PopoverIconModule } from '../popover-icon/popover-icon.module';
const NG_ZORRO_MODULES = [NzTableModule, NzTagModule, NzBadgeModule, NzToolTipModule];

@NgModule({
  declarations: [SystemPortfolioOverviewTableComponent, SystemOverviewTableCardComponent],
  exports: [SystemPortfolioOverviewTableComponent, SystemOverviewTableCardComponent],
  imports: [
    CommonModule,
    DataTableModuleV2,
    DataDisplayModule,
    SvgIconModule,
    MatDividerModule,
    MatCardModule,
    PopoverIconModule,
    ...NG_ZORRO_MODULES,
  ],
})
export class SystemOverviewTableModule {}
