import { Injectable } from '@angular/core';
import { select, selectAll, Selection } from 'd3';
import { BehaviorSubject, filter, Observable } from 'rxjs';
import { ChartEventsInterface } from './chart-events.interface';
import { HoverEvent } from './models/chart-view.models';

@Injectable()
export class HoverEventService<DataType> implements ChartEventsInterface {
  private hover$: BehaviorSubject<HoverEvent<DataType>> = new BehaviorSubject<HoverEvent<DataType>>(null);

  getHoverEvents(): Observable<HoverEvent<DataType>> {
    return this.hover$.asObservable().pipe(filter((v) => v !== null));
  }

  attachEvent({
    chartSVGContainer,
    selectedElement,
  }: {
    chartSVGContainer: Selection<SVGGElement, unknown, null, undefined>;
    selectedElement: string;
  }): void {
    chartSVGContainer
      .selectAll(selectedElement)
      .on('mouseover', (event, eventsInfo: DataType) => {
        selectAll(selectedElement).classed('not-hover', true);
        select(event.currentTarget).classed('not-hover', false).classed('hover', true);
        this.emitHoverEvent({ type: 'mouseover', data: eventsInfo, cell: select(event.currentTarget) });
      })
      .on('mouseout', (event) => {
        selectAll(selectedElement).classed('not-hover', false);
        select(event.currentTarget).classed('hover', false);
        this.emitHoverEvent({ type: 'mouseout' });
      });
  }

  private emitHoverEvent({
    type,
    data,
    cell,
  }: {
    type: 'mouseover' | 'mouseout';
    data?: DataType;
    cell?: Selection<SVGGElement, DataType, SVGElement, unknown>;
  }): void {
    this.hover$.next({ type, data: data, cell });
  }
}
