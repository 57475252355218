import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SideNavService } from '@twaice-fe/frontend/shared/services';
import {
  ACCOUNT_SETTINGS_ROUTE,
  ANALYTICS_UPSELL_ROUTE,
  MODEL_LIBRARY_BASE_ROUTE,
  ON_DEMAND_SERVICES_ROUTE,
  PRIVACY_POLICY_ROUTE,
} from '@twaice-fe/shared/constants';
import { CustomerType } from '@twaice-fe/shared/models';
import { authSelectors, configsSelectors } from 'libs/frontend/shared/store/src/selectors';

const SIDE_NAV_EXPANDED_STATE_KEY = 'tw-side-nav-expanded-state';

@Component({
  selector: 'twaice-fe-dashboard-sidenav',
  templateUrl: 'dashboard-sidenav.component.html',
  styleUrls: ['./dashboard-sidenav.component.scss'],
})
export class DashboardSideNavComponent {
  showSideNav = false;
  isCollapsed = false;
  customerType: CustomerType;

  // for use in template
  CustomerType = CustomerType;
  intercomTarget = 'dashboard-layout-sidenav';
  // mobility specific attributes
  isPartOfExploreAndCompare = false;

  constructor(
    protected sideNavService: SideNavService,
    protected store: Store,
    private router: Router
  ) {
    this.router.events.pipe(takeUntilDestroyed()).subscribe((event) => {
      if (this.customerType === CustomerType.MOBILITY && event instanceof NavigationStart) {
        this.isPartOfExploreAndCompare =
          event.url.includes('mobility-explore') && !event.url.includes('/mobility-explore/overview');
      }
      if (event instanceof NavigationEnd) {
        this.showSideNav = !this.isSolutionWithoutSidenav();
      }
    });

    this.sideNavService.sideNavState$.subscribe((state) => {
      this.isCollapsed = state === 'collapsed';
    });

    this.store
      .select(configsSelectors.getCustomerType)
      .pipe(takeUntilDestroyed())
      .subscribe((customerType) => {
        this.customerType = customerType;
      });

    // reset expanded state when user logs-out
    this.store
      .select(authSelectors.getUser)
      .pipe(takeUntilDestroyed())
      .subscribe((user) => {
        if (user) return;
        sessionStorage.removeItem(SIDE_NAV_EXPANDED_STATE_KEY);
      });
  }

  isSolutionWithoutSidenav(): boolean {
    return (
      this.router.url.includes(ACCOUNT_SETTINGS_ROUTE) ||
      this.router.url.includes(ON_DEMAND_SERVICES_ROUTE) ||
      this.router.url.includes(PRIVACY_POLICY_ROUTE) ||
      this.router.url.includes(ANALYTICS_UPSELL_ROUTE) ||
      this.router.url.includes(MODEL_LIBRARY_BASE_ROUTE)
    );
  }

  collapse(isCollapsed: boolean) {
    this.isCollapsed = isCollapsed;
    this.sideNavService.toggleSideNavState();
  }

  preventDefault($event: Event): void {
    $event.preventDefault();
    $event.stopImmediatePropagation();
  }

  routeIsActive(route: string): boolean {
    return this.router.url.includes(route);
  }
}
