import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Injectable()
export class SideNavService {
  private sideNav: MatSidenav;

  setSideNav(sidenav: MatSidenav) {
    this.sideNav = sidenav;
  }

  openMenu() {
    window.scroll(0, 0);
    document.body.classList.add('disabled-scrolling');
    return this.sideNav.open();
  }

  closeMenu() {
    document.body.classList.remove('disabled-scrolling');
    return this.sideNav.close();
  }
}
