<div class="h-full w-full" twFakeLoading [twFakeLoadingState]="loading">
  <iframe
    [src]="demoLink"
    style="border: 0"
    allow="fullscreen"
    allowfullscreen="true"
    loading="eager"
    name="walnut_iframe"
    sandbox="allow-scripts allow-same-origin allow-popups allow-forms allow-downloads"
    title="Walnut embedded demo"
    width="100%"
    height="100%"
    frameborder="none"
    (load)="onLoad()"
  >
  </iframe>
</div>
