import { DatabricksConfig, Environment } from './environment.model';

export const DATABRICKS_CONFIG_PROD: DatabricksConfig = {
  tokenArn: process.env['DATABRICKS_TOKEN_ARN'] || '/twaice/databricks/workspaces/prod_fe_token',
  pathArn:
    process.env['DATABRICKS_PATH_ARN'] || '/twaice/databricks/workspaces/prod/warehouses/prod-backend-sql-warehouse/http_path',
  hostArn:
    process.env['DATABRICKS_HOST_ARN'] ||
    '/twaice/databricks/workspaces/prod/warehouses/prod-backend-sql-warehouse/server_hostname',
  databaseNames: {
    measurements: process.env['DATABRICKS_MEASUREMENTS_DB_NAME_ARN'] || 'prod_dl_measurements',
    battery_kpis: process.env['DATABRICKS_BATTERY_KPIS_DB_NAME_ARN'] || 'prod_dl_battery_kpis',
    customer_config: process.env['DATABRICKS_CUSTOMER_CONFIG_DB_NAME_ARN'] || 'prod_dl_customer_config',
    performance_manager: process.env['DATABRICKS_PERFORMANCE_MANAGER_DB_NAME_ARN'] || 'prod_dl_performance_manager',
  },
};

export const ENVIRONMENT_PROD: Environment = {
  production: true,
  name: process.env['ENV_NAME'] || 'prod',
  staticFilesHost: 'https://static.twaice.ai/',
  baseUrlBe4Fe: 'https://be4fe.twaice.ai/',
  baseUrlApi: 'https://api.twaice.ai/',
  /* eslint-enable @typescript-eslint/naming-convention */

  // public identifier for the Transifex project
  transifexToken: '1/e60edf51d3cbc5c2a847caef792f9da32ba18ef6',
  // public Hotjar site ID
  hotjarSiteId: '3451095',
  // public Intercom App ID
  intercomAppId: 'thagvi83',
  // public Mixpanel ID
  mixpanelId: 'e98640b38fc715de1089148141a0513e',
};
