import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from '@env/fe';
import { init as sentryInit } from '@sentry/angular';
import { browserTracingIntegration, replayIntegration } from '@sentry/browser';

import { AppModule } from './app/app.module';

sentryInit({
  dsn: 'https://1f09eed14635a923c465b459285f47d7@o4506949880315904.ingest.us.sentry.io/4506953889153024',
  integrations: [
    browserTracingIntegration(),
    replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/api\.(?:dev\.|stg\.|)twaice\.ai/,
    /^https:\/\/be4fe\.(?:dev\.|stg\.|)twaice\.ai/,
  ],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  // append "tmp-" to env name if it's a temp deployment
  environment: window.location.hostname.includes('twaice.ai') ? `tmp-${environment.name}` : environment.name,
  enabled: !environment.version.includes('local'),
  release: environment.version,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
