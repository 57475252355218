import { NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SafeAssetPathPipe, SafeAssetPathPipeModule } from '@twaice-fe/frontend/shared/utilities';

@NgModule({
  imports: [MatIconModule, SafeAssetPathPipeModule],
})
export class CustomIconModule {
  iconPath = 'assets/icons/applications';

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private safeAssetPathPipe: SafeAssetPathPipe
  ) {
    this.matIconRegistry.addSvgIconResolver((name) => {
      if (!name.includes(this.iconPath)) {
        name = `${this.iconPath}/${name}`;
      }

      if (!name.includes('.svg')) {
        name += '.svg';
      }

      return this.domSanitizer.bypassSecurityTrustResourceUrl(this.safeAssetPathPipe.transform(name));
    });
  }
}
