export const ENERGY_ANALYTICS_DEMO_LINK =
  'https://demo.twaice.com/player/?demoId=3d820a6d-9c2c-493c-b9ed-6808ed1641bf&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app';

export const ENERGY_INCIDENTS_DEMO_LINK =
  'https://demo.twaice.com/player/?demoId=ad6e664c-78e0-4d1b-a1f1-5131620575e5&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app';

export const ENERGY_WARRANTY_DEMO_LINK =
  'https://demo.twaice.com/player/?demoId=31705973-5d6f-4e72-8f24-05b9d3b971a2&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app';

export const REPORTING_DEMO_LINK =
  'https://demo.twaice.com/player/?demoId=f8d5a5e0-2073-4632-8598-4e4b57e3dfd2&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app';

export const MOBILITY_HEALTH_DEMO_LINK =
  'https://demo.twaice.com/player/?demoId=ae31d828-39d5-40e5-a6f3-d8532006f6e2&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app';

export const BATTERY_ENCYCLOPEDIA_LINK = 'https://www.twaice.com/battery-encyclopedia';

export const PERFORMANCE_MANAGER_LINK = 'https://oim.twaice.cloud/oim/login';

export const DEMO_DOWNLOAD_LINK = 'https://www.twaice.com/form/try-our-model-with-matlab';
