import { formatNumber } from '@angular/common';
import { PredictionMeasurements } from '@twaice-fe/shared/models';
import {
  blue400A05,
  blue400A15,
  blue500,
  blueGray400,
  blueGray500,
  blueGray500A15,
  blueGray500A40,
  colorLight,
  colorTransparent,
  fontSizeSm,
  fontSizeXl,
  orange500,
  red500,
  red500A15,
} from '../base-variables';
import { hexToRgb } from '../hex-to-rgb';
import { PlotlyData } from '../plotly';
import { ChartSection } from './models/chart-section';
import { PredictionChartCalculations } from './prediction-chart-calculations';

export enum LineType {
  DOTTED,
  SOLID,
  SOLID_WITH_MARKERS,
}

export interface PredictionChartPlotColorScheme {
  main: string;
  mainTransparent: string;
  mainSemiTransparent: string;

  danger: string;
  dangerTransparent: string;
  gray?: string;
  grayTransparent?: string;

  warning?: string;
}

export class PredictionChartPlotData {
  static COLOR_SCHEME: PredictionChartPlotColorScheme = {
    main: blue500,
    mainSemiTransparent: blue400A15,
    mainTransparent: blue400A05,

    danger: red500,
    dangerTransparent: red500A15,

    gray: blueGray500,
    grayTransparent: blueGray500A15,

    warning: orange500,
  };

  static setPlotColorScheme(primary: string, danger?: string, warning?: string) {
    if (primary) {
      this.COLOR_SCHEME.main = primary;
      this.COLOR_SCHEME.mainSemiTransparent = hexToRgb(primary, 0.15);
      this.COLOR_SCHEME.mainTransparent = hexToRgb(primary, 0.05);
    }
    if (danger) {
      this.COLOR_SCHEME.danger = danger;
      this.COLOR_SCHEME.dangerTransparent = hexToRgb(danger, 0.15);
    }
    if (warning) {
      this.COLOR_SCHEME.warning = warning;
    }
  }

  /*  This function returns a list of graphs to imitate the legend we require */
  static getLegend(fullLineLegendName?: string, dottedLineLegendName?: string, areaLegendName?: string): PlotlyData[] {
    const legendGraphs: PlotlyData[] = [];
    // with current version of plotly we can only manipulate spacing between legend items by adding spaces in text
    const spacer = '    ';

    // time can never be bellow 0, so this graphs should never exist due to their x and y location
    if (areaLegendName) {
      legendGraphs.push({
        x: [-100],
        y: [-100],
        stackgroup: 'legend',
        fillcolor: this.COLOR_SCHEME.mainSemiTransparent,
        line: {
          width: 0,
          smoothing: 0.1,
        },
        showlegend: true,
        name: areaLegendName,
      });
    }

    if (dottedLineLegendName) {
      legendGraphs.push({
        x: [-100],
        y: [-100],
        name: dottedLineLegendName + spacer,
        mode: 'lines',
        line: {
          dash: 'dot',
          color: this.COLOR_SCHEME.main,
        },
      });
    }

    if (fullLineLegendName) {
      legendGraphs.push({
        x: [-100],
        y: [-100],
        name: fullLineLegendName + spacer,
        mode: 'lines',
        line: {
          color: this.COLOR_SCHEME.main,
        },
      });
    }

    return legendGraphs;
  }

  /*  This function returns a list of graphs to imitate the legend we require */
  static getLegendForEnergyPrediction(
    fullLineLabel: string,
    dashedLineLabel: string,
    areaLabel: string,
    dottedAreaLabel?: string
  ): PlotlyData[] {
    // with current version of plotly we can only manipulate spacing between legend items by adding spaces in text
    const spacer = '&nbsp;'.repeat(5);
    // time can never be bellow 0, so this graphs should never exist
    return [
      {
        x: [-100],
        y: [-100],
        showlegend: !!dottedAreaLabel,
        name: dottedAreaLabel,
        line: {
          color: '#81CBCF',
          width: 5,
        },
        mode: 'lines',
      },
      {
        x: [-100],
        y: [-100],
        stackgroup: 'legend',
        fillcolor: blueGray500A40,
        line: {
          width: 0,
          smoothing: 0.1,
        },
        showlegend: true,
        name: areaLabel + spacer,
      },
      {
        x: [-100],
        y: [-100],
        name: dashedLineLabel + spacer,
        mode: 'lines',
        line: {
          dash: 'dot',
          color: this.COLOR_SCHEME.main,
        },
      },
      {
        x: [-100],
        y: [-100],
        name: fullLineLabel + spacer,
        mode: 'lines',
        line: {
          color: this.COLOR_SCHEME.main,
        },
      },
    ];
  }

  /*
   * This method returns our base value line graphs(for estimation or prediction values).
   * It is the only type of graphs where we also display hover info on.
   * */
  static getBaseGraph({
    measurements,
    locale,
    limit,
    sections,
    colorUnderLimitRed = false,
    lineType = LineType.SOLID,
    lineWidth = 2,
    measurand = '%',
    title = 'SOH',
    grayInEOLSpace = false,
    invertedEOLSpace = false,
    disableTooltip = false,
  }: {
    measurements: PredictionMeasurements;
    locale: string;
    limit: number;
    sections: ChartSection[];
    colorUnderLimitRed?: boolean;
    lineType?: LineType;
    lineWidth?: number;
    measurand?: string;
    title?: string;
    grayInEOLSpace?: boolean;
    invertedEOLSpace?: boolean;
    disableTooltip?: boolean;
  }): PlotlyData[] {
    const chartDataArray: PlotlyData[] = [];
    const numberPrecision =
      measurements.precision !== undefined ? `1.${measurements.precision}-${measurements.precision}` : '1.2-2';

    const dash = lineType === LineType.DOTTED ? 'dot' : 'solid';

    const colorInEOL = colorUnderLimitRed
      ? this.COLOR_SCHEME.danger
      : grayInEOLSpace
      ? this.COLOR_SCHEME.gray
      : this.COLOR_SCHEME.main;

    // First we draw all the sections and color them according to the fact if they are under or over limit
    sections.forEach((section) => {
      // We use the text array to supply hover styled template with all the values
      const text = [];
      for (let i = section.start; i <= section.end; i++) {
        // !Important! -> Do not delete any spaces bellow!! Every space in the string bellow has its purpose!!
        if (!measurements.valuesUB && !measurements.valuesLB) {
          text.push(
            '<span> </span><br>' +
              '<span> </span><br>' +
              '<span> </span><br>' +
              `<span>        </span><span style="font-size: ${fontSizeXl}px">` +
              title +
              ': ' +
              formatNumber(measurements.values[i], locale, numberPrecision) +
              `</span> <span style="font-size: ${fontSizeSm}px"> ` +
              measurand +
              '</span><span>        </span><br>' +
              '<span> </span><br>' +
              '<span> </span><br>'
          );
        } else {
          // This calculations are done to figure out semi-dynamically how much spacing we need for the nice value display
          // It is not perfect, but it is a good "close enough" solution considering the low time effort
          const val: string = formatNumber(measurements.values[i], locale, numberPrecision);

          let upperVal, lowerVal, upperValDisplay, lowerValDisplay;

          /*
           * We take what is max -> either the proportional number from the length of the big number value,
           * or if that number is smaller the number of spaces from the predicted "static width"
           * */
          const smallTextSpacesCount = Math.max(val.length * 1.6, 14);

          if (measurements.valuesUB[i]) {
            upperVal = formatNumber(measurements.valuesUB[i], locale, numberPrecision);

            const upperValSpaces = '&nbsp;'.repeat(Math.max(smallTextSpacesCount - upperVal.length / 2, 4));
            upperValDisplay =
              `<span> </span><br>`.repeat(2) +
              `<span style="font-size: ${fontSizeSm}px; color:${blueGray400}">` +
              `${upperValSpaces}upper ${upperVal} ${measurand}${upperValSpaces}</span><br>`;
          } else {
            upperValDisplay = '';
          }

          if (measurements.valuesLB[i]) {
            lowerVal = formatNumber(measurements.valuesLB[i], locale, numberPrecision);

            const lowerValSpaces = '&nbsp;'.repeat(Math.max(smallTextSpacesCount - lowerVal.length / 2, 4));
            lowerValDisplay =
              `<span style="font-size: ${fontSizeSm}px; color:${blueGray400};">` +
              `${lowerValSpaces}lower ${lowerVal} ${measurand}${lowerValSpaces}</span><br>` +
              `<span> </span><br>`.repeat(2);
          } else {
            lowerValDisplay = '';
          }

          // Then we figure out how many spaces we want to have around the actual value -> minimum of 2
          const valSpaces = '&nbsp;'.repeat(Math.max(6 - val.length / 2, 2));

          // We then apply the appropriate number of spaces to tle left and right side of the displayed values
          text.push(
            upperValDisplay +
              `<span> </span><br>`.repeat(3) +
              `<span style="font-size: ${fontSizeXl}px">${valSpaces}${title}: ${val}</span>` +
              `<span style="font-size: ${fontSizeSm}px"> ${measurand}</span><span style="font-size: ${fontSizeXl}px">${valSpaces}</span><br>` +
              `<span> </span><br>`.repeat(2) +
              lowerValDisplay
          );
        }
      }

      chartDataArray.push({
        x: measurements.timestamps.slice(section.start, section.end + 1).map((t) => new Date(t).toISOString()),
        y: measurements.values.slice(section.start, section.end + 1),
        mode: lineType === LineType.SOLID_WITH_MARKERS ? 'lines+markers' : 'lines',
        line: {
          dash: dash,
          color: (invertedEOLSpace ? !section.underLimit : section.underLimit) ? colorInEOL : this.COLOR_SCHEME.main,
          smoothing: 0.1,
          width: lineWidth,
        },
        text: disableTooltip ? undefined : text,
        showlegend: false,
        hovertemplate: disableTooltip ? undefined : '%{text}' + '<extra></extra>',
        hoverinfo: disableTooltip ? 'none' : undefined,
      });
    });

    if (sections.length > 1) {
      // Then we draw the connections between the sections
      for (let i = 1; i < sections.length; i++) {
        const endOfPrevIndex = sections[i - 1].end;

        // We can split the connection in 2 parts and color it according to the fact if ti is above or bellow the limit
        chartDataArray.push({
          x: [measurements.timestamps[endOfPrevIndex], sections[i].passingLimitTimestamp].map((t) => new Date(t).toISOString()),
          y: [measurements.values[endOfPrevIndex], limit],
          showlegend: false,
          hoverinfo: 'skip',
          mode: 'lines',
          line: {
            dash: dash,
            color: (invertedEOLSpace ? !sections[i - 1].underLimit : sections[i - 1].underLimit)
              ? colorInEOL
              : this.COLOR_SCHEME.main,
            smoothing: 0.1,
            width: lineWidth,
          },
        });

        chartDataArray.push({
          x: [sections[i].passingLimitTimestamp, measurements.timestamps[endOfPrevIndex + 1]].map((t) =>
            new Date(t).toISOString()
          ),
          y: [limit, measurements.values[endOfPrevIndex + 1]],
          showlegend: false,
          hoverinfo: 'skip',
          mode: 'lines',
          line: {
            dash: dash,
            color: (invertedEOLSpace ? !sections[i - 1].underLimit : sections[i - 1].underLimit)
              ? this.COLOR_SCHEME.main
              : colorInEOL,
            smoothing: 0.1,
            width: lineWidth,
          },
        });
      }
    }
    return chartDataArray;
  }

  /*
   * This method returns confidence graph group, which consists of an area
   * plot going from bottom to lower bound values which is semi-transparent and
   * an area plot filled with provided color which goes from lower to upper bound values
   * */
  static getConfidenceGraphGroup(
    timestamps: number[],
    valuesUB: number[],
    valuesLB: number[],
    stackGroupName: string,
    fillColor: string
  ): PlotlyData[] {
    const measurementsDiff: number[] = [];
    for (let i = 0; i < valuesUB.length; i++) {
      measurementsDiff.push(valuesUB[i] - valuesLB[i]);
    }

    return [
      {
        x: timestamps.map((t) => new Date(t).toISOString()),
        y: valuesLB,
        stackgroup: stackGroupName,
        fillcolor: colorTransparent,
        line: {
          width: 0,
          smoothing: 0.1,
        },
        showlegend: false,
        hoverinfo: 'skip',
      },
      {
        x: timestamps.map((t) => new Date(t).toISOString()),
        y: measurementsDiff,
        stackgroup: stackGroupName,
        fillcolor: fillColor,
        line: {
          width: 0,
          smoothing: 0.1,
        },
        showlegend: false,
        hoverinfo: 'skip',
      },
    ];
  }

  /*
   * This method returns confidence graph group, which consists of an area
   * plot going from bottom to lower bound values which is semi-transparent and
   * an area plot filled with provided color which goes from lower to upper bound values
   * */
  static getSimpleLineGraph(
    timestamps: number[],
    valuesUB: number[],
    valuesLB: number[],
    stackGroupName: string,
    fillColor: string
  ): PlotlyData[] {
    const measurementsDiff: number[] = [];
    for (let i = 0; i < valuesUB.length; i++) {
      measurementsDiff.push(valuesUB[i] - valuesLB[i]);
    }

    return [
      {
        x: timestamps.map((t) => new Date(t).toISOString()),
        y: valuesLB,
        stackgroup: stackGroupName,
        fillcolor: colorTransparent,
        line: {
          width: 0,
          smoothing: 0.1,
        },
        showlegend: false,
        hoverinfo: 'skip',
      },
      {
        x: timestamps.map((t) => new Date(t).toISOString()),
        y: measurementsDiff,
        stackgroup: stackGroupName,
        fillcolor: fillColor,
        line: {
          width: 0,
          smoothing: 0.1,
        },
        showlegend: false,
        hoverinfo: 'skip',
      },
    ];
  }

  /*
   * This method returns the confidence interval graphs - stacked area plots
   * which provide semi transparent confidence interval to acompany the main plot values
   * To enable us to display this the differnece of the max and min confidence interval
   * is put on top of a transparent area plot of minimal confidence interval values
   * */
  static getConfidenceIntervalGraph(
    measurements: PredictionMeasurements,
    stackGroup: string,
    sections: ChartSection[] = [],
    colorUnderLimitRed = false,
    grayInEOLSpace = false
  ): PlotlyData[] {
    const areaColorInEOL = colorUnderLimitRed
      ? this.COLOR_SCHEME.dangerTransparent
      : grayInEOLSpace
      ? this.COLOR_SCHEME.grayTransparent
      : this.COLOR_SCHEME.mainSemiTransparent;

    const chartDataList: PlotlyData[] = [];
    // We go through the sections since each section has to be colored according
    // to weather it is above or below the limit value
    sections.forEach((section: ChartSection, i) => {
      // drawing the confidence interval of the current section
      chartDataList.push(
        ...PredictionChartPlotData.getConfidenceGraphGroup(
          measurements.timestamps.slice(section.start, section.end + 1),
          measurements.valuesLB.slice(section.start, section.end + 1),
          measurements.valuesUB.slice(section.start, section.end + 1),
          stackGroup + i,
          section.underLimit ? areaColorInEOL : this.COLOR_SCHEME.mainSemiTransparent
        )
      );

      // We add the prediction areas for the gap between the previous section
      // to the current section - first section has nothing to connect with
      if (i > 0) {
        const endOfPrevIndex = section.start - 1;

        // First we need to calculate the values of UB and LB on the timestamp
        // where the section passes the limit
        const valueUB = PredictionChartCalculations.getValueForTimestampInLine(
          measurements.timestamps[endOfPrevIndex],
          measurements.timestamps[section.start],
          measurements.valuesUB[endOfPrevIndex],
          measurements.valuesUB[section.start],
          section.passingLimitTimestamp
        );

        const valueLB = PredictionChartCalculations.getValueForTimestampInLine(
          measurements.timestamps[endOfPrevIndex],
          measurements.timestamps[section.start],
          measurements.valuesLB[endOfPrevIndex],
          measurements.valuesLB[section.start],
          section.passingLimitTimestamp
        );

        // drawing the first part - from end of previous section to the passing limit timestamp
        chartDataList.push(
          ...PredictionChartPlotData.getConfidenceGraphGroup(
            [measurements.timestamps[endOfPrevIndex], section.passingLimitTimestamp],
            [measurements.valuesUB[endOfPrevIndex], valueUB],
            [measurements.valuesLB[endOfPrevIndex], valueLB],
            stackGroup + '-mid-1-' + i,
            section.underLimit ? this.COLOR_SCHEME.mainSemiTransparent : areaColorInEOL
          )
        );

        // drawing the second part - from passing limit timestamp to the start of current section
        chartDataList.push(
          ...PredictionChartPlotData.getConfidenceGraphGroup(
            [section.passingLimitTimestamp, measurements.timestamps[section.start]],
            [valueUB, measurements.valuesUB[section.start]],
            [valueLB, measurements.valuesLB[section.start]],
            stackGroup + '-mid-2-' + i,
            section.underLimit ? areaColorInEOL : this.COLOR_SCHEME.mainSemiTransparent
          )
        );
      }
    });

    return chartDataList;
  }

  private static getMonthWithZeroLead(date: Date, locale: string): string {
    if (!date) {
      return '';
    }
    return formatNumber(date.getMonth() + 1, locale, '2.0-0');
  }

  private static getYear(date: Date): string {
    if (!date) {
      return '';
    }
    return date.getFullYear().toString();
  }

  private static getMonthAndYear(date: Date, locale: string): string {
    if (!date) {
      return '     N/A     ';
    }
    return PredictionChartPlotData.getMonthWithZeroLead(date, locale) + ' / ' + PredictionChartPlotData.getYear(date);
  }

  static getEolHoverText(
    eolValue: number,
    eolTimestamp: number,
    locale: string,
    eolMinTimestamp?: number,
    eolMaxTimestamp?: number,
    measurand = '%',
    title = 'SOH - EOL Prediction'
  ): string {
    const eolDate = new Date(eolTimestamp);
    const eolMinDate = eolMinTimestamp ? new Date(eolMinTimestamp) : null;
    const eolMaxDate = eolMaxTimestamp ? new Date(eolMaxTimestamp) : null;

    /*
     * This part of the code is sensitive to spaces, which is why it is coded in such a way
     * every space has it's purpose as this is a semi-hacky solution to displaying the items
     *
     * Using '&nbsp;' instead of simply using spaces for styling as Safari otherwise doesn't style it properly
     */
    const eolMinHoverText = eolMinDate
      ? `<span style="font-size: 14px; color:${blueGray400};vertical-align: bottom">` +
        '&nbsp;'.repeat(8) +
        PredictionChartPlotData.getMonthAndYear(eolMinDate, locale) +
        '&nbsp;'.repeat(7) +
        '</span>'
      : '&nbsp;'.repeat(70); // to align in case of missing text

    const eolMaxHoverText = eolMaxDate
      ? `<span style="font-size: 14px; color:${blueGray400};vertical-align: bottom">` +
        '&nbsp;'.repeat(7) +
        PredictionChartPlotData.getMonthAndYear(eolMaxDate, locale) +
        '</span>' +
        '&nbsp;'.repeat(16)
      : '&nbsp;'.repeat(70); // to align in case of missing text

    return (
      '<span>&nbsp;</span><br>' +
      '<span>&nbsp;</span><br>' +
      `<span>${'&nbsp;'.repeat(20)}</span>` +
      '<span style="font-size: 20px">' +
      `${eolValue ? formatNumber(eolValue, locale, '2.0-0') : ''}${measurand} ${title}${'&nbsp;'.repeat(eolValue ? 6 : 10)}` +
      `</span><br>` +
      `<span>&nbsp;</span><br>` +
      `<span>&nbsp;</span><br>` +
      eolMinHoverText +
      `<span style="font-size: 14px;vertical-align: bottom">${PredictionChartPlotData.getMonthWithZeroLead(eolDate, locale)}` +
      '&nbsp;/&nbsp;' +
      '</span>' +
      `<span style="font-size: 20px;vertical-align: bottom">${PredictionChartPlotData.getYear(eolDate)}</span>` +
      eolMaxHoverText
    );
  }

  static getDotGraph(value: number, timestamp: number, colorRed = false, size = 10, hoverInfoText?: string): PlotlyData {
    return {
      x: [new Date(timestamp).toISOString()],
      y: [value],
      showlegend: false,
      hoverinfo: !hoverInfoText ? 'skip' : 'text',
      mode: 'markers',
      marker: {
        color: colorRed ? this.COLOR_SCHEME.danger : this.COLOR_SCHEME.main,
        size: size,
        symbol: ['circle'],
      },
      text: [hoverInfoText],
      hovertemplate: hoverInfoText ? '%{text}' + '<extra></extra>' : undefined,
    };
  }

  static getShapeGraph(
    value: number,
    timestamp: number,
    hoverInfoText?: string,
    useDangerColor = false,
    shape = 'circle',
    size = 12
  ): PlotlyData[] {
    return [
      {
        x: [new Date(timestamp).toISOString()],
        y: [value],
        showlegend: false,
        hoverinfo: !hoverInfoText ? 'skip' : 'text',
        mode: 'markers',
        marker: {
          color: useDangerColor ? this.COLOR_SCHEME.danger : this.COLOR_SCHEME.main,
          size: size,
          symbol: [shape],
        },
      },
      {
        x: [new Date(timestamp).toISOString()],
        y: [value],
        showlegend: false,
        hoverinfo: !hoverInfoText ? 'skip' : 'text',
        mode: 'markers',
        marker: {
          color: colorLight,
          size: size - 4,
          symbol: [shape],
        },
        text: [hoverInfoText],
        hovertemplate: hoverInfoText ? '%{text}' + '<extra></extra>' : undefined,
      },
    ];
  }

  /*
   * This function provides chart data area graph with a line on the top
   * from zero to the provided value
   * */
  static levelGraph(
    value: number,
    timerange: number[],
    fillColor = colorTransparent,
    lineColor = blueGray500,
    lineWidth = 1.5
  ): PlotlyData {
    return {
      y: [value, value],
      x: [new Date(timerange[0]).toISOString(), new Date(timerange[1]).toISOString()],
      fillcolor: fillColor,
      line: {
        width: lineWidth,
        smoothing: 0.1,
        color: lineColor,
      },
      stackgroup: 'level' + value,
      showlegend: false,
      hoverinfo: 'skip',
    };
  }

  /*
   * This function provides chart data area graph with a line
   * from maximum graph value to the provided value
   * */
  static invertedLevelGraph(
    value: number,
    maxValue: number,
    timerange: number[],
    fillColor = colorTransparent,
    lineColor = blueGray500,
    lineWidth = 1.5
  ): PlotlyData[] {
    return [
      {
        y: [value, value],
        x: [new Date(timerange[0]).toISOString(), new Date(timerange[1]).toISOString()],
        fillcolor: colorTransparent,
        line: {
          width: lineWidth,
          smoothing: 0.1,
          color: lineColor,
        },
        stackgroup: 'inverted-level' + value,
        showlegend: false,
        hoverinfo: 'skip',
      },
      {
        y: [maxValue, maxValue],
        x: [new Date(timerange[0]).toISOString(), new Date(timerange[1]).toISOString()],
        fillcolor: fillColor,
        stackgroup: 'inverted-level' + value,
        showlegend: false,
        hoverinfo: 'skip',
      },
    ];
  }

  /*
   * This function provides an indication dashed or solid line for the given timestamp and value range
   * */
  static timestampIndicator(
    minMaxRange: number[],
    timeStamp: number,
    dot = true,
    color = blueGray500,
    lineWidth = 1.5
  ): PlotlyData {
    const formattedTimestamp = new Date(timeStamp).toISOString();
    return {
      y: [minMaxRange[0], minMaxRange[1]],
      x: [formattedTimestamp, formattedTimestamp],
      mode: 'lines',
      line: {
        dash: dot ? 'dot' : 'solid',
        width: lineWidth,
        smoothing: 0.1,
        color: color,
      },
      showlegend: false,
      hoverinfo: 'skip',
    };
  }

  /*
   * This function provides an indicator blue or red graph line between provided points
   * */
  static getHighlightLine(x1: number, x2: number, y0: number, y1: number, isUnderLimit = false, dashed = false): PlotlyData {
    return {
      y: [y0, y1],
      x: [new Date(x1).toISOString(), new Date(x2).toISOString()],
      mode: 'lines',
      line: {
        width: 2,
        color: isUnderLimit ? this.COLOR_SCHEME.danger : this.COLOR_SCHEME.main,
        dash: dashed ? 'dot' : 'solid',
      },
      showlegend: false,
      hoverinfo: 'skip',
    };
  }

  static getMockLine(timestamps: number[], values: number[], color: string = colorTransparent): PlotlyData {
    return {
      x: timestamps.map((t) => new Date(t).toISOString()),
      y: values,
      showlegend: false,
      hoverinfo: 'skip',
      mode: 'lines',
      line: {
        color: color,
      },
    };
  }
}
