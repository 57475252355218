export interface BaseEnergyAnalyticResponseInterface {
  customerBk: string;
  systemBk: string;
}

export interface BaseEnergyAnalyticsParamsInterface {
  customerBk?: string;
  systemBk?: string;
}

export interface SafetyRecommendationDataInterface extends BaseEnergyAnalyticResponseInterface {
  systemState: ActionStateEnum;
  numberOfStableStrings: number;
  requiredActions: ComponentStateInterface[];
  recommendedActions: ComponentStateInterface[];
}

export interface ComponentStateInterface {
  name: string;
  componentBk: string;
  componentState: ActionStateEnum;
  kpiStates: KpiStateInterface[];
}

export interface KpiStateInterface {
  kpi: SafetyKPIEnum;
  state: ActionStateEnum;
  issueTypes: KpiIssueTypeEnum[];
}

export enum SafetyKPIEnum {
  TEMPERATURE = 'TEMPERATURE',
  RESISTANCE = 'RESISTANCE',
}

export enum ActionStateEnum {
  REQUIRED = 'ACTION_REQUIRED',
  RECOMMENDED = 'ACTION_RECOMMENDED',
  STABLE = 'STABLE',
}

export enum KpiIssueTypeEnum {
  ANOMALY = 'ANOMALY',
  TREND = 'TREND',
}

export interface SafetyRecommendationComponentDetailsParamsInterface extends BaseEnergyAnalyticsParamsInterface {
  componentBk: string;
}

export interface KpiStateDetailsInterface {
  kpi: SafetyKPIEnum;
  state: ActionStateEnum;
  issueType: KpiIssueTypeEnum;
  timestamps: Date[];
  outlierComponentValues: number[];
  stableComponentsValues: number[];
}

export interface SafetyRecommendationComponentDetailsInterface extends BaseEnergyAnalyticResponseInterface {
  componentBk: string;
  kpiStates: KpiStateDetailsInterface[];
}

export interface SystemIngestionStatistics {
  lastSeen: Date | null;
}
