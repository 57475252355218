import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchInputFieldComponent } from './search-input-field.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { FormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { NameFilterPipe } from './nameFilter.pipe';

@NgModule({
  declarations: [SearchInputFieldComponent, NameFilterPipe],
  exports: [SearchInputFieldComponent, NameFilterPipe],
  imports: [CommonModule, MatFormFieldModule, MatIconModule, MatInputModule, FormsModule, MatButtonModule],
})
export class SearchInputFieldModule {}
