import { ExtendedEnvironment } from './environment-variables/environment.model';
import { LoginConfig } from '@twaice-fe/shared/models';
/*
 * This class is here to provide a library level environment variable
 * to decuple the environment from the application and therefore avoid
 * the circular dependency and enable shared services and components
 * to make use of the environment variable.
 *
 * To make it useful set the environment variable on the application level
 * preferably in the constructor of app module
 * */

export class CurrentEnvironment {
  private static LOGIN_CONFIG;

  static setLoginConfig(loginConfig: LoginConfig) {
    this.LOGIN_CONFIG = loginConfig;
  }

  static getLoginConfig(): LoginConfig {
    return this.LOGIN_CONFIG;
  }
}
