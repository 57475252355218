import { Action, createReducer, on } from '@ngrx/store';
import { AuthState } from '@twaice-fe/shared/models';
import * as AuthActions from '../actions/auth.actions';

export const AUTH_FEATURE_KEY = 'auth';

export const initialState: AuthState = {
  user: undefined,
  errors: {
    setInitialPasswordError: undefined,
    requestNewPasswordError: undefined,
    resetPasswordError: undefined,
    changePasswordError: undefined,
    loginError: undefined,
    mfaError: undefined,
  },
  mfa: {
    setupCode: undefined,
  },
};

const authReducer = createReducer(
  initialState,
  on(AuthActions.loginSuccess, (state, { user }) => ({
    ...state,
    user,
    mfa: {},
  })),
  on(AuthActions.loginError, (state, { error }) => ({
    ...state,
    user: null,
    errors: {
      loginError: error,
    },
  })),
  on(AuthActions.logout, (state) => ({
    ...state,
    user: null,
  })),
  on(AuthActions.userChanged, (state, { user }) => ({
    ...state,
    user,
  })),
  on(AuthActions.setupMfaSuccess, (state, { code }) => ({
    ...state,
    mfa: {
      setupCode: code,
    },
  })),
  /**
   * Succesful modifications to the user
   */
  on(
    AuthActions.setInitialPasswordSuccess,
    AuthActions.requestNewPasswordSuccess,
    AuthActions.resetPasswordSuccess,
    AuthActions.changePasswordSuccess,
    (state) => ({
      ...state,
      errors: {},
    })
  ),
  /**
   * Error handling
   */
  on(AuthActions.setInitialPasswordError, (state, { error }) => ({
    ...state,
    errors: {
      setInitialPasswordError: error,
    },
  })),
  on(AuthActions.requestNewPasswordError, (state, { error }) => ({
    ...state,
    errors: {
      requestNewPasswordError: error,
    },
  })),
  on(AuthActions.resetPasswordError, (state, { error }) => ({
    ...state,
    errors: {
      resetPasswordError: error,
    },
  })),
  on(AuthActions.changePasswordError, (state, { error }) => ({
    ...state,
    errors: {
      changePasswordError: error,
    },
  })),
  on(AuthActions.mfaCodeError, (state, { error }) => ({
    ...state,
    errors: {
      mfaError: error,
    },
  }))
);
export function reducer(state: AuthState | undefined, action: Action) {
  return authReducer(state, action);
}
