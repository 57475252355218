import { Injectable } from '@angular/core';
import { bisect, pointer, Selection } from 'd3';
import { ChartLayoutService } from '../chart-helpers/chart-layout.service';
import { ChartEventsInterface } from './chart-events.interface';
import { GlobalHoverEvent, GlobalHoverOptions } from './models/chart-view.models';

@Injectable()
export class GlobalEventService implements ChartEventsInterface {
  attachEvent({
    options,
    chartSVGContainer,
    chartLayoutService,
    data,
    selectedElement,
  }: {
    options: GlobalHoverOptions;
    chartSVGContainer: Selection<SVGGElement, unknown, null, undefined>;
    chartLayoutService: ChartLayoutService;
    data: { x: number[]; y: number[] };
    selectedElement: string;
  }) {
    const xScale = chartLayoutService.getXScale();
    const yScale = chartLayoutService.getYScale();

    const calculateEventFields = (event): GlobalHoverEvent => {
      let [x, y] = pointer(event);

      if (options.attach) {
        const dimensions = chartLayoutService.getChartDimensions();
        x += dimensions.marginLeft / 2;
        y += dimensions.marginTop / 2;
      }

      let scaledX = xScale.invert(x);

      if (scaledX instanceof Date) {
        scaledX = scaledX.getTime();
      }

      let xIndex = bisect(data.x, scaledX);

      if (xIndex > data.x.length - 1) {
        xIndex = data.x.length - 1;
      }

      const positionY = options.attach ? yScale(data.y[xIndex]) : y;

      return {
        x: { x: x, data: data.x[xIndex], index: xIndex },
        y: { y: positionY, data: data.y[xIndex], index: xIndex },
      };
    };

    if (options.onHover) {
      chartSVGContainer.select(selectedElement).classed('enable-hover', true);

      chartSVGContainer
        .select(selectedElement)
        .on('mousemove', (event) => options.onHover({ ...calculateEventFields(event), color: event.currentTarget.style.stroke }));

      chartSVGContainer.select(selectedElement).on('mouseout', () => options.onHover(null));
    }

    if (options.onClick) {
      chartSVGContainer.select(selectedElement).on('click', (event) => {
        options.onClick(calculateEventFields(event));
      });
    }
  }
}
