<div nz-dropdown [nzDropdownMenu]="menu">
  <mat-icon>more_vert</mat-icon>
</div>
<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item (click)="onOptionClick(option?.id)" *ngFor="let option of data.optionsSelector; trackBy: trackByIndex">
      {{ option?.label }}
    </li>
  </ul>
</nz-dropdown-menu>
