import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  AreaViewService,
  BarChartDataProcessing,
  ChartAxisService,
  ChartLayoutService,
  InitializeChartUtility,
} from '@twaice-fe/frontend/shared/utilities';
import { AlertPanelModule } from '../alert-panel/alert-panel.module';
import { LoadingTipsComponent } from '../loading-tips/loading-tips.component';
import { ProgressSpinnerModule } from '../progress-spinner/progress-spinner.module';
import { HealthBarChartComponent } from './health-bar-chart.component';

@NgModule({
  declarations: [HealthBarChartComponent],
  imports: [CommonModule, ProgressSpinnerModule, AlertPanelModule, LoadingTipsComponent],
  exports: [HealthBarChartComponent],
  providers: [ChartLayoutService, InitializeChartUtility, ChartAxisService, AreaViewService, BarChartDataProcessing],
})
export class HealthBarChartModule {}
