import { CommonModule, DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { T } from '@transifex/angular';
import {
  AnnotationViewService,
  AreaViewService,
  ChartLayoutService,
  GlobalHoverEvent,
  LineViewService,
  blueGray400,
  ChartLayout,
} from '@twaice-fe/frontend/shared/utilities';
import { IncidentLowerThresholdEnum, IncidentUpperThresholdEnum, SingleIncidentInterface } from '@twaice-fe/shared/models';
import { Selection } from 'd3';
import { Subject, fromEvent, takeUntil } from 'rxjs';
import { addLineChartTooltip, initTooltipContent, positionTooltip } from '../health-line-chart/health-line-chart-tooltip';
import { TooltipContentInterface } from '../health-line-chart/models/health-line-chart.models';
import { dimensions, xAxisConfig, yAxisConfig } from './incident-chart.config';

@Component({
  selector: 'twaice-fe-incident-chart',
  templateUrl: './incident-chart.component.html',
  styleUrls: ['./incident-chart.component.scss'],
  standalone: true,
  imports: [CommonModule],
  providers: [ChartLayoutService, LineViewService, AreaViewService],
})
export class IncidentChartComponent implements OnChanges, OnInit, OnDestroy {
  @Input() extendedLegend: boolean;
  @Input() layout?: ChartLayout;
  @Input() incident: SingleIncidentInterface;
  @Input() hideThresholdAreas: boolean;
  @Input() hasTooltip?: boolean;
  @ViewChild('lineChart', { static: true }) lineChart: ElementRef<HTMLDivElement>;
  @ViewChild('tooltip', { static: false }) tooltipRef: ElementRef<HTMLDivElement>;

  @T('Critical', { _key: 'shared.components.incident-chart.critical-boundary-label' })
  private criticalBoundaryLabel: string;

  @T('High', { _key: 'shared.components.incident-chart.high-boundary-label' })
  private highBoundaryLabel: string;

  @T('Medium', { _key: 'shared.components.incident-chart.medium-boundary-label' })
  private mediumBoundaryLabel: string;

  @T('Low', { _key: 'shared.components.incident-chart.low-boundary-label' })
  private lowBoundaryLabel: string;

  incidentUnit: string;
  tooltipContent: TooltipContentInterface;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly NORMAL_READING_COLOR = blueGray400;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly INCIDENT_READING_COLOR = '#7C5CC7';

  private chartSVGContainer: Selection<SVGGElement, unknown, null, undefined>;
  private destroy$ = new Subject<void>();

  constructor(
    private chartLayoutService: ChartLayoutService,
    private lineViewService: LineViewService,
    private areaViewService: AreaViewService,
    private annotationViewService: AnnotationViewService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.setupResizeListener();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.incident) {
      this.drawChart();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getSensorDataMetrics(data) {
    const sensorData = data?.sensorData;
    if (!sensorData?.length) {
      return null;
    }

    const values = sensorData.map((d) => d.value);
    const timestamps = sensorData.map((d) => new Date(Number(d.timestamp) * 1000).getTime());
    this.incidentUnit = sensorData.find((d) => d.unit)?.unit;

    return { values, timestamps, incidentUnit: this.incidentUnit };
  }

  configureAxes(timestamps, values, incidentUnit) {
    const xAxisInfo = this.chartLayoutService.setXAxisInfo({
      xAxisInfo: xAxisConfig,
      layout: this.layout ? this.layout : {},
      x: [[...timestamps]],
    });

    const yAxisInfo = this.chartLayoutService.setYAxisInfo({
      yAxisInfo: yAxisConfig,
      layout: this.layout ? { ...this.layout, yUnit: incidentUnit } : { yUnit: incidentUnit },
      y: [values],
    });

    return { xAxisInfo, yAxisInfo };
  }

  private drawChart() {
    this.clearChart();
    const metrics = this.getSensorDataMetrics(this.incident);
    if (!metrics) {
      return; // No sensor data to draw
    }

    const { timestamps, values, incidentUnit } = metrics;
    const { lowerThresholdAreas, upperThresholdAreas } = this.getThresholdAreas(values);
    const yMin = lowerThresholdAreas.length ? Math.min(...lowerThresholdAreas.map((area) => area.yMin)) : Math.min(...values);
    const yMax = upperThresholdAreas.length ? Math.max(...upperThresholdAreas.map((area) => area.yMax)) : Math.max(...values);
    const { xAxisInfo, yAxisInfo } = this.configureAxes(timestamps, [...values, yMin, yMax], incidentUnit);

    this.chartSVGContainer = this.chartLayoutService.draw({
      chartContainer: this.lineChart.nativeElement,
      chartDimensions: dimensions,
      xAxisInfo,
      yAxisInfo,
      layout: this.layout ? this.layout : {},
    });

    const [minTimestamp, maxTimestamp] = [Math.min(...timestamps), Math.max(...timestamps)];

    [...lowerThresholdAreas, ...upperThresholdAreas].forEach((area) => {
      this.drawArea(minTimestamp, maxTimestamp, area.yMin, area.yMax, area.background);
      if (area.yMin !== area.yMax) {
        this.drawLabel(minTimestamp, area.yMin, area.text, 'rgba(0, 0, 0, 0.45)');
      }
    });

    this.drawSensorReadingLine(timestamps, values);
  }

  private drawSensorReadingLine(timestamps: number[], values: number[]): void {
    // Determine the indices for the start and end of the incident
    const incidentStartIndex = timestamps.findIndex((timestamp) => timestamp > new Date(this.incident.startTime).getTime());
    const incidentEndIndex = this.incident.endTime
      ? timestamps.findIndex((timestamp) => timestamp > new Date(this.incident.endTime).getTime())
      : timestamps.length; // Use the length if there's no end time, drawing to the end

    // Draw the normal reading before the incident, if applicable
    if (incidentStartIndex > 0) {
      this.drawSegment(
        'line-before-incident',
        timestamps.slice(0, incidentStartIndex),
        values.slice(0, incidentStartIndex),
        this.NORMAL_READING_COLOR,
        '2'
      );
    }

    // Draw the incident period
    this.drawSegment(
      'line-during-incident',
      timestamps.slice(incidentStartIndex, incidentEndIndex),
      values.slice(incidentStartIndex, incidentEndIndex),
      this.INCIDENT_READING_COLOR,
      '3'
    );

    if (incidentEndIndex < timestamps.length) {
      this.drawSegment(
        'line-after-incident',
        timestamps.slice(incidentEndIndex),
        values.slice(incidentEndIndex),
        this.NORMAL_READING_COLOR,
        '2'
      );
    }
  }

  private drawSegment(id: string, selectedTimestamps: number[], selectedValues: number[], color: string, strokeWidth: string) {
    this.lineViewService.draw({
      chartSVGContainer: this.chartSVGContainer,
      chartLayoutService: this.chartLayoutService,
      data: { x: [selectedTimestamps], y: [selectedValues] },
      options: {
        id: id,
        color: [color],
        strokeWidth: strokeWidth,
        style: color === this.NORMAL_READING_COLOR ? 'dashed' : 'solid',
        events: {
          onHover: this.hasTooltip ? (event) => this.onHover({ event }) : null,
        },
      },
    });
  }

  private getThresholdAreas(values: number[]) {
    if (!this.incident?.details || this.hideThresholdAreas) return;
    const { details } = this.incident;
    const getThresholdOrMax = (threshold: number): number => (threshold >= Math.max(...values) ? threshold : Math.max(...values));
    const getThresholdOrMin = (threshold: number): number => (threshold <= Math.min(...values) ? threshold : Math.min(...values));

    const isLowerThreshold = this.incident.message.includes('lower');
    const isUpperThreshold = this.incident.message.includes('upper');

    const upperThresholdAreas = isUpperThreshold
      ? Object.keys(IncidentUpperThresholdEnum)
          .filter((key) => details[IncidentUpperThresholdEnum[key]])
          .map((key) => ({
            ...this.getUpperSeverityThresholdConfig(IncidentUpperThresholdEnum[key]),
            yMin: details[IncidentUpperThresholdEnum[key]],
            yMax:
              details[IncidentUpperThresholdEnum[key]] ===
                details[this.getUpperSeverityThreshold(IncidentUpperThresholdEnum[key])] ||
              !details[this.getUpperSeverityThreshold(IncidentUpperThresholdEnum[key])]
                ? getThresholdOrMax(details[IncidentUpperThresholdEnum[key]])
                : details[this.getUpperSeverityThreshold(IncidentUpperThresholdEnum[key])],
          }))
      : [];

    const lowerThresholdAreas = isLowerThreshold
      ? Object.keys(IncidentLowerThresholdEnum)
          .filter((key) => details[IncidentLowerThresholdEnum[key]])
          .map((key) => ({
            ...this.getLowerSeverityThresholdConfig(IncidentLowerThresholdEnum[key]),
            yMin:
              details[IncidentLowerThresholdEnum[key]] ===
                details[this.getLowerSeverityThreshold(IncidentLowerThresholdEnum[key])] ||
              !details[this.getLowerSeverityThreshold(IncidentLowerThresholdEnum[key])]
                ? getThresholdOrMin(details[IncidentLowerThresholdEnum[key]])
                : details[this.getLowerSeverityThreshold(IncidentLowerThresholdEnum[key])],
            yMax: details[IncidentLowerThresholdEnum[key]],
          }))
      : [];

    return { lowerThresholdAreas, upperThresholdAreas };
  }

  private getUpperSeverityThreshold(threshold: IncidentUpperThresholdEnum) {
    const severityThresholds: {
      [key in IncidentUpperThresholdEnum]?: IncidentUpperThresholdEnum;
    } = {
      [IncidentUpperThresholdEnum.LOW_UPPER_THRESHOLD]: IncidentUpperThresholdEnum.MEDIUM_UPPER_THRESHOLD,
      [IncidentUpperThresholdEnum.MEDIUM_UPPER_THRESHOLD]: IncidentUpperThresholdEnum.HIGH_UPPER_THRESHOLD,
      [IncidentUpperThresholdEnum.HIGH_UPPER_THRESHOLD]: IncidentUpperThresholdEnum.CRITICAL_UPPER_THRESHOLD,
      [IncidentUpperThresholdEnum.CRITICAL_UPPER_THRESHOLD]: IncidentUpperThresholdEnum.CRITICAL_UPPER_THRESHOLD,
    };

    return severityThresholds[threshold];
  }

  private getLowerSeverityThreshold(threshold: IncidentLowerThresholdEnum) {
    const severityThresholds: {
      [key in IncidentLowerThresholdEnum]?: IncidentLowerThresholdEnum;
    } = {
      [IncidentLowerThresholdEnum.LOW_LOWER_THRESHOLD]: IncidentLowerThresholdEnum.MEDIUM_LOWER_THRESHOLD,
      [IncidentLowerThresholdEnum.MEDIUM_LOWER_THRESHOLD]: IncidentLowerThresholdEnum.HIGH_LOWER_THRESHOLD,
      [IncidentLowerThresholdEnum.HIGH_LOWER_THRESHOLD]: IncidentLowerThresholdEnum.CRITICAL_LOWER_THRESHOLD,
      [IncidentLowerThresholdEnum.CRITICAL_LOWER_THRESHOLD]: IncidentLowerThresholdEnum.CRITICAL_LOWER_THRESHOLD,
    };

    return severityThresholds[threshold];
  }

  private getUpperSeverityThresholdConfig(threshold: IncidentUpperThresholdEnum) {
    const severityThresholds: {
      [key in IncidentUpperThresholdEnum]: {
        background: string;
        text: string;
      };
    } = {
      [IncidentUpperThresholdEnum.LOW_UPPER_THRESHOLD]: { background: '#F5F5F5', text: 'Low' },
      [IncidentUpperThresholdEnum.MEDIUM_UPPER_THRESHOLD]: { background: '#F8EFD4', text: 'Medium' },
      [IncidentUpperThresholdEnum.HIGH_UPPER_THRESHOLD]: { background: '#FFD9BF', text: 'High' },
      [IncidentUpperThresholdEnum.CRITICAL_UPPER_THRESHOLD]: { background: '#E5BFBF', text: 'Critical' },
    };

    return severityThresholds[threshold];
  }

  private getLowerSeverityThresholdConfig(threshold: IncidentLowerThresholdEnum) {
    const severityThresholds: {
      [key in IncidentLowerThresholdEnum]: {
        background: string;
        text: string;
      };
    } = {
      [IncidentLowerThresholdEnum.LOW_LOWER_THRESHOLD]: { background: '#F5F5F5', text: 'Low' },
      [IncidentLowerThresholdEnum.MEDIUM_LOWER_THRESHOLD]: { background: '#F8EFD4', text: 'Medium' },
      [IncidentLowerThresholdEnum.HIGH_LOWER_THRESHOLD]: { background: '#FFD9BF', text: 'High' },
      [IncidentLowerThresholdEnum.CRITICAL_LOWER_THRESHOLD]: { background: '#E5BFBF', text: 'Critical' },
    };

    return severityThresholds[threshold];
  }

  private drawArea(minTimestamp: number, maxTimestamp: number, yMin: number, yMax: number, color: string) {
    this.areaViewService.draw({
      chartSVGContainer: this.chartSVGContainer,
      chartLayoutService: this.chartLayoutService,
      data: [{ x: [minTimestamp, maxTimestamp], y0: [yMax, yMax], y1: [yMin, yMin] }],
      options: { color: [color] },
    });
  }

  private drawLabel(minTimestamp: number, y: number, label: string, textColor: string) {
    this.annotationViewService.draw({
      chartSVGContainer: this.chartSVGContainer,
      chartLayoutService: this.chartLayoutService,
      data: { x: [minTimestamp], y: [y] },
      options: {
        fill: 'transparent',
        text: { text: label, color: textColor, xOffset: 4, yOffset: -4, size: 16 },
      },
    });
  }

  private onHover({ event, showTooltip = true }: { event: GlobalHoverEvent; showTooltip?: boolean }) {
    if (!event) {
      this.tooltipRef.nativeElement.style.display = 'none';

      this.chartSVGContainer?.select('#lines #line-tooltip').remove();
      return;
    }

    const { x, y, color } = event;

    addLineChartTooltip({ x: x.x, chartContainer: this.lineChart.nativeElement });

    if (showTooltip) {
      this.tooltipContent = initTooltipContent({
        data: { x: this.datePipe.transform(x.data, 'MMM d, yyyy, HH:mm'), y: y.data as number, index: x.index },
        layoutInfo: {
          color: color,
          title: this.incident?.sensorName || '',
          unit: this.incidentUnit || '',
          valuePrecision: this.layout.yTickValuesPrecision ? this.layout.yTickValuesPrecision : 1,
        },
      });

      this.tooltipRef.nativeElement = positionTooltip({
        x: x.x,
        y: y.y,
        tooltip: this.tooltipRef.nativeElement,
        chartContainer: this.lineChart.nativeElement,
      });
    }
  }

  private setupResizeListener(): void {
    fromEvent(window, 'resize')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.redrawChartIfNeeded());
  }

  private redrawChartIfNeeded(): void {
    if (this.lineChart.nativeElement?.clientWidth && this.lineChart.nativeElement?.clientHeight) {
      this.drawChart();
    }
  }

  private clearChart(): void {
    if (this.chartSVGContainer) {
      this.chartLayoutService.clear(this.lineChart.nativeElement);
    }
  }
}
