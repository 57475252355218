import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, map, of, switchMap, takeUntil, withLatestFrom } from 'rxjs';
import * as EnergyPredictionActions from '../actions/energy-prediction.actions';
import * as EnergyPredictionSelectors from '../selectors/energy-prediction.selectors';

import { Store } from '@ngrx/store';
import { EnergyPredictionService } from 'libs/frontend/features/energy-prediction/src/lib/energy-prediction.service';

@Injectable()
export class EnergyPredictionEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnergyPredictionActions.fetchEnergyPredictionData),
      withLatestFrom(this.store.select(EnergyPredictionSelectors.getEnergyPredictionState)),
      switchMap(([action, state]) =>
        this.energyPredictionService.getEnergyPredictionData(action.systemID).pipe(
          map(({ data }) => EnergyPredictionActions.loadEnergyPredictionDataSuccess({ data })),
          takeUntil(this.actions$.pipe(ofType(EnergyPredictionActions.cancelPredictionRequest))),
          catchError((error) => {
            console.error('[Error/Fetch energy health prediction]', error);
            return of(EnergyPredictionActions.loadEnergyPredictionFailure({ error }));
          })
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private energyPredictionService: EnergyPredictionService,
    protected store: Store
  ) {}
}
