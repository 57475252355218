import { Params, Router } from '@angular/router';

// allows you to access all params from the Angular router outside of a routing context (e.g. <router-outlet>)
// typically to be used when wanting to access route parameters from within a service
export const extractAllParamsFromRouter = (router: Router): Params => {
  let params = {};
  let route = router.routerState.snapshot.root;
  do {
    params = { ...params, ...route.params };
    route = route.firstChild;
  } while (route);

  return params;
};
