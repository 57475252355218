import { createAction, props } from '@ngrx/store';
import { OverviewSystem, PaginatedResponse } from '@twaice-fe/shared/models';
import { ListConfigurationInterface } from '../types/list-configuration.interface';

export const fetchSystems = createAction(
  '[Systems] Fetch systems',
  props<{
    includeMetadata?: boolean;
    includeHealthStateKpis?: boolean;
    onlySystems?: boolean;
    includeSafetyScore?: boolean;
    includeSystemStatistics?: boolean;
  }>()
);

export const fetchSystemsHealthKpiData = createAction(
  '[Systems/API] fetch system health kpi data',
  props<{ includeHealthStateKpis?: boolean; includeMetadata?: boolean }>()
);

export const fetchSystemsEfcData = createAction(
  '[Systems/API] fetch system EFC data',
  props<{ systemIds: { id: string; calculationLevel: number }[] }>()
);

export const loadSystemsSuccess = createAction(
  '[Systems/API] Load Systems Success',
  props<{ data: PaginatedResponse<OverviewSystem> }>()
);
export const loadSystemsEfcDataSuccess = createAction(
  '[Systems/API] Load Systems EFC data Success',
  props<{ data: PaginatedResponse<OverviewSystem> }>()
);

// set loading to false when all data is retrieved from cache
export const systemLoadingSuccess = createAction('[Systems/API] Loading Systems data finished');

export const loadSystemsHealthKpiDataSuccess = createAction(
  '[Systems/API] Load Systems health kpi data Success',
  props<{ data: PaginatedResponse<OverviewSystem> }>()
);

export const selectSystem = createAction('[Systems] Select system by id', props<{ systemId: string }>());

export const updateSystemListConfiguration = createAction(
  '[Systems] Update table configuration',
  props<{ config: ListConfigurationInterface; isLoading?: boolean }>()
);

export const loadSystemsFailure = createAction('[Systems/API] Load Systems Failure', props<{ error: any }>());

export const systemRouteRedirectSuccess = createAction('[Systems] Route Redirect Success');

export const cancelSystemRequest = createAction('[Systems/API] Cancel systems request');

export const systemsColumnPicker = createAction(
  '[Datatable/Config] Systems overview column picker',
  props<{ columns: string[] }>()
);
