export const CUSTOMER_ID_AUDI = '7f249010-bc4c-4dbb-abe3-868118c77d61';
export const CUSTOMER_ID_BMZ = 'cde95cdd-ee9f-40a1-82ad-797463cc220d';
export const CUSTOMER_ID_HERO_MOTOCORP = 'e49a2592-6c5e-488a-bc06-4d3a67531bb9';
export const CUSTOMER_ID_MERCEDES_BENZ = 'a1efc0aa-4369-44f5-b65d-95d416b566f3';
export const CUSTOMER_ID_MIELE = '2a075e0a-90c2-4be4-bca7-c84ddafc94fa';
export const CUSTOMER_ID_PEPPER_MOTION = 'e1934e33-4313-4b49-9347-2c0b89f703e4';
export const CUSTOMER_ID_JUNIZ = 'ababdbe3-2ba8-4b5e-8634-4ec378e29298';
export const CUSTOMER_ID_TWAICE = '897e75b4-ada7-4467-b055-7fd7e29686fd';
export const CUSTOMER_ID_TWAICE_DEMO = 'cdda2d12-4c6b-43a0-87d3-c3ba5b8fd91b';
export const CUSTOMER_ID_VERBUND = '879c0a09-170a-4905-b2bf-02ba087b8021';
export const CUSTOMER_ID_VIRICITI_PROD = '4659a1df-7a27-49ce-9bcb-59ee5e98669f';
export const CUSTOMER_ID_VIRICITI_STG = '6fc1ab87-8390-4d40-9bb5-098e1c668ca8';
export const CUSTOMER_ID_VISPIRON = '1996260a-90c2-48ff-96c6-1427745db54a';
