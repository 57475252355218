import { Injectable } from '@angular/core';
import { select, Selection } from 'd3';
import { BehaviorSubject, filter, Observable } from 'rxjs';
import { HeatmapCellData } from '../chart-views/models/chart-view.models';
import { ChartEventsInterface } from './chart-events.interface';
import { HeatmapHoverEvent } from './models/chart-view.models';

@Injectable()
export class HeatmapEventService implements ChartEventsInterface {
  private hover$: BehaviorSubject<HeatmapHoverEvent> = new BehaviorSubject<HeatmapHoverEvent>(null);

  getHoverEvents(): Observable<HeatmapHoverEvent> {
    return this.hover$.asObservable().pipe(filter((v) => v !== null));
  }

  attachEvent({ chartSVGContainer }: { chartSVGContainer: Selection<SVGGElement, unknown, null, undefined> }): void {
    chartSVGContainer.selectAll('rect').on('mouseover', (event) => this.emitHoverEvent('mouseover', select(event.target)));
    chartSVGContainer.selectAll('rect').on('mouseout', () => this.emitHoverEvent('mouseout'));
  }

  private emitHoverEvent(
    event: 'mouseover' | 'mouseout',
    cell?: Selection<SVGRectElement, HeatmapCellData, SVGElement, unknown>
  ): void {
    this.hover$.next({
      cell,
      data: cell ? cell.data()[0] : undefined,
      type: event,
    });
  }
}
