import { Component, Input } from '@angular/core';
import { CellConfigInterface } from '../../models/cell-config.interface';
import { CellTypeEnum } from '../../models/cell-type.enum';

@Component({
  selector: 'twaice-fe-datatable-cell-status',
  templateUrl: 'status-cell.component.html',
})
export class StatusCellComponent {
  @Input() data: CellConfigInterface;
  cellTypeEnum = CellTypeEnum;
}
