import { BreakpointObserver } from '@angular/cdk/layout';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';
import { BaseTableComponent } from './base-table.components';
import { DatatableBulkConfigType } from './models/datatable.interface';
import { ListModeEnum } from './models/list-mode.enum';

@Component({
  selector: 'twaice-fe-v2-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DataTableV2Component extends BaseTableComponent implements OnInit {
  @Input() enableBulkSelection = false;
  @Input() enableTableConfig = true;
  @Input() bulkActions: DatatableBulkConfigType;
  @Input() updateRouteFilter = false;
  @Input() maxSelectLimit = -1;
  @Output() selectedItems$ = new EventEmitter<string[]>();

  @ContentChild('listTemplate') listTemplate: TemplateRef<any>;
  @ContentChild('cardsTemplate') cardsTemplate: TemplateRef<any>;
  @ContentChild('customListTemplate') customListTemplate: TemplateRef<any>;

  @ViewChild(CdkVirtualScrollViewport, { static: false })
  public viewPort: CdkVirtualScrollViewport;

  loadingStripes: number[] = Array(10)
    .fill(0)
    .map((_, idx) => idx);
  listModeEnum = ListModeEnum;

  protected bulkSelection$ = new BehaviorSubject<{ [id: number]: any }>({});
  protected sorted: string;

  constructor(
    protected store: Store,
    public breakpointObserver: BreakpointObserver
  ) {
    super(store, breakpointObserver);
  }

  /**
   * cdk-virtual-scroll-viewport helps with virtual dom rendering while scrolling
   * this function helps set the correct translation of the table header to avoid jumping while
   * rendering new rows
   * @returns translation offset to update header position
   */
  public get inverseOfTranslation(): string {
    if (!this.viewPort || !this.viewPort['_renderedContentOffset']) {
      return '-0px';
    }

    const offset = this.viewPort['_renderedContentOffset'];
    return `-${offset}px`;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.listenToSelectedItems();
  }

  /**
   * Sorts the table based on the specified column.
   *
   * @param col - The column name to sort by.
   */
  public sortColumn(col: string): void {
    if (this.config[col]?.sortableByKey) {
      this.sorted = col;
      super.sort(this.config[col].sortableByKey);
    }
  }

  /**
   * Selects or deselects all items for bulk actions based on the passed argument.
   *
   * @param all - If true, all items are selected; otherwise, all items are deselected.
   */
  public onBulkSelectAll(all: boolean): void {
    if (all) {
      this.selectAllBulkItems();
    } else {
      this.bulkSelection$.next({});
    }
  }

  /**
   * Updates the bulk selection based on the given state and item.
   *
   * @param state - An object containing the selection state.
   * @param item - The item to be added or removed from the bulk selection.
   */
  public onBulkSelection({ state }, item: any): void {
    const id = item.id ?? item.containerID;
    const newBulkSelection = { ...this.bulkSelection$.value };

    if (state) {
      newBulkSelection[id] = item;
    } else if (newBulkSelection[id]) {
      delete newBulkSelection[id];
    }

    this.bulkSelection$.next(newBulkSelection);
    this.selectedItems$.emit(Object.keys(this.bulkSelection$.getValue()));
  }

  /**
   * Provides a unique identifier for each item, helping Angular track changes and optimize performance.
   *
   * @param _ - Ignored index parameter.
   * @param item - The item for which the change is tracked.
   * @returns The unique identifier (ID) of the item.
   */
  public trackChanges(_: any, item: any): number {
    return item.id;
  }

  /**
   * Observes the selected items in the store.
   */
  private listenToSelectedItems(): void {
    this.store
      .select(this.getSelector())
      .pipe(
        map(({ selectedIds, entities }) => ({ selectedIds, entities })),
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))
      )
      .subscribe(({ selectedIds, entities }) => this.processSelectedItems(selectedIds, entities));
  }

  /**
   * Processes the selected items received from the store.
   *
   * @param selectedIds - Array of selected item IDs.
   * @param entities - Object containing the entities by their ID.
   */
  private processSelectedItems(selectedIds: number[], entities: { [id: number]: any }): void {
    if (!selectedIds || selectedIds.length === 0) {
      this.bulkSelection$.next([]);
      return;
    }

    selectedIds.forEach((newId) => {
      const newItem = { [newId]: entities[newId] };
      const selectedItems =
        selectedIds.length < Object.keys(this.bulkSelection$.value).length
          ? newItem
          : { ...this.bulkSelection$.value, ...newItem };
      this.bulkSelection$.next(selectedItems);
    });
  }

  /**
   * Gathers all items for bulk selection based on the current state.
   */
  private selectAllBulkItems(): void {
    this.store
      .select(this.getSelector())
      .pipe(
        switchMap((state) => this.buildBulkItemsFromState(state)),
        take(1)
      )
      .subscribe((data) => {
        this.bulkSelection$.next(data);
      });
  }

  private buildBulkItemsFromState(state: any): Observable<{ [id: number]: any }> {
    if (state.config.filter || Object.keys(state.customFilters ?? {}).length > 0 || state.config.searchString) {
      return this.elements$.pipe(
        map(({ data }: any) => {
          // If maxSelected is not -1, then slice the list based on maxSelected value
          if (this.maxSelectLimit !== -1) {
            data = data.slice(0, this.maxSelectLimit);
          }
          return data.reduce((obj, item) => Object.assign(obj, { [item.id]: item }), {});
        })
      );
    }

    // If maxSelectLimit is not -1 and the number of entities exceeds maxSelectLimit, then slice the entities.
    if (this.maxSelectLimit !== -1 && Object.keys(state.entities).length > this.maxSelectLimit) {
      const limitedEntities = {};
      Object.keys(state.entities)
        .slice(0, this.maxSelectLimit)
        .forEach((key) => {
          limitedEntities[key] = state.entities[key];
        });

      return of(limitedEntities);
    }

    return of(state.entities);
  }
}
