<div class="error-wrapper">
  <h4>Houston, we have a problem!</h4>
  <h1>ERROR: Missing configuration</h1>
  <p>
    We were not able to get the configuration for your user.<br />
    You might be able to solve the issue by trying to sign out and back in again:
  </p>
  <button mat-flat-button color="accent" (click)="signOut()" data-test="sign-out-button">
    <mat-icon>exit_to_app</mat-icon>
    Sign Out
  </button>
  <p>If the issue persist please contact us at: <a href="mailto:support@twaice.com" target="_blank">support&#64;twaice.com</a></p>
</div>
