/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { missingCalculationAlert } from '@twaice-fe/frontend/shared/utilities';
import {
  AggregationScope,
  Health,
  HealthAlert,
  KPI,
  HealthRequestMeasurementParameters,
  HealthRequestParameters,
  HttpParametersObject,
  SingleRackKpisEnum,
} from '@twaice-fe/shared/models';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class HealthService {
  constructor(private httpClient: HttpClient) {}

  /**
   * Request health data for a specific systemID at depth level
   *
   * @param kpiName
   * @param requestParams
   * @param systemID
   * @param aggregationScope
   * @param depth
   */
  getHealthData({
    requestParams,
    systemID,
    aggregationScope,
    depth,
  }: {
    requestParams: HealthRequestParameters;
    systemID: string;
    aggregationScope: AggregationScope;
    depth: number;
  }): Observable<Health | HealthAlert> {
    let healthParameters: HttpParametersObject = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      system_id: systemID,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      aggregation_scope: aggregationScope,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      aggregation_type: requestParams.aggregationType,
      depth: depth.toString(),
    };

    const url = requestParams.useLatest ? `health/${requestParams.kpi}/latest` : `health/${requestParams.kpi}`;

    if (!requestParams.useLatest) {
      healthParameters = {
        ...healthParameters,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        start_date: requestParams.startDate,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        end_date: requestParams.endDate,
        sampling: requestParams.sampling,
      };
    }

    return this.httpClient.get<Health | HealthAlert>(url, { params: healthParameters }).pipe(
      map((health) => ({ ...health, kpi: requestParams.kpiName })),
      catchError(() => {
        const healthAlert = new HealthAlert();

        healthAlert.kpi = requestParams.kpiName;

        healthAlert.alert = missingCalculationAlert;

        return of(healthAlert);
      })
    );
  }

  getHealthMeasurementsData({
    systemID,
    containerID,
    kpi,
    healthRequestMeasurementParameters,
  }: {
    systemID: string;
    containerID: string;
    kpi: SingleRackKpisEnum | KPI;
    healthRequestMeasurementParameters: HealthRequestMeasurementParameters;
  }): Observable<Health | HealthAlert> {
    const url = `health/${kpi}/${systemID}/${containerID}`;
    if (!healthRequestMeasurementParameters)
      throw Error('[HealthService] failed while fetching health data, healthRequestMeasurementParameters is undefined');

    return this.httpClient
      .get<Health | HealthAlert>(url, { params: JSON.parse(JSON.stringify(healthRequestMeasurementParameters)) })
      .pipe(
        map((response) => ({ ...response, containerID })),
        catchError(() => {
          const healthAlert = new HealthAlert();

          healthAlert.kpi = kpi;

          healthAlert.alert = missingCalculationAlert;

          healthAlert.containerId = containerID;

          return of(healthAlert);
        })
      );
  }
}
