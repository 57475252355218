import { Component, Input } from '@angular/core';

@Component({
  selector: 'twaice-fe-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss'],
})
export class ProgressSpinnerComponent {
  @Input() overlay = false;
  @Input() spinnerSize = 60;
  @Input() spinnerColor = 'primary';
  @Input() spinnerText?: string;
}
