export const unionArrays = <T, K>(array1: T[], array2: T[], getKey: (item: T) => K): T[] => {
  const map = new Map<K, T>();

  [...array1, ...array2].forEach((item) => map.set(getKey(item), item));

  return Array.from(map.values());
};

export const isArrayEqual = (a: Array<string | number> | undefined, b: Array<string | number> | undefined) => {
  if (!a || !b) return false;
  if (a.length !== b.length) return false;
  return JSON.stringify(a.slice().sort()) === JSON.stringify(b.slice().sort());
};
