export const positionTooltip = ({
  x,
  y,
  barWidth,
  tooltip,
  chartContainer,
}: {
  x: number;
  y: number;
  barWidth: number;
  tooltip: HTMLDivElement;
  chartContainer: HTMLDivElement;
}): HTMLDivElement => {
  tooltip.style.display = 'flex';

  // Calculate if the tooltip would extend outside the component and flip it accordingly
  const flipX = x + tooltip.clientWidth > chartContainer.clientWidth;
  const extraPadding = 5;

  if (flipX) {
    tooltip.classList.add('flip');
    tooltip.style.transform = `translate(${x - tooltip.clientWidth - barWidth / 2 - extraPadding * 2}px, ${y}px)`;
  } else {
    tooltip.classList.remove('flip');
    tooltip.style.transform = `translate(${x + barWidth / 2 + extraPadding}px, ${y}px)`;
  }

  return tooltip;
};
