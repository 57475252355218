import { ConfidenceIntervalData } from '@twaice-fe/frontend/shared/utilities';
import { select } from 'd3';
import { lineChartDimensions } from './health-line-chart.config';
import { TooltipContentInterface } from './models/health-line-chart.models';

export const initTooltipContent = ({
  data,
  layoutInfo,
  confidenceIntervalData,
}: {
  data: { x: string; y: number; index: number };
  layoutInfo: { color: string; title: string; unit: string; valuePrecision: number };
  confidenceIntervalData?: ConfidenceIntervalData;
}): TooltipContentInterface => {
  const tooltipContent = { color: layoutInfo.color, content: [] };

  const firstRow = [layoutInfo.title, data.y?.toFixed(layoutInfo.valuePrecision) + layoutInfo.unit];

  if (confidenceIntervalData && confidenceIntervalData.x.length) {
    const uncertainty = Math.max(confidenceIntervalData.y1[data.index] - data.y, data.y - confidenceIntervalData.y0[data.index]);

    firstRow.push(`± ${parseFloat(uncertainty?.toFixed(layoutInfo.valuePrecision + 1)) + layoutInfo.unit}`);
  }

  tooltipContent.content.push(firstRow, [data.x]);

  return tooltipContent;
};

export const positionTooltip = ({
  x,
  y,
  tooltip,
  chartContainer,
}: {
  x: number;
  y: number;
  tooltip: HTMLDivElement;
  chartContainer: HTMLDivElement;
}): HTMLDivElement => {
  tooltip.style.display = 'flex';

  const parentWidth = chartContainer.clientWidth;

  const tooltipWidth = tooltip.clientWidth;

  const tooltipHeight = tooltip.clientHeight;

  // Calculate if the tooltip would extend outside the component and flip it accordingly
  const flipX = x + tooltipWidth > parentWidth;

  if (flipX) {
    tooltip.classList.add('flip');
  } else {
    tooltip.classList.remove('flip');
  }

  const calculatedX = flipX ? x - tooltipWidth - 40 : x;

  tooltip.style.transform = `translate(${calculatedX + 20}px, ${y - tooltipHeight / 8}px)`;

  return tooltip;
};

export const addLineChartTooltip = ({ x, chartContainer }: { x: number; chartContainer: HTMLDivElement }) => {
  select(chartContainer).select('#lines #line-tooltip').remove();

  select(chartContainer)
    .select('#lines')
    .insert('line', ':first-child')
    .attr('class', 'line-tooltip')
    .attr('id', 'line-tooltip')
    .attr('x1', x)
    .attr('y1', 5)
    .attr('x2', x)
    .attr('y2', chartContainer.clientHeight - lineChartDimensions.marginTop - lineChartDimensions.marginBottom);
};
