import { Injectable } from '@angular/core';
import { select, Selection } from 'd3';
import { ChartLayoutService } from '../../chart-helpers/chart-layout.service';
import { ChartViewInterface } from '../chart-view.interface';
import { StackedBarChartData, StackedBarViewOptions } from '../models/chart-view.models';
import { skip, Subscription } from 'rxjs';
import { HoverEventService } from '../../chart-events/hover-event.service';
import { ClickEventService } from '../../chart-events/click-event.service';

@Injectable()
export class StackedBarViewService implements ChartViewInterface<StackedBarChartData[], StackedBarViewOptions> {
  private eventSubscription: Subscription;

  constructor(
    private eventService: HoverEventService<StackedBarChartData>,
    private clickEventService: ClickEventService<StackedBarChartData>
  ) {}

  draw({
    chartSVGContainer,
    chartLayoutService,
    data,
    options,
  }: {
    chartSVGContainer: Selection<SVGGElement, unknown, null, undefined>;
    chartLayoutService: ChartLayoutService;
    data: StackedBarChartData[];
    options: StackedBarViewOptions;
  }): Selection<SVGGElement, unknown, null, undefined> {
    const {
      color,
      xPadding,
      yPadding,
      events: { onHover, onClick },
    } = { ...{ xPadding: 0, yPadding: 0 }, ...options };
    const yScale = chartLayoutService.getYScale();
    const xScale = chartLayoutService.getXScale();
    const dimensions = chartLayoutService.getChartDimensions();
    const barWidth = (dimensions.width + dimensions.marginLeft + dimensions.marginRight) / data.length - xPadding;

    const chartContent = chartSVGContainer
      .select('#chart_content')
      .append('g')
      .attr('id', 'stacked-bars')
      .attr('class', 'stacked-bars')
      .attr('data-test', 'stacked-bars');

    const groups = chartContent
      .selectAll('.bar-group')
      .data(data)
      .enter()
      .append('g')
      .attr('id', (_d, index) => `bar-group-${index}`)
      .attr('class', 'bar-group')
      .attr('transform', (d) => `translate(${xScale(d.x) - barWidth / 2 - xPadding},0)`)
      .attr('x', (d) => xScale(d.x))
      .attr('y', dimensions.height)
      .attr('width', barWidth + xPadding);

    groups.each((d, index) => {
      const group = select(`#bar-group-${index}`);
      let y0 = 0;
      Object.keys(d.y)
        .reverse()
        .forEach((key) => {
          const y1 = yScale(y0) - yScale(y0 + d.y[key]);
          group
            .append('rect')
            .attr('x', 0)
            .attr('y', yScale(y0 + d.y[key]))
            .attr('width', barWidth)
            .attr('height', y1 ? y1 - yPadding : y1)
            .attr('fill', color[key]);
          y0 += d.y[key];
        });

      // Added to fix fluctuating on hover due to xPadding
      group
        .append('rect')
        .attr('x', -xPadding)
        .attr('y', 0)
        .attr('width', (dimensions.width + dimensions.marginLeft + dimensions.marginRight) / data.length + xPadding)
        .attr('height', dimensions.height)
        .attr('fill', 'transparent');

      group
        .append('rect')
        .attr('class', 'interaction-bar')
        .attr('x', 0)
        .attr('y', yPadding)
        .attr('width', (dimensions.width + dimensions.marginLeft + dimensions.marginRight) / data.length - xPadding)
        .attr('height', dimensions.height - 2 * yPadding)
        .attr('rx', 4)
        .attr('stroke-width', 1)
        .attr('fill', 'transparent');
    });

    if (onHover && typeof onHover === 'function') {
      this.eventService.attachEvent({
        chartSVGContainer: chartContent,
        selectedElement: '.bar-group',
      });

      this.eventSubscription = this.eventService.getHoverEvents().subscribe((event) => onHover(event));
    }

    if (onClick && typeof onClick === 'function') {
      this.clickEventService.attachEvent({
        chartSVGContainer: chartContent,
        selectedElement: '.interaction-bar',
      });

      this.eventSubscription = this.clickEventService.getClickEvent().pipe(skip(1)).subscribe(onClick);
    }

    return chartContent;
  }

  clear(chartContainer: HTMLDivElement): void {
    select(chartContainer).selectAll('#stacked-bars').remove();

    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }
}
