import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { SafePipeModule } from 'safe-pipe';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { PopoverIconComponent } from './popover-icon.component';

@NgModule({
  declarations: [PopoverIconComponent],
  exports: [PopoverIconComponent],
  imports: [CommonModule, SafePipeModule, SvgIconModule, NzPopoverModule],
})
export class PopoverIconModule {}
