import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { catchError, filter, map, mergeMap, of, takeUntil, withLatestFrom } from 'rxjs';

import { WarrantyTrackerService } from 'libs/frontend/features/energy-analytics/warranty-tracker/src/lib/warranty-tracker.service';

import * as warrantyTrackerActions from '../actions/warranty-tracker.actions';
import * as warrantyTrackerSelectors from '../selectors/warranty-tracker.selectors';

@Injectable()
export class WarrantyTrackerEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(...[warrantyTrackerActions.fetchWarrantyData]),
      withLatestFrom(this.store.select(warrantyTrackerSelectors.getWarrantyTrackerState)),
      mergeMap(([action, state]) => {
        const { systemID, levelDepth, warrantyReferenceMetadata } = action;

        return this.warrantyTrackerService.getWarrantyOverview(systemID, levelDepth, warrantyReferenceMetadata).pipe(
          filter((response) => !!response),
          map(({ data }) => warrantyTrackerActions.loadWarrantyDataSuccess({ data })),
          takeUntil(this.actions$.pipe(ofType(warrantyTrackerActions.cancelWarrantyDataRequest))),
          catchError((error) => {
            console.error('Error', error);
            return of(warrantyTrackerActions.loadWarrantyFailure({ error }));
          })
        );
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    protected store: Store,
    protected warrantyTrackerService: WarrantyTrackerService
  ) {}
}
