<div class="w-[250] p-8 flex flex-col gap-8">
  <ng-container *ngIf="filterConfig.option === 'range'; else selectionTemplate">
    <label>{{ filterConfig.label }}:</label>
    <mat-slider
      class="mt-16"
      [min]="filterConfig.min"
      [max]="filterConfig.max"
      step="1"
      showTickMarks
      discrete
      [displayWith]="formatLabel"
      (click)="$event.stopPropagation()"
    >
      <input [value]="filterConfig.min" matSliderStartThumb (valueChange)="handleValueChange($event, 'min')" />
      <input [value]="filterConfig.max" matSliderEndThumb (valueChange)="handleValueChange($event, 'max')" />
    </mat-slider>
  </ng-container>

  <ng-template #selectionTemplate>
    <label class="text-left">{{ filterConfig.label }}:</label>
    <form class="flex flex-col items-center justify-start">
      <mat-form-field (click)="$event.stopPropagation()">
        <input type="number" matInput [formControl]="minFormControl" [errorStateMatcher]="matcher" placeholder="Min (Minutes)" />
        <mat-error *ngIf="minFormControl.hasError('min')"> Min value is not valid </mat-error>
      </mat-form-field>

      <mat-form-field (click)="$event.stopPropagation()">
        <input
          type="number"
          matInput
          [formControl]="maxFormControl"
          (click)="$event.stopPropagation()"
          [errorStateMatcher]="matcher"
          placeholder="Max. (Minutes)"
        />

        <mat-error *ngIf="maxFormControl.hasError('max')"> Max value is not valid </mat-error>
      </mat-form-field>
    </form>
  </ng-template>
</div>
