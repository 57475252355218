import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  AnnotationViewService,
  AreaViewService,
  ClickEventService,
  GlobalEventService,
  LineViewService,
} from '@twaice-fe/frontend/shared/utilities';
import { AlertPanelModule } from '../alert-panel/alert-panel.module';
import { ProgressSpinnerModule } from '../progress-spinner/progress-spinner.module';
import { HealthLineChartComponent } from './health-line-chart.component';
import { SvgIconModule } from '../svg-icon/svg-icon.module';

@NgModule({
  declarations: [HealthLineChartComponent],
  imports: [CommonModule, ProgressSpinnerModule, AlertPanelModule, SvgIconModule],
  exports: [HealthLineChartComponent],
  providers: [LineViewService, AreaViewService, GlobalEventService, AnnotationViewService, ClickEventService, DatePipe],
})
export class HealthLineChartModule {}
