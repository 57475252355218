<td *ngIf="enableBulkSelection" class="checkbox-cell">
  <mat-checkbox
    (click)="$event.stopImmediatePropagation()"
    [formControl]="selectionCheckbox"
  ></mat-checkbox>
</td>

<!-- state icon for reviewed or active ... Start -->
<ng-container>
  <td class="new-td" *ngIf="false; else emptyTd">
    <span></span>
  </td>

  <ng-template #emptyTd>
    <td></td>
  </ng-template>
</ng-container>
<!-- state icon for reviewed or active ... End -->

<ng-container *ngFor="let key of columns | async">
  <td [ngClass]="content[key]?.style" (click)="handleRowClick(content[key])">
    <ng-container [ngSwitch]="content[key].type">
      <ng-container *ngSwitchCase="cellTypeEnum.STATUS">
        <twaice-fe-datatable-cell-status [data]="content[key]" />
      </ng-container>

      <!-- ---- ICON TYPE ---  -->
      <ng-container *ngSwitchCase="cellTypeEnum.ICON">
        <twaice-fe-icon-cell [dense]="dense" [data]="content[key]" />
      </ng-container>

      <!-- ---- ICON BUTTON ---- -->
      <ng-container *ngSwitchCase="cellTypeEnum.ICON_LINK" (click)="onEvent($event)">
        <twaice-fe-icon-link [dense]="dense" [data]="content[key]" />
      </ng-container>

      <!-- ---- Tags ---- -->
      <ng-container *ngSwitchCase="cellTypeEnum.TAGS">
        <twaice-fe-tag-cell-component [data]="content[key]" />
      </ng-container>
      <!-- ---- Tooltips ---- -->
      <ng-container *ngSwitchCase="cellTypeEnum.TOOLTIP">
        <twaice-fe-datatable-tooltip-cell [data]="content[key]" />
      </ng-container>

      <!-- ---- Actions button ----- -->
      <ng-container *ngSwitchCase="cellTypeEnum.ACTIONS_BUTTON">
        <twaice-fe-actions-buttons-cell (actionClicked)="action($event)" [data]="content[key]"></twaice-fe-actions-buttons-cell>
      </ng-container>

      <ng-container *ngSwitchCase="cellTypeEnum.PROGRESS_BAR">
        <twaice-fe-progress-bar-cell [data]="content[key]"></twaice-fe-progress-bar-cell>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <twaice-fe-datatable-text-cell [data]="content[key]" />
      </ng-container>
    </ng-container>
  </td>
</ng-container>
