import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectFilterInterface } from '../../models/filter-config.interface';
import { distinctUntilChanged, Subject } from 'rxjs';

@Component({
    selector: 'twaice-fe-datatable-select-filter',
    templateUrl: 'datatable-select-filter.component.html'
})
export class DatatableSelectFilterComponent implements OnInit {
    @Input() filterConfig: SelectFilterInterface;
    @Input() resetForms$: Subject<boolean>;
    @Output() emitFilterChange: EventEmitter<Record<string, unknown>> = new EventEmitter();

    selectedValue: string | null = null;

    ngOnInit(): void {
        this.resetForms$.pipe(distinctUntilChanged()).subscribe((val) => {
            if (val) this.selectedValue = null
        });
    }

    onSelectionChange(value: string): void {
        if (value) this.emitFilterChange.emit({ [this.filterConfig.filterBy]: value });
    }
}