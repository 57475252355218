import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subject, combineLatest, debounceTime, distinctUntilChanged, filter, startWith } from 'rxjs';
import { NumberFilterInterface } from '../../models/filter-config.interface';

@Component({
  selector: 'twaice-fe-datatable-number-filter',
  templateUrl: 'datatable-number-filter.component.html',
  styleUrls: ['datatable-number-filter.component.scss'],
})
export class DatatableNumberFilterComponent implements OnInit {
  @Input() filterConfig: NumberFilterInterface;
  @Input() resetForms$: Subject<boolean>;
  @Output() emitFilterChange: EventEmitter<Record<string, unknown>> = new EventEmitter();

  minFormControl = new FormControl('', [Validators.min(0)]);
  maxFormControl = new FormControl('', [Validators.min(0)]);

  initialState = { min: undefined, max: undefined };
  currentValue: Record<string, number> = this.initialState;

  ngOnInit(): void {
    this.resetForms$.pipe(distinctUntilChanged()).subscribe((val) => {
      if (val) {
        this.currentValue = this.initialState;
      }
    });

    const isValid = (vals) => !this.minFormControl.hasError('min') && !this.maxFormControl.hasError('max');

    combineLatest([
      this.minFormControl.valueChanges.pipe(startWith(undefined)),
      this.maxFormControl.valueChanges.pipe(startWith(undefined)),
    ])
      .pipe(filter(isValid), debounceTime(500))
      .subscribe((form) => {
        form.forEach((formItemValue, index) => {
          if (formItemValue !== undefined && formItemValue >= 0) this.handleValueChange(formItemValue, index > 0 ? 'max' : 'min');
        });
      });
  }

  formatLabel(value: number): string {
    return `${value}`;
  }

  handleNumberFormat(value: number) {
    return this.filterConfig.offset ? this.filterConfig.offset(value) : value;
  }

  handleValueChange(value, key) {
    this.currentValue[key] = value;
    this.emitFilterChange.emit({
      ...(this.currentValue.min && { [this.filterConfig.from]: this.handleNumberFormat(this.currentValue.min) }),
      ...(this.currentValue.max && { [this.filterConfig.to]: this.handleNumberFormat(this.currentValue.max) }),
    });
  }
}
