import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(
    public authService: AuthService,
    public router: Router
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    // if the user is already logged in and tries to access any of the pages for not-logged-in users, redirect them to homepage
    const isLoggedIn = this.authService.isLoggedIn();
    if (!isLoggedIn) {
      this.router.navigate(['login']);
      return false;
    }

    // if password needs to be changed, redirect to settings and block all other navigation
    if (this.authService.isPasswordChangeForced() && !route.url.toString().includes('settings')) {
      await this.router.navigate(['/settings/password-change'], { queryParams: { forceChange: true } });
      return false;
    }

    return true;
  }
}
