import { createAction, props } from '@ngrx/store';
import { FilterBarValues } from '@twaice-fe/frontend/shared/utilities';
import { ListConfigurationInterface } from '../types/list-configuration.interface';
import { EnergyPrediction } from '@twaice-fe/shared/models';

export const fetchEnergyPredictionData = createAction(
  '[Energy prediction] fetch energy prediction data',
  props<{ systemID: string }>()
);

export const loadEnergyPredictionDataSuccess = createAction(
  '[Energy prediction] Load energy prediction data success',
  props<{
    data: EnergyPrediction;
  }>()
);

export const loadEnergyPredictionFailure = createAction(
  '[Energy prediction] Load energy prediction error',
  props<{ error: any }>()
);

export const updatePredictionHealthKpiListConfiguration = createAction(
  '[Energy prediction] Update table configuration',
  props<{ config: ListConfigurationInterface; isLoading?: boolean }>()
);

export const filterPredictionHealthKpiList = createAction(
  '[Energy prediction] Filter prediction health kpi list',
  props<{ filter: { [key: string]: FilterBarValues[] } }>()
);

export const predictionHealthKpiColumnPicker = createAction(
  '[Datatable/Config] Energy prediction column picker',
  props<{ columns: string[] }>()
);

export const sortHealthPredictionList = createAction(
  '[Datatabke/Sort] Sorting energy prediction list',
  props<{ order: string }>()
);

export const selectPredictionContainer = createAction('[Energy prediction] Select rack container', props<{ rackID: string }>());

export const cancelPredictionRequest = createAction('[Energy prediction] Cancel energy prediction request');
