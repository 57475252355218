<h4 class="mb-8">
  {{ 'Unleash the Full Potential of Batteries' | translate: { _key: 'shared.analytics-upsell.header' } }}
</h4>
<p class="text-sm">
  {{
    '  Make impactful, data-driven decisions using reliable insights from the leading AI-supported battery analytics platform.'
      | translate: { _key: 'shared.analytics-upsell.subheader' }
  }}
</p>

<nz-tabset [nzAnimated]="false" class="pb-16">
  <nz-tab [nzTitle]="'Energy Analytics Demos' | translate: { _key: 'shared.analytics-upsell.energy-tab-label' }">
    <div class="analytics-upsell__grid">
      <nz-ribbon [nzText]="'Demo coming soon!' | translate: { _key: 'shared.analytics-upsell.coming-soon-ribbon' }">
        <nz-card [nzCover]="pmCoverTemplate">
          <nz-card-meta
            nzTitle="{{ 'Performance Manager' | translate: { _key: 'shared.analytics-upsell.performance-manager.header' } }}"
            nzDescription="{{
              'Maximise revenue and decrease downtime by identifying and assessing the degree of system imbalance.'
                | translate: { _key: 'shared.analytics-upsell.performance-manager.description' }
            }}"
          />
        </nz-card>
      </nz-ribbon>

      <ng-template #pmCoverTemplate>
        <div class="flex items-center justify-center px-8 py-16 bg-[#FAFAFA]">
          <img class="shadow-default rounded-none" alt="example" src="assets/img/screenshots/energy-performance.png" />
        </div>
      </ng-template>

      <nz-card
        [nzCover]="safetyCoverTemplate"
        [twDemoLink]="demoLinks.ENERGY_INCIDENTS_DEMO_LINK"
        [nzHoverable]="true"
        class="analytics-upsell__link"
      >
        <nz-card-meta
          nzTitle="{{ 'Safety Monitoring' | translate: { _key: 'shared.analytics-upsell.safety.header' } }}"
          nzDescription="{{
            'Get notifications on critical system behaviours potentially leading to safety incidents.'
              | translate: { _key: 'shared.analytics-upsell.safety.description' }
          }}"
        />
      </nz-card>

      <ng-template #safetyCoverTemplate>
        <div class="flex items-center justify-center px-8 py-16 bg-[#FAFAFA]">
          <img class="shadow-default rounded-none" alt="example" src="assets/img/screenshots/safety.png" />
        </div>
      </ng-template>

      <nz-card
        [nzCover]="healthCoverTemplate"
        [twDemoLink]="demoLinks.ENERGY_ANALYTICS_DEMO_LINK"
        [nzHoverable]="true"
        class="analytics-upsell__link"
      >
        <nz-card-meta
          nzTitle="{{ 'Health Monitoring' | translate: { _key: 'shared.analytics-upsell.health.header' } }}"
          nzDescription="{{
            'Maximize battery lifetime and save costs by accessing accurate battery health insights.'
              | translate: { _key: 'shared.analytics-upsell.health.description' }
          }}"
        />
      </nz-card>
      <ng-template #healthCoverTemplate>
        <div class="flex items-center justify-center px-8 py-16 bg-[#FAFAFA]">
          <img class="shadow-default rounded-none" alt="example" src="assets/img/screenshots/energy-health.png" />
        </div>
      </ng-template>

      <nz-card
        [nzCover]="warrantyCoverTemplate"
        [twDemoLink]="demoLinks.ENERGY_WARRANTY_DEMO_LINK"
        [nzHoverable]="true"
        class="analytics-upsell__link"
      >
        <nz-card-meta
          nzTitle="{{ 'Warranty Tracker' | translate: { _key: 'shared.analytics-upsell.warranty.header' } }}"
          nzDescription="{{
            'Find the balance between a profitable operating strategy and battery wear within specified warranty limits.'
              | translate: { _key: 'shared.analytics-upsell.warranty.description' }
          }}"
        />
      </nz-card>
      <ng-template #warrantyCoverTemplate>
        <div class="flex items-center justify-center px-8 py-16 bg-[#FAFAFA]">
          <img class="shadow-default rounded-none" alt="example" src="assets/img/screenshots/warranty.png" />
        </div>
      </ng-template>

      <nz-ribbon [nzText]="'Demo coming soon!' | translate: { _key: 'shared.analytics-upsell.coming-soon-ribbon' }">
        <nz-card [nzCover]="dataExplorerCoverTemplate">
          <nz-card-meta
            nzTitle="{{ 'Data Explorer' | translate: { _key: 'shared.analytics-upsell.data-explorer.header' } }}"
            nzDescription="{{
              'Deep dive into your asset`s operation on different levels of components over time.'
                | translate: { _key: 'shared.analytics-upsell.data-explorer.description' }
            }}"
          />
        </nz-card>
      </nz-ribbon>

      <ng-template #dataExplorerCoverTemplate>
        <div class="flex items-center justify-center px-8 py-16 bg-[#FAFAFA]">
          <img class="shadow-default rounded-none" alt="example" src="assets/img/screenshots/data-explorer.png" />
        </div>
      </ng-template>
    </div>
  </nz-tab>

  <nz-tab [nzTitle]="'Mobility Analytics Demos' | translate: { _key: 'shared.analytics-upsell.mobility-tab-label' }">
    <div class="analytics-upsell__grid">
      <nz-ribbon [nzText]="'Demo coming soon!' | translate: { _key: 'shared.analytics-upsell.coming-soon-ribbon' }">
        <nz-card [nzCover]="exploreCompareCoverTemplate">
          <nz-card-meta
            nzTitle="{{ 'Explore & Compare' | translate: { _key: 'shared.analytics-upsell.mobility.explore-compare.header' } }}"
            nzDescription="{{
              'Identify trends over time and outliers within a group of vehicles.'
                | translate: { _key: 'shared.analytics-upsell.mobility.explore-compare.description' }
            }}"
          />
        </nz-card>
      </nz-ribbon>
      <ng-template #exploreCompareCoverTemplate>
        <div class="flex items-center justify-center px-8 py-16 bg-[#FAFAFA]">
          <img class="shadow-default rounded-none" alt="example" src="assets/img/screenshots/mobility-compare.png" />
        </div>
      </ng-template>

      <nz-ribbon [nzText]="'Demo coming soon!' | translate: { _key: 'shared.analytics-upsell.coming-soon-ribbon' }">
        <nz-card [nzCover]="mobilitySafetyCoverTemplate">
          <nz-card-meta
            nzTitle="{{ 'Safety Monitoring' | translate: { _key: 'shared.analytics-upsell.mobility.safety.header' } }}"
            nzDescription="{{
              'Get notifications on critical battery behaviours potentially leading to safety incidents.'
                | translate: { _key: 'shared.analytics-upsell.mobility.safety.description' }
            }}"
          />
        </nz-card>
      </nz-ribbon>
      <ng-template #mobilitySafetyCoverTemplate>
        <div class="flex items-center justify-center px-8 py-16 bg-[#FAFAFA]">
          <img class="shadow-default rounded-none" alt="example" src="assets/img/screenshots/safety.png" />
        </div>
      </ng-template>

      <nz-card
        [nzCover]="mobilityHealthCoverTemplate"
        [twDemoLink]="demoLinks.MOBILITY_HEALTH_DEMO_LINK"
        [nzHoverable]="true"
        class="analytics-upsell__link"
      >
        <nz-card-meta
          nzTitle="{{ 'Health Monitoring' | translate: { _key: 'shared.analytics-upsell.mobility.health.header' } }}"
          nzDescription="{{
            'Maximize battery lifetime and save costs by accessing accurate battery health insights.'
              | translate: { _key: 'shared.analytics-upsell.mobility.health.description' }
          }}"
        />
      </nz-card>
      <ng-template #mobilityHealthCoverTemplate>
        <div class="flex items-center justify-center px-8 py-16 bg-[#FAFAFA]">
          <img class="shadow-default rounded-none" alt="example" src="assets/img/screenshots/mobility-health.png" />
        </div>
      </ng-template>

      <nz-ribbon [nzText]="'Demo coming soon!' | translate: { _key: 'shared.analytics-upsell.coming-soon-ribbon' }">
        <nz-card [nzCover]="mobilityFleetsCoverTemplate">
          <nz-card-meta
            nzTitle="{{ 'Fleet Creation' | translate: { _key: 'shared.analytics-upsell.mobility.fleets.header' } }}"
            nzDescription="{{
              'Group your vehicles into specifc fleets to get insights on and compare groups of vehicles.'
                | translate: { _key: 'shared.analytics-upsell.mobility.fleets.description' }
            }}"
          />
        </nz-card>
      </nz-ribbon>
      <ng-template #mobilityFleetsCoverTemplate>
        <div class="flex items-center justify-center px-8 py-16 bg-[#FAFAFA]">
          <img class="shadow-default rounded-none" alt="example" src="assets/img/screenshots/mobility-fleets.png" />
        </div>
      </ng-template>
    </div>
  </nz-tab>
</nz-tabset>
