import { DatabricksConfig, Environment } from './environment.model';

export const DATABRICKS_CONFIG_PLACEHOLDER: DatabricksConfig = {
  tokenArn: '{DATABRICKS_TOKEN_ARN}',
  pathArn: '{DATABRICKS_PATH_ARN}',
  hostArn: '{DATABRICKS_HOST_ARN}',
  databaseNames: {
    measurements: '{DATABRICKS_DATABASE_NAME_MEASUREMENTS}',
    battery_kpis: '{DATABRICKS_DATABASE_NAME_BATTERY_KPIS}',
    customer_config: '{DATABRICKS_DATABASE_NAME_CUSTOMER_CONFIG}',
    performance_manager: '{DATABRICKS_DATABASE_NAME_PERFORMANCE_MANAGER}',
  },
};

export const ENVIRONMENT_PLACEHOLDER: Environment = {
  production: true,
  name: '{ENV_NAME_REPLACE_ME}',
  staticFilesHost: '{STATIC_FILES_HOST_REPLACE_ME}',
  baseUrlBe4Fe: '{BASE_URL_BE4FE_REPLACE_ME}',
  baseUrlApi: '{BASE_URL_API_REPLACE_ME}',
  /* eslint-enable @typescript-eslint/naming-convention */
  // public identifier for the Transifex project
  transifexToken: '{TRANSIFEX_PROJECT_TOKEN_REPLACE_ME}',
  // public Hotjar site ID
  hotjarSiteId: '{HOTJAR_SITE_ID_REPLACE_ME}',
  // public Intercom App ID
  intercomAppId: '{INTERCOM_APP_ID_REPLACE_ME}',
  // public Mixpanel ID
  mixpanelId: '{MIXPANEL_ID_REPLACE_ME}',
};
