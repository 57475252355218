import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseStoreComponent } from '../base-store.component';
import { DatatableBulkConfigType } from '../models/datatable.interface';

@Component({
  selector: 'twaice-fe-bulk-actions-bar',
  templateUrl: './bulk-action-bar.component.html',
  styleUrls: ['./bulk-action-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BulkActionsBarComponent extends BaseStoreComponent implements OnDestroy, OnInit, OnChanges {
  @Input() totalItemCount: number;
  @Input() bulkActions: DatatableBulkConfigType;
  @Input() maxSelectLimit = -1;
  @Output() selectedAll = new EventEmitter<boolean>();

  selectAllCheckbox = new FormControl(false);
  selectedItems = 0;
  checkboxIndeterminate = false;

  selectionInfo = '';

  protected destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(protected store: Store) {
    super(store);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isTotalItemCountChange = !changes.totalItemCount.firstChange && changes.totalItemCount.currentValue;
    if (isTotalItemCountChange) {
      this.formulateSelectionText();
    }
  }

  ngOnInit() {
    this.initializeSelectAllCheckbox();
    this.subscribeToStore();
  }

  handleClick(action) {
    action();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }


  /**
 * Initializes the select all checkbox value change subscription.
 */
  private initializeSelectAllCheckbox(): void {
    this.selectAllCheckbox.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.selectedAll.emit(value);
    });
  }

  /**
 * Subscribes to the store to get the state and update the component accordingly.
 */
  private subscribeToStore(): void {
    this.store.select(this.getSelector()).subscribe((state) => {
      this.updateSelectedItems(state.selectedIds);
      this.updateSelectAllCheckbox();
      this.updateCheckboxIndeterminateState();
      this.formulateSelectionText();
    });
  }

  /**
 * Updates the count of selected items.
 */
  private updateSelectedItems(selectedIds: number[]): void {
    this.selectedItems = selectedIds.length;
  }

  /**
 * Updates the select all checkbox value based on the selected items and max selection limit.
 */
  private updateSelectAllCheckbox(): void {
    const bulkStatus = this.calculateBulkStatus();
    this.selectAllCheckbox.setValue(bulkStatus, { emitEvent: false });
  }

  /**
 * Calculates the bulk status based on selected items and max selection limit.
 */
  private calculateBulkStatus(): boolean {
    return this.selectedItems === (this.maxSelectLimit === -1 ? this.totalItemCount :
      this.totalItemCount < this.maxSelectLimit ? this.totalItemCount : this.maxSelectLimit);
  }

  /**
 * Updates the indeterminate state of the select all checkbox.
 */
  private updateCheckboxIndeterminateState(): void {
    const hasSomeSelected = this.selectedItems > 0 && this.selectedItems < this.totalItemCount;
    const withinMaxLimit = this.maxSelectLimit === -1 || this.selectedItems <= this.maxSelectLimit;
    this.checkboxIndeterminate = hasSomeSelected && withinMaxLimit;
  }

  private formulateSelectionText(): void {
    const basicInfo = `${this.selectedItems} of ${this.totalItemCount} items selected`;
    const limitInfo =
      this.maxSelectLimit > -1 && this.maxSelectLimit < this.totalItemCount
        ? ` (maximum selection of ${this.maxSelectLimit} items)`
        : '';

    this.selectionInfo = `${basicInfo}${limitInfo}`;
  }
}
