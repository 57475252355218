import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  BalancingSeverityEnum,
  TemperatureSeverityEnum,
} from 'libs/frontend/features/performance-manager/src/lib/performance-manager.config';
import {
  balancingSystemStatisticsAdapter,
  PERFORMANCE_MANAGER_FEATURE_KEY,
  State,
  temperatureSystemStatisticsAdapter,
} from '../reducers/performance-manager.reducer';

export const getPerformanceManagerState = createFeatureSelector<State>(PERFORMANCE_MANAGER_FEATURE_KEY);
const balancingStatisticsAdapter = balancingSystemStatisticsAdapter.getSelectors();
export const getBalancingSystemStatistics = createSelector(getPerformanceManagerState, (state) =>
  balancingStatisticsAdapter.selectAll(state.balancingSystemStatistics.data)
);
export const getLatestDayBalancingSystemStatistics = createSelector(getBalancingSystemStatistics, (entities) => {
  const sortedEntities = [...entities].sort((a, b) => b.x - a.x);
  const latestStatistics = sortedEntities.find(
    (stats) =>
      stats.y[BalancingSeverityEnum.CRITICAL] || stats.y[BalancingSeverityEnum.ELEVATED] || stats.y[BalancingSeverityEnum.NORMAL]
  );
  return latestStatistics ? latestStatistics.x : null;
});
export const getBalancingSystemStatisticsError = createSelector(
  getPerformanceManagerState,
  (state) => state.balancingSystemStatistics.error
);

const temperatureStatisticsAdapter = temperatureSystemStatisticsAdapter.getSelectors();
export const getTemperatureSystemStatistics = createSelector(getPerformanceManagerState, (state) =>
  temperatureStatisticsAdapter.selectAll(state.temperatureSystemStatistics.data)
);
export const getLatestDayTemperatureSystemStatistics = createSelector(getTemperatureSystemStatistics, (entities) => {
  const sortedEntities = [...entities].sort((a, b) => b.x - a.x);
  const latestStatistics = sortedEntities.find(
    (stats) =>
      stats.y[TemperatureSeverityEnum.CRITICAL] ||
      stats.y[TemperatureSeverityEnum.ELEVATED] ||
      stats.y[TemperatureSeverityEnum.NORMAL]
  );
  return latestStatistics ? latestStatistics.x : null;
});
export const getTemperatureSystemStatisticsError = createSelector(
  getPerformanceManagerState,
  (state) => state.temperatureSystemStatistics.error
);

export const getSystemKPIDataInverter = createSelector(getPerformanceManagerState, (state) => state.systemKPIDataInverter);
export const getSystemKPIDataInverterError = createSelector(
  getPerformanceManagerState,
  (state) => state.systemKPIDataInverter.error
);

export const getSystemKPIDataString = createSelector(getPerformanceManagerState, (state) => state.systemKPIDataString);
export const getSystemKPIDataStringError = createSelector(
  getPerformanceManagerState,
  (state) => state.systemKPIDataString.error
);

export const getSystemInsights = createSelector(getPerformanceManagerState, (state) => state.systemInsights);
export const getSystemSeverityLevel = createSelector(getPerformanceManagerState, (state) => state.systemSeverityLevel);
export const getSystemSeverityLevelError = createSelector(getPerformanceManagerState, (state) => state.systemSeverityLevel.error);

export const getStringKPI = createSelector(getPerformanceManagerState, (state) => state.stringKPIData);
export const getStringKPIError = createSelector(getPerformanceManagerState, (state) => state.stringKPIData.error);

export const getEnergyLossState = createSelector(getPerformanceManagerState, (state) => state.energyLoss);
export const getEnergyLossBySystem = (systemBk: string) =>
  createSelector(getEnergyLossState, ({ data: { entities } }) => entities[systemBk]);
