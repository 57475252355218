import { HttpParams } from '@angular/common/http';

type ParamType = string | number | boolean | undefined;

export class RequestQueryBuilder {
  constructor() {
    return this;
  }

  build(paramsObject: Record<string, ParamType | string[]>): HttpParams {
    let params = new HttpParams();
    Object.keys(paramsObject).forEach((paramKey: string) => {
      if (paramsObject[paramKey]) {
        if (Array.isArray(paramsObject[paramKey])) {
          (paramsObject[paramKey] as Array<any>).forEach((value) => (params = params.append(`${paramKey}[]`, value)));
        } else {
          params = params.set(paramKey, paramsObject[paramKey] as ParamType);
        }
      }
    });
    return params;
  }
}
