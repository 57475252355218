import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ENERGY_PREDICTION_FEATURE_KEY, HealthKpiListAdapter, State } from '../reducers/energy-prediction.reducer';
import { FilterBarValues } from '@twaice-fe/frontend/shared/utilities';
import { PredictionHealthKpiListItem } from '@twaice-fe/shared/models';

const { selectAll } = HealthKpiListAdapter.getSelectors();

export const getEnergyPredictionState = createFeatureSelector<State>(ENERGY_PREDICTION_FEATURE_KEY);
export const getEnergyPredictionKpiHealthState = createSelector(getEnergyPredictionState, (state) => state.healthKpiList);

export const isEnergyPredictionAvailable = createSelector(
  getEnergyPredictionState,
  (state) => state.rackPredictionModelList.length > 0
);

export const getRackPredictionModelList = createSelector(getEnergyPredictionState, (state) => state.rackPredictionModelList);
export const getStoragePredictionModel = createSelector(getEnergyPredictionState, (state) => state.storagePredictionModel);

export const getRawPredictionHealthKpiList = createSelector(getEnergyPredictionState, (state: State) =>
  selectAll(state.healthKpiList)
);

export const getPredictionHealthKpiList = createSelector(getEnergyPredictionState, (state: State) => {
  let data = selectAll(state.healthKpiList);

  if (Object.keys(state.healthKpiList.filter ?? {}).length) {
    data = filterPredictionHealthKpiList(data, state.healthKpiList.filter);
  }

  if (state.healthKpiList.config.searchString) {
    data = data.filter((item) => searchInAllKeys(item, state.healthKpiList.config.searchString ?? ''));
  }

  if (state.healthKpiList.config.order) {
    const [key, direction] = state.healthKpiList.config.order.split(':');
    data = sortDataByConfig(data, key as keyof PredictionHealthKpiListItem, direction as 'asc' | 'desc');
  }

  return data;
});

export const getPredictionMetadata = createSelector(getEnergyPredictionState, (state) => state.metadata);

const filterPredictionHealthKpiList = (
  healthKpiList: PredictionHealthKpiListItem[],
  kpiFilter: Record<string, FilterBarValues[]>
) => {
  const kpiName = Object.keys(kpiFilter)[0];
  const filters = kpiFilter[kpiName];

  return healthKpiList.reduce((result, val) => {
    for (const filter of filters) {
      if (
        filter &&
        ((filter.min === 0 && filter.max === 0 && val[kpiName] == null) ||
          (val[kpiName] >= filter.min && val[kpiName] < filter.max))
      ) {
        result.push(val);
        break;
      }
    }
    return result;
  }, []);
};

export const getSelected = createSelector(getEnergyPredictionKpiHealthState, (state) => state.selected);
export const getSelectedPredictionModel = createSelector(
  getEnergyPredictionState,
  getSelected,
  (state, selectedContainer) =>
    state.rackPredictionModelList.filter(({ container_id }) => container_id === selectedContainer)?.[0]
);

function searchInAllKeys(item: PredictionHealthKpiListItem, searchString: string): boolean {
  if (!searchString) return false;

  const formattedSearchString = searchString.trim().toLowerCase();

  const valueStr = String(item.container_name).toLowerCase();
  return valueStr.includes(formattedSearchString);
}

function sortDataByConfig(
  data: PredictionHealthKpiListItem[],
  key: keyof PredictionHealthKpiListItem,
  direction: 'asc' | 'desc'
): PredictionHealthKpiListItem[] {
  const isAscending = direction === 'asc';

  return [...data].sort((a, b) => {
    const valA = a[key];
    const valB = b[key];

    if (valA === undefined) return isAscending ? -1 : 1;
    if (valB === undefined) return isAscending ? 1 : -1;

    if (typeof valA === 'number' && typeof valB === 'number') {
      return isAscending ? valA - valB : valB - valA;
    }

    if (typeof valA === 'string' && typeof valB === 'string') {
      return isAscending
        ? valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' })
        : valB.localeCompare(valA, undefined, { numeric: true, sensitivity: 'base' });
    }

    return 0; // Return a neutral value if unexpected type encountered or if both values are of the same type but not number or string.
  });
}
