<div class="line-chart-wrapper">
  <div class="card-header">
    <div *ngIf="layout?.title" class="card-title-label" data-test="card-title-label">
      <twaice-fe-svg-icon *ngIf="layout?.titleIcon" [name]="layout?.titleIcon" [stroke]="'#040f26'"></twaice-fe-svg-icon>
      {{ layout?.title }}
    </div>
    <div class="card-items-container">
      <div *ngFor="let legend of layout?.legends" class="card-item-container">
        <div class="icon" [ngClass]="legend.shape" [style]="{ '--legend-color': legend.color }"></div>
        <div class="card-item-label">{{ legend.label }}</div>
      </div>
    </div>
  </div>
  <div class="card-content">
    <div [class.loaded]="!isLoading && !chartAlert" class="line-chart" #lineChart data-test="line-chart"></div>
    <div class="line-chart__tooltip" #tooltip>
      <div class="line-chart__tooltip--column">
        <span class="dot" [style]="{ '--legend-color': tooltipContent?.color }"></span>
      </div>
      <div *ngIf="tooltipContent?.content" class="line-chart__tooltip--column">
        <div *ngFor="let row of tooltipContent.content; let rowIndex = index" class="line-chart__tooltip--row">
          <span [ngClass]="{ value: rowIndex === 0 }" *ngFor="let column of row"> {{ column }} </span>
        </div>
      </div>
    </div>
    <twaice-fe-progress-spinner
      [spinnerSize]="40"
      *ngIf="isLoading && !chartAlert"
      [overlay]="true"
      data-test="data-loading"
    ></twaice-fe-progress-spinner>
    <twaice-fe-alert-panel
      *ngIf="chartAlert"
      [type]="chartAlert.type"
      [title]="chartAlert.title"
      [subTitle]="chartAlert.subTitle"
      data-test="alert-panel"
    ></twaice-fe-alert-panel>
  </div>
</div>
