import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { blue500 } from '@twaice-fe/frontend/shared/utilities';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[twFakeLoading]',
  standalone: true,
  imports: [CommonModule],
  styleUrl: './fake-loading.component.scss',
  templateUrl: './fake-loading.component.html',
  animations: [
    trigger('finishLoading', [
      transition(':leave', [
        style({ opacity: 0.6, background: blue500 }),
        animate('0.1s ease-in', style({ opacity: 0, background: blue500 })),
      ]),
    ]),
  ],
})
export class FakeLoadingComponent implements OnChanges {
  @Input() twFakeLoadingState = false;
  @Input() twFakeLoadingEstimate = 1.5;

  @HostBinding('style')
  animationVariance: Record<string, string> = {};

  constructor() {
    // inject some variance into the animation duration to avoid the same progress on all components
    this.animationVariance = {
      '--twaice-fake-loading-variance': `${this.twFakeLoadingEstimate + Math.random() * 2}s`,
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['twFakeLoadingEstimate']) {
      this.animationVariance = {
        '--twaice-fake-loading-variance': `${this.twFakeLoadingEstimate + Math.random() * 2}s`,
      };
    }
  }
}
