export const keysToCamel = (obj: Record<string, any>): any => {
  const camelCase = (s: string) => s.replace(/(_\w)/g, (k) => k[1].toUpperCase());

  if (!Array.isArray(obj) && obj instanceof Object) {
    const newObject = {};
    Object.keys(obj).forEach((key) => (newObject[camelCase(key)] = keysToCamel(obj[key])));
    return newObject;
  } else if (Array.isArray(obj)) {
    obj = obj.map((item) => keysToCamel(item));
  }

  return obj;
};
