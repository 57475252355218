import { createAction, props } from '@ngrx/store';
import {
  AggregationScope,
  HealthRequestMeasurementParameters,
  HealthRequestParameters,
  PaginatedResponse,
} from '@twaice-fe/shared/models';
import { KpiDistributionInterface } from 'libs/frontend/features/energy-analytics/health/src/lib/models/kpis-distribution.models';
import { ListConfigurationInterface } from '../types/list-configuration.interface';
import { FilterBarValues } from '@twaice-fe/frontend/shared/utilities';

/* Health KPIs data */
export const fetchHealthKpiData = createAction(
  '[Health/Kpi Data] fetch health KPI data',
  props<{
    requestParams: HealthRequestParameters;
    systemID: string;
    aggregationScope: AggregationScope;
    depth: number;
  }>()
);

export const loadHealthKpiDataSuccess = createAction(
  '[Health/Kpi Data] Load health KPI data success',
  props<{
    data: any;
  }>()
);

/* Health strings distribution */
export const loadHealthStringsDistributionSuccess = createAction(
  '[Health/Strings distribution] Load health string distribution Success',
  props<{ data: PaginatedResponse<KpiDistributionInterface> }>()
);

export const loadSelectedStrings = createAction(
  '[Health/Strings distribution] Load selected strings',
  props<{ ids: string[] }>()
);

export const setCustomFilter = createAction(
  '[Health/Strings distribution] Update table custom filters',
  props<{ filters: { [filterKey: string]: FilterBarValues[] } }>()
);

export const updateStringsDistributionListConfiguration = createAction(
  '[Health] Update table configuration',
  props<{ config: ListConfigurationInterface; isLoading?: boolean }>()
);

export const stringsDistributionColumnPicker = createAction(
  '[Datatable/Config] Strings distribution column picker',
  props<{ columns: string[] }>()
);

export const sortStringsDistribution = createAction(
  '[Health/ Strings distribution] Sort strings table',
  props<{ order: string }>()
);

/* Health KPI line chart data  */
export const fetchHealthKpiLineChartData = createAction(
  '[Health/Kpi Data] fetch health KPI line chart data',
  props<{
    systemID: string;
    containerID: string;
    requestParameters: HealthRequestMeasurementParameters;
  }>()
);

export const loadHealthKpiLineChartDataSuccess = createAction(
  '[Health/Kpi Data] Load health KPI line chart data success',
  props<{
    data: any;
  }>()
);
