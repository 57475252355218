import { Injectable } from '@angular/core';
import { area, Selection } from 'd3';
import { ChartLayoutService } from '../chart-helpers/chart-layout.service';
import { ChartViewInterface } from './chart-view.interface';
import { AreaViewOptions, ConfidenceIntervalData } from './models/chart-view.models';

@Injectable()
export class AreaViewService implements ChartViewInterface<ConfidenceIntervalData[], AreaViewOptions> {
  generateAreaData({ data, xScale, yScale }: { data: ConfidenceIntervalData; xScale: any; yScale: any }) {
    const getPoints = (x: number[]): [number, number][] => x.map((d, i) => [d, i]);

    const areaGenerator = area()
      .x0((d) => xScale(d[0]))
      .y0((d) => yScale(data.y0[d[1]]))
      .y1((d) => yScale(data.y1[d[1]]));

    return areaGenerator(getPoints(data.x));
  }

  draw({
    chartSVGContainer,
    chartLayoutService,
    data,
    options,
  }: {
    chartSVGContainer: Selection<SVGGElement, unknown, null, undefined>;
    chartLayoutService: ChartLayoutService;
    data: ConfidenceIntervalData[];
    options: AreaViewOptions;
  }): Selection<SVGGElement, unknown, null, undefined> {
    const { color } = options;
    const xScale = chartLayoutService.getXScale();
    const yScale = chartLayoutService.getYScale();

    const chartContent = chartSVGContainer
      .select('#chart_content')
      .append('g')
      .attr('id', 'areas')
      .attr('class', 'areas')
      .attr('data-test', 'confidence-interval')
      .attr('pointer-events', 'none');

    chartContent
      .selectAll('.area')
      .data(data)
      .enter()
      .append('path')
      .attr('id', (d, i) => `area-${i}`)
      .attr('class', 'area')
      .style('fill', (d, i) => color[i])
      .attr('d', (d) => this.generateAreaData({ data: d, xScale, yScale }));

    return chartSVGContainer;
  }

  clear(chartSVGContainer: Selection<SVGGElement, unknown, null, undefined>): void {
    chartSVGContainer.selectAll('#area').remove();
  }
}
