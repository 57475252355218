import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingStripeComponent } from './loading-stripes.component';

@NgModule({
  declarations: [LoadingStripeComponent],
  imports: [CommonModule],
  exports: [LoadingStripeComponent],
})
export class LoadingStripesModule {}
