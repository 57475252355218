import { BarChartData } from '@twaice-fe/shared/models';

export enum XAxisDirection {
  BOTTOM,
  TOP,
}

export enum YAxisDirection {
  RIGHT,
  LEFT,
}

export enum AxisType {
  X_AXIS,
  Y_AXIS,
}
export enum TickFormatOption {
  D3_DEFAULT = 'D3_DEFAULT',
  AUTO = 'AUTO',
  TWO_LiNES = 'TWO_LiNES',
}

export interface AxisInfo {
  axisDirection: XAxisDirection | YAxisDirection;
  valueRange?: number[];
  tickValues?: number[];
  tickFormat?: ((value: number, index: number) => string) | TickFormatOption;
  isAxisInTime?: boolean;
  ticksCount?: number;
  hasAxisLineDomain?: boolean;
  hasAxisGrid?: boolean;
  autoTicks?: boolean;
  // Use ticksWithPrecision=True in case of autoTicks=false and the axis should have precise calculation of ticks
  ticksWithPrecision?: boolean;
  autoLeftMargin?: number;
  innerPadding?: number;
  reduceTicks?: boolean;
}

export interface ChartDimensions {
  width?: number;
  height?: number;
  marginTop: number;
  marginRight: number;
  marginBottom: number;
  marginLeft: number;
  // Internal offsets between borders of chart container and internal elements
  axisOffsetTop?: number;
  axisOffsetBottom?: number;
  axisOffsetRight?: number;
  axisOffsetLeft?: number;
}

export interface KPIChartData {
  data: BarChartData;
  layout?: ChartLayout;
}

export interface StepKPI {
  minValue: number;
  step: number;
}

export interface StepKPIData {
  [key: string]: StepKPI;
}

export interface Legend {
  label: string;
  color?: string;
  shape: string;
}

export interface ChartLayout {
  xValueRange?: number[];
  yValueRange?: number[];
  xTickValues?: number[];
  yTickValues?: number[];
  xTickCount?: number;
  yTickCount?: number;
  chartColors?: string[];
  title?: string;
  xLabel?: string;
  yLabel?: string;
  xUnit?: string;
  yUnit?: string;
  yTickValuesPrecision?: number;
  xTickValuesPrecision?: number;
  legends?: Legend[];
  confidenceIntervalColors?: string[];
  hasSelection?: boolean;
  numberFormat?: string;
  dateFormat?: string;
  hasTooltip?: boolean;
  enableInteractionLayer?: boolean;
  tooltipTitle?: string;
  titleIcon?: string;
  // TODO: Remove this property after finding a way to handle the case of time axis
  isXAxisInTime?: boolean;
}
