import { Component, Input } from '@angular/core';

@Component({
  selector: 'twaice-fe-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @Input() lightMode: boolean; // If true, styling is adapted to light background dark text
  @Input() appVersion: string;

  currentYear: number = new Date().getFullYear();
}
