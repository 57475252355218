import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SYSTEMS_FEATURE_KEY, State, systemsAdapter } from '../reducers/systems.reducer';
import { getIncidentsAnalytics } from './incidents-detection.selectors';

// Lookup the 'Systems' feature state managed by NgRx
export const getSystemsState = createFeatureSelector<State>(SYSTEMS_FEATURE_KEY);

const { selectAll, selectEntities } = systemsAdapter.getSelectors();

export const getSystemsLoaded = createSelector(getSystemsState, (state: State) => state.isLoading);

export const getSystemsError = createSelector(getSystemsState, (state: State) => state.error);

export const getSystemList = createSelector(getSystemsState, (state: State) => selectAll(state));

export const getSystemListWithIncidents = createSelector(
  getSystemList,
  getIncidentsAnalytics,
  (systemList, incidentAnalytics) => {
    const analyticsMap = incidentAnalytics.reduce((map, obj) => {
      map[obj.systemTid] = obj;
      return map;
    }, {} as any);

    systemList = systemList.map((system) => ({
      ...system,
      incidents: analyticsMap[system.id],
    }));

    return systemList;
  }
);

export const getSystemsEntities = createSelector(getSystemsState, (state: State) => selectEntities(state));

export const getSelectedId = createSelector(getSystemsState, (state: State) => state.selectedId);

export const getSelected = createSelector(getSystemsEntities, getSelectedId, (entities, selectedId) =>
  selectedId ? entities[selectedId] : undefined
);

export const isSystemFetchLoading = createSelector(getSystemsState, (state) => state.isLoading);
