import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'twaice-fe-popover-icon',
  templateUrl: './popover-icon.component.html',
})
export class PopoverIconComponent {
  @Input() popoverContent!: TemplateRef<any>;
  @Input() customClasses: string
}
