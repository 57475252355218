import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { DataDisplayComponent } from './data-display.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [DataDisplayComponent],
  imports: [CommonModule, ClipboardModule, MatIconModule],
  providers: [DecimalPipe, DatePipe],
  exports: [DataDisplayComponent],
})
export class DataDisplayModule {}
