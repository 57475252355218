import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TxtPipe } from './txt.pipe';

@NgModule({
  declarations: [TxtPipe],
  imports: [CommonModule],
  exports: [TxtPipe],
})
export class TxtPipeModule {}
