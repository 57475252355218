<div class="filter h-auto">
  <ul class="list list-start">
    <!-- *** Search input **** -->
    <li class="search-wrapper center" *ngIf="filterConfig.length === 0 && searchPlaceholder">
      <img src="assets/icons/applications/icon_search.svg" alt="Add new" class="datatable-icon" />
      <span class="search-bar" [formGroup]="searchForm">
        <input type="text" placeholder="{{ searchPlaceholder }}" formControlName="searchTerm" class="form-control" />
      </span>
    </li>
    <li class="flex flex-wrap items-center gap-4 overflow-hidden">
      <twaice-fe-tag *ngFor="let filterItem of filterList">
        <span *ngIf="filterItem" class="flex items-center gap-4">
          {{ filterItem }}
          <button [attr.aria-label]="'remove ' + filterItem" (click)="removeFilter(filterItem)">
            <twaice-fe-svg-icon class="pt-4" name="icon_close" size="16px" color="#040F26" />
          </button>
        </span>
      </twaice-fe-tag>
    </li>
  </ul>

  <ul class="list list-end pr-0">
    <!-- output new text foreach change and gets placeholder from config -->
    <!-- ***  loading icon  isLoading input value *** -->
    <li *ngIf="isListLoading$ | async" class="center">
      <mat-spinner [diameter]="20" color="primary" mode="indeterminate"></mat-spinner>
    </li>
    <!-- ***  end loading icon  isLoading input value *** -->

    <!--  **** change table mode view from list to card *** -->
    <li *ngIf="cardEnabled" class="center pl">
      <twaice-fe-svg-icon
        name="icon_list"
        *ngIf="(tableDisplayMode$ | async) !== listModeEnum.LIST"
        (click)="changeMode(listModeEnum.LIST)"
        aria-label="List mode"
      />

      <twaice-fe-svg-icon
        name="icon_card"
        *ngIf="(tableDisplayMode$ | async) !== listModeEnum.CARD"
        (click)="changeMode(listModeEnum.CARD)"
        aria-label="Card mode"
      />
    </li>
    <!--  **** end change table mode view from list to card *** -->

    <!-- *** open modal click *** -->
    <li *ngIf="addActionBtnText" class="add-button-datatable pl" (click)="addNewAction()">
      <img src="assets/icons/applications/icon_plus.svg" alt="Add new" class="datatable-icon" />
      <span>{{ addActionBtnText }}</span>
    </li>
    <!-- *** End modal click *** -->

    <li *ngIf="!hasHeaderSection">
      <button [twMenuTriggerFor]="twMenu">
        <twaice-fe-svg-icon name="more_vert" source="material" />
      </button>

      <twaice-fe-menu #twMenu="twMenu" [closeOnSelect]="false" [formGroup]="columnSelectionForm">
        <twaice-fe-menu-item *ngFor="let column of columnKeys | keyvalue: keepOrder" [dense]="true" textAlign="start">
          <mat-checkbox class="text-left min-w-[200px]" [formControlName]="column.key">
            {{ column.value }}
          </mat-checkbox>
        </twaice-fe-menu-item>
      </twaice-fe-menu>
    </li>
  </ul>

  <!-- ***  columns$ visibility     ***-->
  <div></div>
  <!-- *** Ends columns visibility *** -->
</div>
