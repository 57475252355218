import { Injectable } from '@angular/core';
import { ScaleLinear, ScaleTime, select, Selection } from 'd3';
import { ChartAxisService } from './chart-axis.service';
import { InitializeChartUtility } from './initialize-chart-utility';
import { AxisInfo, ChartDimensions, ChartLayout } from './models/chart-utility.model';
import { formatTick } from './time-scale-utiltiy';

@Injectable()
export class ChartLayoutService {
  constructor(
    private chartUtility: InitializeChartUtility,
    private chartAxisService: ChartAxisService
  ) {}

  draw({
    chartContainer,
    chartDimensions,
    xAxisInfo,
    yAxisInfo,
    layout,
  }: {
    chartContainer: HTMLDivElement;
    chartDimensions: ChartDimensions;
    xAxisInfo: AxisInfo;
    yAxisInfo: AxisInfo;
    layout: ChartLayout;
  }): Selection<SVGGElement, unknown, null, undefined> {
    const updatedChartDimensions = { ...chartDimensions };

    if (yAxisInfo.autoLeftMargin) {
      updatedChartDimensions.marginLeft = chartDimensions.marginLeft + yAxisInfo.autoLeftMargin;
      updatedChartDimensions.axisOffsetRight = chartDimensions.axisOffsetRight - yAxisInfo.autoLeftMargin;
    }

    let chartSVGContainer = this.initChartContainer({ chartContainer, chartDimensions: updatedChartDimensions });

    if (layout.enableInteractionLayer) {
      this.initInteractionLayer(chartSVGContainer);
    }

    chartSVGContainer = this.initAxisAndGrid({
      chartSVGContainer,
      xAxisInfo,
      yAxisInfo,
    });

    this.initAxisLabels({ chartContainer, layout });

    return chartSVGContainer;
  }

  setXAxisInfo({ xAxisInfo, layout, x }: { xAxisInfo: AxisInfo; layout: ChartLayout; x: number[][] }): AxisInfo {
    let xValueRange = layout.xValueRange;

    let xTickValues = layout.xTickValues;

    if (!xValueRange) {
      const minValue = this.chartAxisService.roundNumber(Math.min(...[].concat(...x)), layout.xTickValuesPrecision, 'trunc');
      const maxValue = this.chartAxisService.roundNumber(Math.max(...[].concat(...x)), layout.xTickValuesPrecision, 'ceil');
      xValueRange = [minValue, maxValue];
    }

    if (!xAxisInfo.autoTicks && !xTickValues) {
      let minValue = xValueRange[0];
      const maxValue = xValueRange[1];

      const initialStep = (maxValue - minValue) / xAxisInfo.ticksCount;

      let step = xAxisInfo.isAxisInTime
        ? this.chartAxisService.calculateRoundedStepForTimeScale({ step: initialStep })
        : this.chartAxisService.roundNumber(initialStep, layout.xTickValuesPrecision + 1, 'ceil');

      if (xAxisInfo.isAxisInTime) {
        minValue = this.chartAxisService.calculateFirstTimestampValue({ step, firstTimestamp: minValue });

        let bufferStep = 1;

        while (minValue + step * (xAxisInfo.ticksCount - 1 + bufferStep) < maxValue) {
          bufferStep++;
        }

        step = step * bufferStep;
      }

      xTickValues = this.chartAxisService.calculateXTickValues({
        minValue,
        step,
        hasTimeAxis: xAxisInfo.isAxisInTime,
        xTickCount: xAxisInfo.ticksCount,
      });

      xValueRange = [xTickValues[0], xTickValues[xTickValues.length - 1]];
    }

    return {
      ...xAxisInfo,
      valueRange: xValueRange,
      tickValues: xTickValues,
      tickFormat:
        xAxisInfo.tickFormat || ((value) => formatTick({ value, unit: layout.xUnit, precision: layout.xTickValuesPrecision })),
    };
  }

  setYAxisInfo({ yAxisInfo, layout, y }: { yAxisInfo: AxisInfo; layout: ChartLayout; y: number[][] }): AxisInfo {
    let yValueRange = layout.yValueRange;

    let yTickValues = layout.yTickValues;

    if (!yValueRange) {
      const minValue = yAxisInfo.ticksWithPrecision ? Math.min(...[].concat(...y)) : Math.trunc(Math.min(...[].concat(...y)));

      const maxValue = Math.max(...[].concat(...y));

      yValueRange = [minValue, maxValue];
    }

    let yTickValuesPrecision = layout.yTickValuesPrecision;

    if (!yAxisInfo.autoTicks && !yTickValues) {
      const minValue = yValueRange[0];

      const maxValue = yValueRange[1];

      const initialStep = (maxValue - minValue) / yAxisInfo.ticksCount;

      const step = this.chartAxisService.roundNumber(initialStep, layout.yTickValuesPrecision);

      if (yTickValuesPrecision == null) {
        yTickValuesPrecision = this.chartAxisService.calculateRoundPositionForDecimalNumber(step / 10);
        layout.yTickValuesPrecision = yTickValuesPrecision;
      }

      yTickValues = this.chartAxisService.calculateYTickValues({
        minValue,
        step,
        yTickCount: yAxisInfo.ticksCount,
      });

      yValueRange = [yTickValues[0], yTickValues[yTickValues.length - 1]];
    }

    let autoLeftMargin = yAxisInfo.autoLeftMargin;

    if (!autoLeftMargin) {
      autoLeftMargin =
        (Math.trunc(yTickValues[yTickValues.length - 1]).toString().length +
          yTickValuesPrecision +
          (layout.yUnit?.length ? layout.yUnit.length : 0)) *
        16;
    }

    return {
      ...yAxisInfo,
      valueRange: yValueRange,
      tickValues: yTickValues,
      tickFormat: yAxisInfo.tickFormat || ((value) => formatTick({ value, unit: layout.yUnit, precision: yTickValuesPrecision })),
      autoLeftMargin,
    };
  }

  getXScale(): ScaleTime<number, number> | ScaleLinear<number, number> {
    return this.chartUtility.getXScale();
  }

  setXScale(scale: ScaleTime<number, number> | ScaleLinear<number, number>) {
    return this.chartUtility.setXScale(scale);
  }

  getYScale(): ScaleTime<number, number> | ScaleLinear<number, number> {
    return this.chartUtility.getYScale();
  }

  getChartDimensions(): ChartDimensions {
    return this.chartUtility.getChartDimensions();
  }

  getXAxisInfo(): AxisInfo {
    return this.chartUtility.getXAxisInfo();
  }

  getYAxisInfo(): AxisInfo {
    return this.chartUtility.getYAxisInfo();
  }

  clear(chartContainer: HTMLDivElement): void {
    select(chartContainer).selectAll('*').remove();
  }

  private initChartContainer({
    chartContainer,
    chartDimensions,
  }: {
    chartContainer: HTMLDivElement;
    chartDimensions: ChartDimensions;
  }): Selection<SVGGElement, unknown, null, undefined> {
    const dimensions = {
      ...chartDimensions,
      width: chartContainer.clientWidth - chartDimensions.marginLeft - chartDimensions.marginRight,
      height: chartContainer.clientHeight - chartDimensions.marginTop - chartDimensions.marginBottom,
      axisOffsetTop: chartDimensions.marginTop / 2 + chartDimensions.axisOffsetTop,
      axisOffsetBottom: chartDimensions.axisOffsetBottom,
      axisOffsetRight: chartDimensions.marginRight / 2 + chartDimensions.axisOffsetRight,
      axisOffsetLeft: chartDimensions.marginLeft / 2 + chartDimensions.axisOffsetLeft,
    };

    this.chartUtility.setChartDimensions(dimensions);

    return select(chartContainer)
      .append('svg')
      .attr('width', dimensions.width + dimensions.marginLeft + dimensions.marginRight)
      .attr('height', dimensions.height + dimensions.marginTop + dimensions.marginBottom)
      .style('display', 'block');
  }

  private initInteractionLayer(chartSVGContainer: Selection<SVGGElement, unknown, null, undefined>): void {
    const dimensions = this.getChartDimensions();

    chartSVGContainer
      .append('rect')
      .attr('id', 'interaction_layer')
      .attr('width', dimensions.width + dimensions.marginLeft + dimensions.marginRight)
      .attr('height', dimensions.height)
      .attr('transform', `translate(${dimensions.axisOffsetLeft}, 0)`)
      .style('fill', 'none')
      .style('pointer-events', 'all');
  }

  private initAxisAndGrid({
    chartSVGContainer,
    xAxisInfo,
    yAxisInfo,
  }: {
    chartSVGContainer: Selection<SVGGElement, unknown, null, undefined>;
    xAxisInfo: AxisInfo;
    yAxisInfo: AxisInfo;
  }): Selection<SVGGElement, unknown, null, undefined> {
    const chartContent = chartSVGContainer.append('g').attr('id', 'chart_content');

    this.chartUtility.setXAxisInfo(xAxisInfo);

    this.chartUtility.setXAxis();

    const xAxis = this.chartUtility.getXAxis();

    this.chartUtility.setYAxisInfo(yAxisInfo);

    this.chartUtility.setYAxis();
    const yAxis = this.chartUtility.getYAxis();

    if (xAxisInfo.hasAxisGrid) {
      this.chartUtility.setXAxisGrid();
      const xGridLines = this.chartUtility.getXAxisGrid();
      // We should append XAxisGrid before XAxis, so that axes not been overwritten
      chartContent.node().append(xGridLines.firstChild);
    }

    if (yAxisInfo.hasAxisGrid) {
      this.chartUtility.setYAxisGrid();
      const yGridLines = this.chartUtility.getYAxisGrid();
      // We should append YAxisGrid before XAxis, so that axes not been overwritten
      chartContent.node().append(yGridLines.firstChild);
    }

    chartContent.node().append(xAxis.firstChild);

    chartContent.select('#x_axis').classed('hide-domain', !xAxisInfo.hasAxisLineDomain);

    chartContent.node().append(yAxis.firstChild);

    chartContent.select('#y_axis').classed('hide-domain', !yAxisInfo.hasAxisLineDomain);

    return chartSVGContainer;
  }

  private initAxisLabels({ chartContainer, layout }: { chartContainer: HTMLDivElement; layout: ChartLayout }) {
    if (layout.xLabel) {
      const xLabel = document.createElement('div');

      xLabel.className = 'x-axis-label';

      xLabel.innerHTML = layout.xLabel;

      select(chartContainer).node().appendChild(xLabel);
    }

    if (layout.yLabel) {
      const yLabel = document.createElement('div');

      yLabel.className = 'y-axis-label';

      yLabel.innerHTML = layout.yLabel;

      select(chartContainer).node().appendChild(yLabel);
    }
  }
}
