import { Component, Input } from '@angular/core';

@Component({
  selector: 'twaice-fe-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent {
  @Input() dense = false;
  @Input() textAlign?: string;
}
