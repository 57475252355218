import { Injectable } from '@angular/core';
import { select, Selection, symbol, symbolCircle, xml } from 'd3';
import { skip, Subscription } from 'rxjs';
import { blueGray500 } from '../../base-variables';
import { ClickEventService } from '../../chart-events/click-event.service';
import { ChartLayoutService } from '../../chart-helpers/chart-layout.service';
import { ChartViewInterface } from '../chart-view.interface';
import { AnnotationData, AnnotationTextTransformFunction, AnnotationViewOptions } from '../models/chart-view.models';

@Injectable()
export class AnnotationViewService implements ChartViewInterface<AnnotationData, AnnotationViewOptions> {
  eventSubscription: Subscription;

  constructor(private clickEventService: ClickEventService<number>) {}

  draw({
    chartSVGContainer,
    chartLayoutService,
    data,
    options,
  }: {
    chartSVGContainer: Selection<SVGGElement, unknown, null, undefined>;
    chartLayoutService: ChartLayoutService;
    data: AnnotationData;
    options: AnnotationViewOptions;
  }): Selection<SVGGElement, unknown, null, undefined> {
    const { xOffset, yOffset, shape, stroke, fill, size, rotate } = {
      ...{ xOffset: 0, yOffset: 0, stroke: 'transparent', fill: blueGray500, shape: symbolCircle, size: 50, rotate: 0 },
      ...options,
    };

    const yScale = chartLayoutService.getYScale();
    const xScale = chartLayoutService.getXScale();

    const group = chartSVGContainer
      .select('#chart_content')
      .append('g')
      .attr('id', 'annotations')
      .attr('class', 'annotations')
      .attr('data-test', 'annotations')
      .selectAll('g')
      .data(data.x)
      .enter()
      .append('g')
      .attr('transform', (d, i) => `translate(${xScale(d) + xOffset}, ${yScale(data.y[i]) + yOffset}) rotate(${rotate})`);

    const path = group.append('path').attr('class', 'annotation').attr('stroke', stroke).attr('fill', fill);

    if (typeof shape === 'string') {
      xml(shape).then((svg) => {
        // d3 .bind()s the element to our callback here so we can't use an arrow function
        group.each(function () {
          this.append(svg.documentElement.cloneNode(true));
          select(this).selectAll('path').attr('stroke', stroke).attr('fill', fill);
        });
      });
    } else {
      path.attr('d', symbol().type(shape).size(size));
    }

    const text = options.text;
    if (text) {
      group
        .append('text')
        .text(
          typeof text.text === 'string'
            ? text.text
            : (d, i) => (text.text as AnnotationTextTransformFunction)({ x: d, y: data.y[i] })
        )
        .attr('fill', text.color)
        .attr('font-size', text.size || 13)
        .attr('pointer-events', 'none')
        .attr('transform', (d, i) => `translate(${text.xOffset || 0}, ${text.yOffset || 0})`);
    }

    if (options.events) {
      const { onClick } = options.events;
      this.clickEventService.attachEvent({
        chartSVGContainer: chartSVGContainer.select('#annotations'),
        selectedElement: 'path',
      });

      this.eventSubscription = this.clickEventService.getClickEvent().pipe(skip(1)).subscribe(onClick);
    }

    return chartSVGContainer;
  }

  clear(chartSVGContainer: Selection<SVGGElement, unknown, null, undefined>): void {
    chartSVGContainer.selectAll('#annotations').remove();
    this.eventSubscription?.unsubscribe();
  }
}
