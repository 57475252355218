import { AxisInfo, ChartDimensions, XAxisDirection, YAxisDirection } from '@twaice-fe/frontend/shared/utilities';

export const lineChartDimensions: ChartDimensions = {
  marginTop: 10,
  marginBottom: 25,
  marginRight: 10,
  marginLeft: 50,
  axisOffsetTop: 0,
  axisOffsetBottom: 0,
  axisOffsetRight: -40,
  axisOffsetLeft: 0,
};

export const lineChartXAxisInfo: AxisInfo = {
  axisDirection: XAxisDirection.BOTTOM,
  isAxisInTime: true,
  ticksCount: 10,
  hasAxisLineDomain: false,
  hasAxisGrid: false,
  autoTicks: true,
};

export const lineChartYAxisInfo: AxisInfo = {
  axisDirection: YAxisDirection.LEFT,
  isAxisInTime: false,
  ticksCount: 5,
  hasAxisLineDomain: false,
  hasAxisGrid: true,
  autoTicks: false,
  ticksWithPrecision: true,
};
