import { Pipe, PipeTransform } from '@angular/core';

type Unit = 'bytes' | 'KB' | 'MB' | 'GB' | 'TB' | 'PB';

@Pipe({ name: 'fileSize' })
export class FileSizePipe implements PipeTransform {
  private readonly units: Unit[] = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

  transform(bytes: number = 0): string {
    if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) return '?';

    let unitIndex = 0;

    while (bytes >= 1024) {
      bytes /= 1024;
      unitIndex++;
    }

    const unit = this.units[unitIndex];

    let precision = 2;
    if (unit === 'bytes' || unit === 'KB') {
      precision = 0;
    } else if (unit === 'MB') {
      precision = 1;
    }

    return `${bytes.toFixed(+precision)} ${unit}`;
  }
}
