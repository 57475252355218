<twaice-fe-svg-icon
  [matMenuTriggerFor]="filterMenu"
  name="filter"
  size="12px"
  (menuOpened)="resetFilters(false)"
  (menuClosed)="resetFilters(true)"
  source="tabler"
  class="cursor-pointer"
></twaice-fe-svg-icon>
<mat-menu #filterMenu="matMenu" yPosition="above" style="height: 300px">
  <ng-container [ngSwitch]="data.type" (click)="$event.stopPropagation()">
    <ng-container *ngSwitchCase="filterTypeEnum.TEXT">
      <twaice-fe-datatable-text-filter
        [filterConfig]="data"
        [resetForms$]="resetForms$"
        (emitFilterChange)="handleFilterChange($event)"
      />
    </ng-container>

    <ng-container *ngSwitchCase="filterTypeEnum.DATE">
      <twaice-fe-datatable-date-filter
        [resetForms$]="resetForms$"
        [filterConfig]="data"
        (emitFilterChange)="handleFilterChange($event)"
      />
    </ng-container>

    <ng-container *ngSwitchCase="filterTypeEnum.OPTIONS">
      <twaice-fe-datatable-options-filter
        [resetForms$]="resetForms$"
        [filterConfig]="data"
        (emitFilterChange)="handleFilterChange($event)"
      />
    </ng-container>

    <ng-container *ngSwitchCase="filterTypeEnum.SELECT">
      <twaice-fe-datatable-select-filter
       [resetForms$]="resetForms$"
       [filterConfig]="data"
       (emitFilterChange)="handleFilterChange($event)"
      />
    </ng-container>

    <ng-container *ngSwitchCase="'number'">
      <twaice-fe-datatable-number-filter
        [resetForms$]="resetForms$"
        [filterConfig]="data"
        (emitFilterChange)="handleFilterChange($event)"
      />
    </ng-container>
  </ng-container>
</mat-menu>
