import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TxtList } from '@twaice-fe/shared/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class TxtService {
  private uiTexts: BehaviorSubject<TxtList> = new BehaviorSubject<TxtList>(null);

  constructor(
    @Inject('txtUrl') txtUrl: string,
    private httpClient: HttpClient
  ) {
    if (txtUrl) {
      this.httpClient.get<TxtList>(txtUrl).subscribe((uiTexts) => this.uiTexts.next(uiTexts));
    } else {
      throw new Error('Service is used without providing url');
    }
  }

  getString(key: string): Observable<string | string[]> {
    return this.uiTexts.pipe(
      filter((uiTexts) => uiTexts != null),
      map((uiTexts) => (uiTexts[key] ? uiTexts[key].text : `key: ${key} doesn't exist in dict`))
    );
  }
}
