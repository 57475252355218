import { de_DE, en_GB, es_ES, fr_FR, nl_NL, pt_PT } from 'ng-zorro-antd/i18n';

export const getNzLocale = (locale: string) => {
  switch (locale) {
    case 'en':
      return en_GB;
    case 'fr':
      return fr_FR;
    case 'nl':
      return nl_NL;
    case 'es':
      return es_ES;
    case 'pt':
      return pt_PT;
    case 'de':
      return de_DE;
    default:
      return en_GB;
  }
};

// truncate sub-locales (e.g. de-DE, en-GB...) into their "clean" parents (e.g. de, en...)
export const cleanLocale = (locale: string) => locale.split(/-|_/g)[0];
