import { Component, Input } from '@angular/core';
import { AlertType } from './models/alert-panel.models';

@Component({
  selector: 'twaice-fe-alert-panel',
  templateUrl: './alert-panel.component.html',
  styleUrls: ['./alert-panel.component.scss'],
})
export class AlertPanelComponent {
  @Input()
  type:
    | AlertType.MISSING_CALCULATION
    | AlertType.MISSING_DATA
    | AlertType.MISSING_SENSOR
    | AlertType.MISSING_KPI
    | AlertType.INSUFFICIENT_INSIGHTS;

  @Input() title: string;

  @Input() subTitle: string;

  @Input()
  size: 's' = 's';
}
