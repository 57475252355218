<nz-range-picker
  nzFormat="MMM d, y"
  [(ngModel)]="pickerValue"
  (ngModelChange)="onDatePickerChange($event)"
  [nzRenderExtraFooter]="footerRender"
  [nzMode]="selectedTimeResolution?.value ?? 'date'"
></nz-range-picker>
<ng-template #footerRender>
  <div class="flex flex-col gap-16 px-[4px] py-16">
    <span class="text-base font-semibold">View resolution</span>
    <nz-segmented
      [nzBlock]="true"
      [nzOptions]="timeResolutionMapping"
      [(ngModel)]="selectedTimeResolutionIndex"
      (ngModelChange)="onTimeResolutionChange($event)"
    ></nz-segmented>
    <span class="text-base font-semibold">Time range</span>
    <nz-segmented
      [nzOptions]="timeRangeMapping"
      [(ngModel)]="selectedTimeRangeIndex"
      (ngModelChange)="onTimeRangeChange($event)"
    ></nz-segmented>
  </div>
</ng-template>
