import { Injectable } from '@angular/core';
import { ChartEventsInterface } from './chart-events.interface';
import { select, Selection } from 'd3';
import { EventsInfo, FilterBarValues } from './models/chart-view.models';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class BarSelectionService implements ChartEventsInterface {
  private filterBarValues: BehaviorSubject<FilterBarValues[]> = new BehaviorSubject<FilterBarValues[]>(null);
  private isCtrlPressed = false;
  private filterValues: FilterBarValues[] = [];

  setFilterBarValues(value: FilterBarValues[]): void {
    this.filterBarValues.next(value);
  }

  getFilterBarValues(): BehaviorSubject<FilterBarValues[]> {
    return this.filterBarValues;
  }

  attachAndDetectCtrlKeyEvent(): void {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && !this.isCtrlPressed) {
        this.isCtrlPressed = true;
      }
    };

    const handleKeyUp = (event) => {
      if (!event.ctrlKey && !event.metaKey) {
        this.isCtrlPressed = false;
        if (this.filterValues.length) {
          this.setFilterBarValues([...this.filterValues]);
          this.filterValues = [];
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);
  }

  attachEvent({ chartSVGContainer }: { chartSVGContainer: Selection<SVGGElement, unknown, null, undefined> }): void {
    chartSVGContainer.select('#bars').classed('enable-selection', true);
    this.attachAndDetectCtrlKeyEvent();

    const handelClickedBar = (event, eventsInfo) => {
      if (!this.isCtrlPressed && this.filterValues.length) {
        this.filterValues = [];
      }
      select(event.target).classed('selected', true);
      const currentFilter = eventsInfo.filterBarValues;

      if (!this.filterValues.some((item) => `${item.min}-${item.max}` === `${currentFilter.min}-${currentFilter.max}`)) {
        this.filterValues.push(currentFilter);

        if (!this.isCtrlPressed && this.filterValues.length) {
          this.setFilterBarValues([...this.filterValues]);
          this.filterValues = [];
        }
      }
    };

    chartSVGContainer.selectAll('rect').on('click', (event, eventsInfo: EventsInfo) => handelClickedBar(event, eventsInfo));
  }
}
