import { Injectable } from '@angular/core';
import { select, Selection } from 'd3';
import { Subscription } from 'rxjs';
import { HoverEventService } from '../chart-events/hover-event.service';
import { EventsInfo } from '../chart-events/models/chart-view.models';
import { ChartLayoutService } from '../chart-helpers/chart-layout.service';
import { ChartViewInterface } from './chart-view.interface';
import { BarChartData, BarViewOptions } from './models/chart-view.models';

@Injectable()
export class BarViewService implements ChartViewInterface<BarChartData[], BarViewOptions> {
  private eventSubscription: Subscription;

  constructor(private eventService: HoverEventService<EventsInfo>) {}

  draw({
    chartSVGContainer,
    chartLayoutService,
    data,
    options,
  }: {
    chartSVGContainer: Selection<SVGGElement, unknown, null, undefined>;
    chartLayoutService: ChartLayoutService;
    data: BarChartData[];
    options: BarViewOptions;
  }): Selection<SVGGElement, unknown, null, undefined> {
    const {
      color,
      events: { onHover },
      enableLayoutInteraction,
    } = options;

    const xScale = chartLayoutService.getXScale();

    const yScale = chartLayoutService.getYScale();

    const chartDimensions = chartLayoutService.getChartDimensions();

    const tickValues = chartLayoutService.getXAxisInfo().tickValues;

    const barWidth = xScale(tickValues[1]) - xScale(tickValues[0]);

    const chartContent = chartSVGContainer
      .select('#chart_content')
      .append('g')
      .attr('id', 'bars')
      .attr('class', 'bars')
      .attr('data-test', 'bars');

    for (let xIndex = 0; xIndex < tickValues.length - 1; xIndex++) {
      let yPosition = 0;
      if (data[0].y[xIndex] || data[data.length - 1].y[xIndex]) {
        const barGroup = chartContent.append('g');

        for (let yIndex = 0; yIndex < data.length; yIndex++) {
          const height = data[yIndex].y[xIndex];

          if (height) {
            yPosition += height;

            const eventsInfo: EventsInfo = {
              xData: data[yIndex].x[xIndex],
              yData: height,
              filterBarValues: { min: tickValues[xIndex], max: tickValues[xIndex + 1] },
            };

            barGroup
              .datum(eventsInfo)
              .append('rect')
              .style('fill', color[yIndex])
              .attr('data-test', 'color-' + (yIndex + 1))
              .attr('y', yScale(yPosition))
              .attr('height', chartDimensions.height - chartDimensions.axisOffsetBottom - yScale(height))
              .attr('width', barWidth - 2)
              .attr('x', xScale(tickValues[xIndex]) + 1);
          }
        }

        if (enableLayoutInteraction) {
          const eventsInfo: EventsInfo = {
            xData: [],
            yData: data[0].y[xIndex],
            filterBarValues: { min: tickValues[xIndex], max: tickValues[xIndex + 1] },
          };

          barGroup
            .datum(eventsInfo)
            .append('rect')
            .attr('id', 'interaction_bar')
            .attr('class', 'interaction-bar')
            .attr('y', 0)
            .attr('height', chartDimensions.height - chartDimensions.axisOffsetBottom)
            .attr('width', barWidth - 2)
            .attr('x', xScale(tickValues[xIndex]) + 1)
            .style('pointer-events', 'all');
        }
      }
    }

    if (onHover && typeof onHover === 'function') {
      this.eventService.attachEvent({
        chartSVGContainer: chartContent,
        selectedElement: enableLayoutInteraction ? '#interaction_bar' : 'rect',
      });

      this.eventSubscription = this.eventService.getHoverEvents().subscribe((event) => onHover(event));
    }

    return chartSVGContainer;
  }

  clear(chartContainer: HTMLDivElement): void {
    select(chartContainer).selectAll('#bars').remove();

    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }
}
