import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, distinctUntilChanged, of, takeUntil } from 'rxjs';
import { BaseStoreComponent } from './base-store.component';

@Component({ template: '' })
export abstract class BaseListComponent extends BaseStoreComponent implements OnInit, OnDestroy {
  @Input() elements$: Observable<unknown>;
  @Input() searchPlaceholder = '';
  @Input() scrollContainer = '.datatable';
  @Input() frontEndPagination = false
  @Input() pageSize = 10

  @Output() pageChange: EventEmitter<number> = new EventEmitter()

  reachedLastPage$: Observable<boolean>;
  isLoading$: Observable<boolean>;
  isStripLoading$: Observable<boolean>;

  frontTotalItems = 0

  limit = 20;
  page = 1;

  /**
   * filled when all the subscribers needs to be destroyed
   * @type {Subject<boolean>}
   */
  protected destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(protected store: Store) {
    super(store);
  }

  ngOnInit(): void {
    let elementLength = 1
    this.elements$.pipe(
      distinctUntilChanged(),
      takeUntil(this.destroy$))
      .subscribe((datalist: any) => {
        if (datalist?.totalCount) {
          elementLength = datalist.totalCount
          return
        }

        elementLength = (datalist as any[]).length
      })

    this.store
      .select(this.getSelector())
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.destroy$))
      .subscribe((data) => {
        this.isStripLoading$ = of(data.isLoading && data.config.totalPages >= data.config.page);
        this.isLoading$ = of(data.isLoading || data.isEfcLoading);
        const isFiltersEnabled = data.config?.searchString?.length > 0 || Object.keys(data?.customFilters ?? {}).length > 0

        this.frontTotalItems = isFiltersEnabled ? elementLength : data.ids.length

        this.page = data.config.page;
        const lastPage = data.config.page >= data.config.totalPages;
        this.reachedLastPage$ = of(lastPage);
      });
  }

  onScroll($event?) {
    this.store.dispatch(this.getConfigActions({ page: this.page + 1 }));
  }

  handleFrontPaginationPageChange(page: number) {
    this.pageChange.emit(page)
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
