import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseStoreComponent } from '../base-store.component';
import { FilterConfigType, FilterTypeEnum } from '../models/filter-config.interface';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'twaice-fe-datatable-filter',
  templateUrl: 'datatable-filter.component.html',
  styleUrls: ['./datatable-filter.component.scss'],
})
export class DatatableFilterComponent extends BaseStoreComponent {
  @Input()
  data: FilterConfigType;

  @Input()
  updateRouteFilter: boolean;

  resetForms$: Subject<boolean> = new Subject();

  filterTypeEnum = FilterTypeEnum;

  constructor(
    protected store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    super(store);
  }

  handleFilterChange(filter: Record<string, string>) {
    const validateFilterObject = () => {
      const filterObject = Object.assign({}, filter);
      Object.keys(filterObject).forEach((key: string) => {
        if (!filterObject[key]) {
          filterObject[key] = undefined;
        }
      });

      return filterObject;
    };

    if (this.updateRouteFilter) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: validateFilterObject(),
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    } else {
      this.store.dispatch(this.getFilterActions(validateFilterObject()));
    }
  }

  resetFilters(resetState: boolean) {
    this.resetForms$.next(resetState);
  }
}
