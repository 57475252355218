import { Injectable } from '@angular/core';
import { line, Selection } from 'd3';
import { GlobalEventService } from '../chart-events/global-event.service';
import { ChartLayoutService } from '../chart-helpers/chart-layout.service';
import { ChartViewInterface } from './chart-view.interface';
import { ChartData, LineViewOptions } from './models/chart-view.models';

@Injectable()
export class LineViewService implements ChartViewInterface<ChartData, LineViewOptions> {
  constructor(private eventService: GlobalEventService) {}

  draw({
    chartSVGContainer,
    chartLayoutService,
    data,
    options,
  }: {
    chartSVGContainer: Selection<SVGGElement, unknown, null, undefined>;
    chartLayoutService: ChartLayoutService;
    data: ChartData;
    options: LineViewOptions;
  }): Selection<SVGGElement, unknown, null, undefined> {
    const { id, color, style, strokeWidth } = { style: 'solid', ...options };
    const xScale = chartLayoutService.getXScale();

    const yScale = chartLayoutService.getYScale();

    data = this.sortChartData(data);

    const getPoints = (x: number[], y: number[]): [number, number][] => x.map((d, i) => [xScale(d), yScale(y[i])]);

    const lineGenerator = line()
      .x((d) => d[0])
      .y((d) => d[1]);

    const chartContent = chartSVGContainer
      .select('#chart_content')
      .append('g')
      .attr('id', 'lines')
      .attr('class', 'lines')
      .attr('data-test', 'lines');

    chartContent
      .selectAll('.line')
      .data(data.x)
      .enter()
      .append('path')
      .attr('id', (d, i) => id ?? `line-${i}`)
      .attr('class', 'line')
      .attr('pointer-events', 'none')
      .style('stroke', (d, i) => color[i])
      .attr('d', (d, i) => lineGenerator(getPoints(d, data.y[i])));

    if (style !== 'solid') {
      chartContent
        .style('stroke-dasharray', style === 'dotted' ? '0 4' : '4 2')
        .style('stroke-linecap', style === 'dotted' ? 'round' : 'none');
    }

    if (strokeWidth) {
      chartContent.selectAll('.line').style('stroke-width', strokeWidth);
    }

    if (options.events) {
      data.x.forEach((x, i) => {
        this.eventService.attachEvent({
          options: options.events,
          chartSVGContainer,
          chartLayoutService,
          data: { x, y: data.y[i] },
          selectedElement: chartSVGContainer.select('#interaction_layer').empty()
            ? id
              ? `#${id}`
              : `#line-${i}`
            : '#interaction_layer',
        });
      });
    }

    return chartSVGContainer;
  }

  clear(chartSVGContainer: Selection<SVGGElement, unknown, null, undefined>): void {
    chartSVGContainer.selectAll('#lines').remove();
  }

  // The chart data should be sorted based on timestamps in order to use the bisect function for x values.
  private sortChartData(data: ChartData): ChartData {
    const sortedData = {
      x: [],
      y: [],
    };

    data.x.forEach((x, index) => {
      const combinedData = x.map((xVal, i) => ({ x: xVal, y: data.y[index][i] }));

      combinedData.sort((a, b) => a.x - b.x);

      sortedData.x.push(combinedData.map((datum) => datum.x));

      sortedData.y.push(combinedData.map((datum) => datum.y));
    });

    return sortedData;
  }
}
