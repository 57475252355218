import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DatatableConfigInterface } from '../../datatable';
import { Router } from '@angular/router';

@Component({
  selector: 'twaice-fe-system-overview-table-card',
  templateUrl: 'system-overview-table-card.component.html',
})
export class SystemOverviewTableCardComponent implements OnInit {
  @Input() config: DatatableConfigInterface;
  @Input() columns$: Observable<string[]>;
  @Input() selected = false;
  @Input() isFleet = false;

  cardContent = {
    storageName: '',
    initialCapacity: '',
    power: '',
    totalCycles: '',
    cycleLast30Days: '',
    sohMin: '',
    sohMax: '',
    incidents: {
      tags: [],
    },
  };

  constructor(private router: Router) {}

  ngOnInit() {
    const { storage, nominalEnergyCapacity, maximumPower, totalCycles, cycleLast30Days, maxSoh, minSoh, incidents } = this.config;

    this.cardContent = {
      storageName: storage.text as string,
      initialCapacity: nominalEnergyCapacity.text as string,
      power: maximumPower.text as string,
      totalCycles: totalCycles.text as string,
      cycleLast30Days: cycleLast30Days.text as string,
      sohMax: maxSoh.text as string,
      sohMin: minSoh.text as string,
      incidents: {
        tags: incidents?.tags ?? [],
      },
    };
  }

  onEvent(event) {
    event.stopPropagation();
  }

  navigate(link: string, event) {
    event.stopPropagation();
    this.router.navigateByUrl(link);
  }
}
