<ng-container *ngIf="data.numberFormat && data.text; else textTemplate">
  <div class="style">
    <twaice-fe-data-display
      [style.color]="data.color"
      [numberFormat]="data.numberFormat"
      [measurand]="data.measurand"
      [value]="data.text"
      [sign]="data.prefix"
    />
  </div>
</ng-container>

<ng-template #textTemplate>
  <span [style.color]="data.color">{{ data.prefix || '' }} {{ data.text || '--' }}</span>
</ng-template>
