import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  EnergyPrediction,
  EnergyPredictionDisplayData,
  EnergyPredictionDisplayDataResponse,
  PredictionModel,
  TWSingleResponse,
} from '@twaice-fe/shared/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EnergyPredictionService {
  constructor(private http: HttpClient) {}

  getData(predictionModel: PredictionModel): Observable<EnergyPredictionDisplayData> {
    return this.http
      .get<EnergyPredictionDisplayDataResponse>('energy-prediction/data', {
        params: {
          systemID: predictionModel.system_id,
          sensorID: predictionModel.sensor_id,
          modelID: predictionModel.prediction_model_id,
        },
      })
      .pipe(map((response: EnergyPredictionDisplayDataResponse) => response.data));
  }

  getEnergyPredictionData(systemID: string): Observable<TWSingleResponse<EnergyPrediction>> {
    return this.http.get<TWSingleResponse<EnergyPrediction>>(`energy-prediction/systems/${systemID}/energy-prediction`);
  }
}
