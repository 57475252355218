import { DatePipe, formatNumber } from '@angular/common';
import { blueGray300, blueGray500, blueGray700, blueGray800, colorLight, colorTransparent } from '../base-variables';
import { PlotlyAnnotations } from '../plotly';

export class PredictionChartAnnotations {
  /*
   * This function returns a group of annotations for date axis, which together
   * enable us to display multiple text items grouped within the same square.
   * */
  static getDateWithLabelAnnotation(timestamp: number, label: string, background = false): PlotlyAnnotations[] {
    const datePipe = new DatePipe('en-US'); // locale doesnt matter as we define direct format
    const timestampDate = new Date(timestamp);

    const fontBase = background ? 4 : 5; // if there is no background the font should be bigger

    const dateText =
      `<span style="font-family: proxima-nova, sans-serif; font-size: ${fontBase * 3.5}px;">` +
      `${datePipe.transform(timestampDate, 'MM')}&nbsp;` +
      `</span>` +
      `<span style="font-family: proxima-nova-bold, sans-serif; font-size: ${fontBase * 4.5}px;">` +
      datePipe.transform(timestampDate, 'y') +
      `</span>`;

    return [
      /*
       * The first annotation is required to provide a centered arrow line to the text and uniform background
       * */
      {
        x: timestampDate.toISOString(),
        y: 0,
        xref: 'x',
        yref: 'paper',
        // To make sure that the border background is sized properly,
        text: `<span style="font-size: ${fontBase * 3.5}px">    ${label}    </span><br><br><br><br>${dateText}`,
        font: {
          // Font size of the label declares the line height -> small number enables us to have more control over the spacings
          size: fontBase,
          color: background ? colorLight : blueGray800,
        },
        arrowhead: 0,
        arrowwidth: 1.5,
        arrowcolor: blueGray500,
        ax: 0,
        ay: 112,
        bgcolor: background ? blueGray700 : colorTransparent,
        borderpad: 4,
      },
    ];
  }

  static getDateAnnotation(timestamp: number, displayLabel: string, border = true, fontSize = 14): PlotlyAnnotations {
    return {
      x: new Date(timestamp).toISOString(),
      y: 0,
      xref: 'x',
      yref: 'paper',
      text: displayLabel,
      font: {
        size: fontSize,
        color: blueGray700,
      },
      ax: 0,
      ay: border ? 56 : 100, // if it has no border, it should be displayed bellow the bordered items
      bgcolor: border ? blueGray300 : colorTransparent,
      borderpad: 8,

      showarrow: true,
      arrowhead: 0,
      arrowsize: 5,
      arrowwidth: 1.5,
      arrowcolor: blueGray500,
    };
  }

  /*
   * This function returns a group of annotations for y/value-axis, which together
   * enable us to display multiple text items grouped within the same square.
   * */
  static getValueWithLabelAnnotation(
    value: number,
    locale: string,
    measurand: string,
    label: string,
    precision = '1.0-0',
    background = false
  ): PlotlyAnnotations {
    const displayValue: string = formatNumber(value, locale, precision);
    const fontBase = background ? 4 : 5; // if there is no background the font should be bigger

    // Spaces in the string are not to be manipulated with as they are important for the alignment of annotation
    const dateText =
      `<span style="font-family: proxima-nova, sans-serif; font-size: ${fontBase * 3.5}px">     ${label}     </span>` +
      `<br><br><br><br>${!background ? '<br>' : ''}` +
      `<span style="font-family: proxima-nova-bold, sans-serif; font-size: ${fontBase * 4.5}px">${displayValue}</span>` +
      `<span>&nbsp;&nbsp;</span><span style="font-family: proxima-nova, sans-serif; font-size: ${
        fontBase * 3.5
      }px">${measurand}</span>`;

    return {
      x: 0,
      y: value,
      xref: 'paper',
      yref: 'y',
      text: dateText,
      font: {
        // Font size of the label declares the line height -> small number enables us to have more control over the spacings
        size: 4,
        color: background ? colorLight : blueGray800,
      },
      arrowhead: 0,
      arrowwidth: 1.5,
      arrowcolor: blueGray500,
      ax: -144,
      ay: 0,
      bgcolor: background ? blueGray700 : colorTransparent,
      borderpad: 4,
    };
  }

  /*
   * This function returns annotation, which covers the tick value that is provided
   * as value which has bigger letters then the "regular ticks"
   * */
  static getEmphasisedYValueTick(value: number, measurand: string): PlotlyAnnotations {
    const displayValue = Math.round(value) + measurand;

    return {
      x: 0,
      y: value,
      xref: 'paper',
      yref: 'y',
      text: displayValue,
      font: {
        size: 12,
        color: blueGray500,
      },
      align: 'right',
      showarrow: true,
      arrowhead: 0,
      arrowsize: 2,
      arrowwidth: 1.5,
      arrowcolor: colorTransparent,
      ax: -23,
      ay: 0,
      borderwidth: 0,
      borderpad: 4,
      bgcolor: colorLight,
      opacity: 1,
    };
  }

  static getSimpleText(value: number, timestamp: number, text: string, size = 16, color: string = colorLight): PlotlyAnnotations {
    return {
      x: new Date(timestamp).toISOString(),
      y: value,
      xref: 'x',
      yref: 'y',
      text: text,
      font: {
        size: size,
        color: color,
      },
      bgcolor: colorTransparent,
      ax: 0,
      ay: 0,
      arrowsize: 0,
    };
  }
}
