<div class="empty-solution">
  <div class="empty-solution__container">
    <img class="empty-solution__image" [src]="illustration" />
    <div class="">
      <h2 nz-typography>{{ headline }}</h2>

      <ng-content></ng-content>

      <strong nz-typography>
        @if (demoLink || downloadDemo) {
        {{ 'Want to know how it works?' | translate: { _key: 'shared.empty-solution.subheader-with-demo-link' } }}
        } @else {
        {{
          'Meanwhile, master analysing battery analytics?'
            | translate: { _key: 'shared.empty-solution.subheader-without-demo-link' }
        }}
        }
      </strong>

      <div class="empty-solution__buttons">
        <a *ngIf="!downloadDemo" class="text-sm" [href]="BATTERY_ENCYCLOPEDIA_LINK" target="_blank" rel="noopener noreferrer">
          {{ 'Open Battery Encyclopedia' | translate: { _key: 'shared.empty-solution.encyclopedia-link-label' } }}
        </a>
        <button *ngIf="demoLink" nz-button nzType="primary" [twDemoLink]="demoLink">
          {{ 'View Demo' | translate: { _key: 'shared.empty-solution.demo-button-label' } }}
        </button>

        <a *ngIf="downloadDemo" class="text-sm" [href]="DEMO_DOWNLOAD_LINK" target="_blank" rel="noopener noreferrer">
          {{ 'Download Demo Model' | translate: { _key: 'shared.empty-solution.download-demo-button-label' } }}
        </a>

        <ng-content select="button,a"></ng-content>
      </div>
    </div>
  </div>
</div>
