import { Injectable } from '@angular/core';
import { ChartLayout } from './models/chart-utility.model';
import { TimeInterval } from '@twaice-fe/shared/utilities';
import { ChartAxisService } from './chart-axis.service';

@Injectable()
export class BarChartDataProcessing {
  layout: ChartLayout;

  constructor(private chartAxisService: ChartAxisService) {}

  /**
   * Get as input number[]
   * Get as output input chunked into sub-arrays: number[][]
   *
   * @param kpiData KPI data to be chunked into sub-arrays
   * @param hasTimeAxis Time axis needs different processing then linear axis
   * @param minValue The chunking in calculated by calculating the minValue
   * @param step And we chunk data based on ( kpi values < minValue + step * indexChunk )
   * @param tickCount
   */
  splitListIntoChunks({
    kpiData,
    hasTimeAxis,
    minValue,
    step,
    tickCount,
  }: {
    kpiData: number[];
    hasTimeAxis: boolean;
    minValue: number;
    step: number;
    tickCount: number;
  }): number[][] {
    if (!kpiData) return [];

    const chunksValuesList = [];

    let chunkValues = [];

    let indexKPI = 0;

    let indexChunk = 1;

    while (indexKPI < kpiData.length && indexChunk !== tickCount + 1) {
      let compareValue = minValue + step * indexChunk;

      if (hasTimeAxis && this.chartAxisService.getIsStepInYear()) {
        while (new Date(compareValue).getMonth() !== 0) {
          compareValue += TimeInterval.DAY;
        }
      }

      if (kpiData[indexKPI] < compareValue) {
        chunkValues.push(kpiData[indexKPI]);

        if (indexKPI === kpiData.length - 1) {
          chunksValuesList.push(chunkValues);
          indexChunk += 1;
        }

        indexKPI += 1;
      } else {
        chunksValuesList.push(chunkValues);
        indexChunk += 1;
        chunkValues = [];
      }
    }

    while (chunksValuesList.length !== tickCount) {
      chunksValuesList.push([]);
    }

    return chunksValuesList;
  }
}
