import {
  AxisInfo,
  ChartDimensions,
  ChartLayout,
  TickFormatOption,
  XAxisDirection,
  YAxisDirection,
} from '@twaice-fe/frontend/shared/utilities';

export const xPadding = 2;
export const yPadding = 0;

export const stackedBarChartDimensions: ChartDimensions = {
  marginTop: 64,
  marginBottom: -64,
  marginRight: 0,
  marginLeft: 0,
  axisOffsetTop: 24,
  axisOffsetBottom: 0,
  axisOffsetRight: 0,
  axisOffsetLeft: 0,
};

export const stackedBarChartXAxisInfo: AxisInfo = {
  axisDirection: XAxisDirection.TOP,
  isAxisInTime: true,
  hasAxisLineDomain: false,
  hasAxisGrid: false,
  innerPadding: xPadding,
  tickFormat: TickFormatOption.TWO_LiNES,
};

export const stackedBarChartYAxisInfo: AxisInfo = {
  axisDirection: YAxisDirection.LEFT,
  ticksCount: 4,
  hasAxisLineDomain: false,
  hasAxisGrid: false,
  autoTicks: true,
  autoLeftMargin: 1,
  innerPadding: yPadding,
};

export const initialStackedBarChartLayout: ChartLayout = {
  yTickValuesPrecision: 0,
  hasSelection: true,
  hasTooltip: true,
};
