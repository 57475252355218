import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MOBILITY_BASE_ROUTE, MOBILITY_DEPRECATED_BASE_ROUTE, SKIP_BE4FE_HEADER } from '@twaice-fe/shared/constants';
import {
  IncidentComponentOverview,
  IncidentDelaysInterface,
  IncidentInterface,
  IncidentRulesThresholdInterface,
  IncidentStorageOverview,
  IncidentStorageOverviewParams,
  IncidentsAnalyticsInterface,
  System,
  TwResponse,
  TWSingleResponse,
  MailConfiguration,
  IncidentComponentOverviewParams,
} from '@twaice-fe/shared/models';
import { keysToCamel, keysToSnake, normalizeQueryParams } from '@twaice-fe/shared/utilities';
import { BehaviorSubject, map, switchMap } from 'rxjs';
import { SystemsService } from './systems.service';

@Injectable({
  providedIn: 'root',
})
export class IncidentsDetectionService {
  selectedSystem$: BehaviorSubject<System> = new BehaviorSubject(null);

  constructor(private httpClient: HttpClient, private systemService: SystemsService) {}

  // Setter for systemId
  setSelectSystem(system: System): void {
    this.selectedSystem$.next(system);
  }

  // Getter for systemId
  getSelectSystem(): BehaviorSubject<System> {
    return this.selectedSystem$;
  }

  fetchIncidents(params?: HttpParams) {
    // TODO(CS-428): get rid of custom logic for fleet here once all systems are migrated to new CCS
    const isFleet =
      window.location.pathname.includes(MOBILITY_BASE_ROUTE) || window.location.pathname.includes(MOBILITY_DEPRECATED_BASE_ROUTE);

    if (params.get('component_bk') && isFleet) {
      return this.systemService.getCurrentSystem().pipe(
        switchMap((system) =>
          this.httpClient.get<TwResponse<IncidentInterface>>(
            `widget/fleet/systems/${system.id}/vehicles/${params.get('component_bk')}/incidents`,
            { params: normalizeQueryParams(params.keys().reduce((obj, key) => ({ ...obj, [key]: params.get(key) }), {})) }
          )
        ),
        map((response) => new TwResponse<IncidentInterface>(response))
      );
    }

    return this.httpClient.get<TwResponse<IncidentInterface>>('incidents', { params });
  }

  fetchIncidentsAnalytics(params?: HttpParams) {
    return this.httpClient.get<TwResponse<IncidentsAnalyticsInterface>>('incidents/analytics', { params });
  }

  fetchSingleIncident({
    customerBk,
    systemBk,
    ruleBk,
    sensorBk,
    startTime,
    ruleTypeBk,
    includeData = false,
  }: {
    customerBk: string;
    systemBk: string;
    ruleTypeBk: string;
    ruleBk: string;
    sensorBk: string;
    startTime: string;
    includeData?: boolean;
  }) {
    return this.httpClient.get<{ data: IncidentInterface }>(
      `incidents/${customerBk}/${systemBk}/${ruleTypeBk}/${ruleBk}/${encodeURIComponent(sensorBk)}/${startTime}`,
      { params: { includeData } }
    );
  }

  fetchIncidentThresholdRules(params?: HttpParams) {
    return this.httpClient.get<TwResponse<IncidentRulesThresholdInterface>>(`rules/thresholds`, { params });
  }

  fetchIncidentsDelays(systemID: string, params?: HttpParams) {
    return this.httpClient.get<TwResponse<IncidentDelaysInterface>>(`incidents/${systemID}/delays`, { params });
  }

  fetchStorageOverview({ customerBk, systemBk, timeRangeStart, timeRangeEnd }: IncidentStorageOverviewParams) {
    return this.httpClient
      .get<{ data: IncidentStorageOverview }>(`monitor/storage-overview`, {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        params: { customer_bk: customerBk, system_bk: systemBk, time_range_start: timeRangeStart, time_range_end: timeRangeEnd },
        headers: {
          [SKIP_BE4FE_HEADER]: '1',
        },
      })
      .pipe(map((response) => keysToCamel(response)));
  }

  fetchNodeOverview(params: IncidentComponentOverviewParams) {
    return this.httpClient
      .get<{ data: IncidentComponentOverview }>(`monitor/component-overview`, {
        params: { ...keysToSnake(params) },
        headers: {
          [SKIP_BE4FE_HEADER]: '1',
        },
      })
      .pipe(map((response) => keysToCamel(response)));
  }

  fetchMailConfiguration({ customerBk, systemBk }: { customerBk: string; systemBk: string }) {
    return this.httpClient.get<TWSingleResponse<MailConfiguration>>(`monitor/notifications/mail`, {
      params: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        customer_bk: customerBk,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        system_bk: systemBk,
      },
      headers: {
        [SKIP_BE4FE_HEADER]: '1',
      },
    });
  }

  updateMailConfiguration({
    customerBk,
    systemBk,
    mailConfig,
  }: {
    customerBk: string;
    systemBk: string;
    mailConfig: MailConfiguration;
  }) {
    return this.httpClient.put<TWSingleResponse<MailConfiguration>>(`monitor/notifications/mail`, mailConfig, {
      params: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        customer_bk: customerBk,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        system_bk: systemBk,
      },
      headers: {
        [SKIP_BE4FE_HEADER]: '1',
      },
    });
  }
}
