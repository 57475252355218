<ng-template [ngIf]="template === 'systems'">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        {{ content.name.header }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p>{{ content.name.text }}</p>
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template [ngIf]="template === 'loading'">
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>
        <twaice-fe-loading-stripe size="sm"></twaice-fe-loading-stripe>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="flex items-center justify-between">
        <twaice-fe-loading-stripe size="md"></twaice-fe-loading-stripe>
        <twaice-fe-loading-stripe size="md"></twaice-fe-loading-stripe>
        <twaice-fe-loading-stripe size="md"></twaice-fe-loading-stripe>
      </div>
      <div class="flex items-center justify-between">
        <twaice-fe-loading-stripe size="lg"></twaice-fe-loading-stripe>
        <twaice-fe-loading-stripe size="lg"></twaice-fe-loading-stripe>
      </div>
      <div class="flex items-center justify-between">
        <twaice-fe-loading-stripe size="lg"></twaice-fe-loading-stripe>
        <twaice-fe-loading-stripe size="lg"></twaice-fe-loading-stripe>
      </div>
    </mat-card-content>
  </mat-card>
</ng-template>
