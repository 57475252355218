import { Component, Input } from '@angular/core';
import { CellConfigInterface } from '../../models/cell-config.interface';

@Component({
  selector: 'twaice-fe-datatable-tooltip-cell',
  templateUrl: 'tooltip-cell.component.html',
})
export class TooltipCellComponent {
  @Input() data: CellConfigInterface;

  constructor() {}
}
