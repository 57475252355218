<div class="tw-alert-panel">
  <div class="tw-alert-panel__icon tw-alert-panel__icon--{{ size }}" [ngSwitch]="type">
    <mat-icon *ngSwitchCase="0" svgIcon="icon_missing_calculation"></mat-icon>
    <mat-icon *ngSwitchCase="1" svgIcon="icon_missing_data"></mat-icon>
    <mat-icon *ngSwitchCase="2" svgIcon="icon_missing_sensor"></mat-icon>
    <mat-icon *ngSwitchCase="3" svgIcon="icon_missing_kpi"></mat-icon>
    <mat-icon *ngSwitchCase="4" svgIcon="icon_missing_insights"></mat-icon>
  </div>
  <div class="tw-alert-panel__title tw-alert-panel__title--{{ size }}">{{ title }}</div>
  <div class="tw-alert-panel__subtitle tw-alert-panel__subtitle--{{ size }}">{{ subTitle }}</div>
</div>
