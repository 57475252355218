import { Component, Input } from '@angular/core';
import { CellConfigInterface } from '../../models/cell-config.interface';

@Component({
  selector: 'twaice-fe-icon-link',
  templateUrl: 'icon-link-cell.component.html',
})
export class IconLinkCellComponent {
  @Input() dense: boolean;
  @Input() data: CellConfigInterface;

  constructor() {}
}
