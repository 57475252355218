<div class="incident-chart">
  <div class="incident-chart__legend-container">
    <div *ngIf="extendedLegend" class="incident-chart__legend">
      <div class="incident-chart__legend-dotted-line"></div>
      Stable
      <div class="incident-chart__legend-line"></div>
      Incident
    </div>
    <div *ngIf="!extendedLegend" class="incident-chart__legend">
      <div class="incident-chart__legend-line"></div>
      {{ incident?.sensorName }}
    </div>
  </div>
  <div class="incident-chart__container">
    <div class="incident-chart__y-axis-label">{{ incident?.sensorName }} / {{ incidentUnit || '-' }}</div>
    <div class="incident-chart__svg-container" #lineChart></div>
    <div class="line-chart__tooltip" #tooltip>
      <div class="line-chart__tooltip--column">
        <span class="dot" [style]="{ '--legend-color': tooltipContent?.color }"></span>
      </div>
      <div *ngIf="tooltipContent?.content" class="line-chart__tooltip--column">
        <div *ngFor="let row of tooltipContent.content; let rowIndex = index" class="line-chart__tooltip--row">
          <span [ngClass]="{ value: rowIndex === 0 }" *ngFor="let column of row"> {{ column }} </span>
        </div>
      </div>
    </div>
  </div>
</div>
