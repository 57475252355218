import { Component, Input } from '@angular/core';
import { CellConfigInterface } from '../../models/cell-config.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'twaice-fe-tag-cell-component',
  templateUrl: 'tag-cell.component.html',
})
export class TagCellComponent {
  @Input() data: CellConfigInterface;

  constructor(private router: Router) {}

  navigate(link: string) {
    this.router.navigateByUrl(link);
  }

  onEvent(event) {
    event.stopPropagation();
  }
}
