<div class="health-bar-chart-wrapper">
  <div [class.loaded]="!isLoading && !alert" class="health-bar-chart" #barChart data-test="bar-chart"></div>
  <ng-container *ngIf="isLoading && !alert">
    <twaice-fe-loading-tips data-test="data-loading" tipSet="ENERGY_HEALTH" [overlay]="true" />
  </ng-container>

  <twaice-fe-alert-panel
    *ngIf="alert"
    [type]="alert.type"
    [title]="alert.title"
    [subTitle]="alert.subTitle"
    data-test="alert-panel"
  ></twaice-fe-alert-panel>
</div>
