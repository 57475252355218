import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, debounceTime, distinctUntilChanged, filter, map } from 'rxjs';
import { TextFilterInterface } from '../../models/filter-config.interface';

@Component({
  selector: 'twaice-fe-datatable-text-filter',
  templateUrl: 'datatable-text-filter.component.html',
  styleUrls: ['./datatable-text-filter.component.scss'],
})
export class DatatableTextFilterComponent implements OnInit {
  textInputControl = new FormControl('');

  @Input() filterConfig: TextFilterInterface;
  @Input() resetForms$: Subject<boolean>;
  @Output() emitFilterChange: EventEmitter<Record<string, unknown>> = new EventEmitter();

  ngOnInit() {
    this.textInputControl.valueChanges
      .pipe(
        debounceTime(500),
        filter((text) => !!text),
        map((text) => text.trim()),
        distinctUntilChanged()
      )
      .subscribe((text: string) => {
        this.emitFilterChange.emit({ [this.filterConfig.filterBy]: text });
      });

    this.resetForms$.pipe(distinctUntilChanged()).subscribe((val) => val && this.textInputControl.reset());
  }
}
