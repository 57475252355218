import { Injectable } from '@angular/core';
import { TimeInterval } from '@twaice-fe/shared/utilities';

@Injectable()
export class ChartAxisService {
  private isStepInYear = false;

  getIsStepInYear(): boolean {
    return this.isStepInYear;
  }

  calculateRoundPositionForDecimalNumber(value: number): number {
    const digitsAfterComma = value.toString().split('.')[1];

    let roundDecimalTo = 1;

    if (digitsAfterComma) {
      for (const digit of digitsAfterComma) {
        if (digit !== '0') break;

        roundDecimalTo++;
      }
    }

    return roundDecimalTo;
  }

  roundNumber(number: number, precision: number = 0, roundType: 'trunc' | 'ceil' = 'ceil'): number {
    const roundingFactor = precision ? Math.pow(10, precision) : 1;
    const roundedValue =
      roundType === 'trunc'
        ? Math.trunc((number + Number.EPSILON) * roundingFactor)
        : Math.ceil((number + Number.EPSILON) * roundingFactor);
    return roundedValue / roundingFactor;
  }

  calculateRoundedStepForTimeScale({ step }: { step: number }): number {
    if (step <= TimeInterval.SECOND) {
      return TimeInterval.SECOND;
    } else if (step <= TimeInterval.MINUTE) {
      return TimeInterval.MINUTE;
    } else if (step <= TimeInterval.HOUR) {
      return TimeInterval.HOUR;
    } else if (step <= TimeInterval.DAY) {
      return TimeInterval.DAY;
    } else if (step <= TimeInterval.ONE_WEEK) {
      return TimeInterval.ONE_WEEK;
    } else if (step <= TimeInterval.THREE_MONTHS) {
      return TimeInterval.THREE_MONTHS;
    } else if (step <= TimeInterval.SIX_MONTHS) {
      return TimeInterval.SIX_MONTHS;
    } else {
      return TimeInterval.ONE_YEAR;
    }
  }

  calculateFirstTimestampValue({ step, firstTimestamp }: { step: number; firstTimestamp: number }): number {
    if (step !== TimeInterval.ONE_YEAR) {
      this.isStepInYear = false;

      return firstTimestamp;
    }

    this.isStepInYear = true;

    return new Date(new Date(firstTimestamp).getFullYear().toString()).getTime();
  }

  calculateXTickValues({
    minValue,
    step,
    hasTimeAxis,
    xTickCount,
  }: {
    minValue: number;
    step: number;
    hasTimeAxis: boolean;
    xTickCount: number;
  }): number[] {
    const tickValues = [];

    for (let i = 0; i < xTickCount + 1; i++) {
      let compareValue = minValue + step * i;

      if (hasTimeAxis && this.isStepInYear) {
        while (new Date(compareValue).getMonth() !== 0) {
          compareValue += TimeInterval.DAY;
        }
      }

      tickValues.push(compareValue);
    }

    return tickValues;
  }

  calculateYTickValues({ minValue, step, yTickCount }: { minValue: number; step: number; yTickCount: number }): number[] {
    const tickValues = [];

    for (let i = 0; i < yTickCount + 1; i++) {
      tickValues.push(minValue + step * i);
    }

    return tickValues;
  }
}
