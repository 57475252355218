import { Action, ActionReducer } from '@ngrx/store';
import { authActions, configsAction } from '../actions';

export function clearStateMetaReducer<S, A extends Action = Action>(reducer: ActionReducer<S, A>) {
  return function (state: S, action: A): S {
    if (action.type === authActions.logout().type) {
      return reducer(undefined, action);
    }

    if (action.type === configsAction.internalUIConfigChanged().type) {
      return reducer(undefined, action);
    }

    return reducer(state, action);
  };
}
