import { createAction, props } from '@ngrx/store';
import { FeWarrantyMetadata, PaginatedResponse, WarrantyTrackerInterface } from '@twaice-fe/shared/models';
import { ListConfigurationInterface } from '../types/list-configuration.interface';

export const fetchWarrantyData = createAction(
  '[Warranty Tracker] Fetch warranty data',
  props<{ systemID: string; levelDepth: number; warrantyReferenceMetadata: FeWarrantyMetadata }>()
);

export const loadWarrantyDataSuccess = createAction(
  '[Warranty Tracker/API] Load Warranty Data Success',
  props<{ data: PaginatedResponse<WarrantyTrackerInterface> }>()
);

export const updateWarrantyListConfiguration = createAction(
  '[Warranty Tracker] Update table configuration',
  props<{ config: ListConfigurationInterface; isLoading?: boolean }>()
);

export const loadWarrantyFailure = createAction('[Warranty Tracker/API] Load Warranty Data Failure', props<{ error: any }>());

export const cancelWarrantyDataRequest = createAction('[Warranty Tracker/API] Cancel warranty data request');

export const warrantyListColumnPicker = createAction(
  '[Datatable/Config] Warranty data list overview column picker',
  props<{ columns: string[] }>()
);

export const sortWarrantyTracker = createAction('[Datatable/Config] Sort warranty tracker list', props<{ order: string }>());
