import { DatePipe } from '@angular/common';
import { SystemStatisticsData } from '@twaice-fe/shared/models';
import { balancingStackedBarInfo } from 'libs/frontend/features/performance-manager/src/lib/performance-manager.config';
import { BarSeries, EChartProgressOption } from '../datatable/models/progress-bar-cell.interface';
import {severityCritical, severityMedium, severityNormal} from "@twaice-fe/frontend/shared/utilities";

interface StatsValuesInterface {
  high: number;
  medium: number;
  balanced: number;
  missing: number;
}

export interface ColumnItem<T> {
  name: string;
  tooltip?: {
    content: string,
    link?: string,
    linkText?: string
  };
  class?: string;
  width?: string
}

export interface SystemOverviewAntTableInterface {
  metadata: {
    id: string;
    name: string;
    location: string;
    nominalEnergyCapacity: string;
    maximumPower: string;
    efcSum: number;
  };
  performance?: StatsValuesConfigInterface;
  health?: StatsValuesConfigInterface;
  safetyScore?: StatsValuesConfigInterface;
}

export interface StatsValuesConfigInterface {
  label: string;
  color: string;
  value?: number;
  icon?: string;
  link?: string;
}

export const statsValuesConfig: { [key in keyof StatsValuesInterface]: StatsValuesConfigInterface } = {
  high: { label: 'of nodes with imbalance issues', color: severityCritical },
  medium: { label: 'of nodes with imbalance issues', color: severityMedium },
  balanced: { label: 'Normal', color: severityNormal },
  missing: { label: 'Onboarding in Progress', color: '#B4B7BE', icon: 'settings' },
};

export const healthValuesConfig: { [key in string]: StatsValuesConfigInterface } = {
  high: { label: '--', color: severityCritical },
  medium: { label: '--', color: severityMedium },
  balanced: { label: 'Normal', color: severityNormal },
  missing: { label: 'Onboarding in Progress', color: '#B4B7BE', icon: 'settings' },
};

export const safetyScoreValuesConfig: { [key in keyof StatsValuesInterface]: StatsValuesConfigInterface } = {
  high: { label: "Critical", color: severityCritical },
  medium: { label: "Warning", color: severityMedium },
  balanced: { label: 'Normal', color: severityNormal },
  missing: { label: 'Onboarding in Progress', color: '#B4B7BE', icon: 'settings' },
};

/** Creates a gap bar for visual separation in bar charts. */
export const createGap = (): BarSeries => ({
  type: 'bar',
  data: [1],
  barWidth: '50%',
  itemStyle: { color: '#ffffff' },
  stack: 'total',
});

/** Creates a bar series for a specific severity level with given value. */
export const createBar = (severity: string, value: number): BarSeries => ({
  type: 'bar',
  data: [value],
  barWidth: '50%',
  itemStyle: {
    color: balancingStackedBarInfo[severity].color,
    barBorderRadius: severity === 'high' || severity === 'missing' ? [2, 0, 0, 2] : [0, 2, 2, 0],
  },
  stack: 'total',
});

/** Returns configuration for the progress bar chart with given statistics. */
export const getProgressBarOptions = (formattedStats: StatsValuesInterface): EChartProgressOption => ({
  series: [
    createBar('high', formattedStats.high),
    createGap(),
    createBar('medium', formattedStats.medium),
    createGap(),
    createBar('balanced', formattedStats.balanced),
    createGap(),
    createBar('missing', formattedStats.missing),
  ],
  grid: { top: 0, bottom: 0, left: 0, right: 0 },
  xAxis: { type: 'value', show: false, max: 103 },
  yAxis: { type: 'category', show: false, data: [''] },
});

/** Calculates percentage statistics for the different severity levels. */
export const formatSystemStats = (values = { high: 0, medium: 0, balanced: 0, missing: 0 }): StatsValuesInterface => {
  const totalCount = Object.values(values).reduce((sum, num) => sum + (num ?? 0), 0);
  return {
    high: calculatePercentage(values.high, totalCount),
    medium: calculatePercentage(values.medium, totalCount),
    balanced: calculatePercentage(values.balanced, totalCount),
    missing: calculatePercentage(values.missing, totalCount),
  };
};

export const calculatePercentage = (value: number, total: number): number => (total > 0 ? Math.round((value * 100) / total) : 0);

/** Generates HTML string for tooltip based on system statistics and a provided date. */
export const createTooltipText = (datePipe: DatePipe, systemStatistics: SystemStatisticsData): string => {
  let tooltipText = '';
  if (!systemStatistics?.y) return tooltipText;

  const formattedDate = datePipe.transform(systemStatistics.x * 1000, 'MMMM dd, yyyy') || '-';
  tooltipText += `<div class="flex items-start flex-col"><div class="text-base font-semibold">${formattedDate}</div>`;

  Object.keys(balancingStackedBarInfo)?.forEach((severity) => {
    tooltipText += `
      <div class="flex items-center gap-16">
        <div style="background: ${balancingStackedBarInfo[severity].color}" class="w-8 h-8"></div>
        <div class="font-normal text-sm leading-base text-tertiary-text ml-2">${balancingStackedBarInfo[severity].label}</div>
        <div class="text-base font-semibold">${systemStatistics.y[severity] ?? 0} nodes</div>
      </div>`;
  });
  tooltipText += '</div>';
  return tooltipText;
};
