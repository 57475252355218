/* const t = {
  storagePredictionModel,
  rackPredictionModelList,
  metadata: {
    cycle_count
    eol
    end_of_life_soh_value
    eol_timestamps
    cycle_count_eol
    soh
  },
  healthKpiList
};
 */

import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  EnergyPrediction,
  MetadataInterface,
  PredictionHealthKpiListItem,
  StoragePredictionDataInterface,
  StoragePredictionModelInterface,
} from '@twaice-fe/shared/models';

import { ListConfigurationInterface } from '../types/list-configuration.interface';
import { EnergyPredictionActions } from '../actions';
import { FilterBarValues } from '@twaice-fe/frontend/shared/utilities';

export const ENERGY_PREDICTION_FEATURE_KEY = 'energy-prediction';

export interface PredictionHealthKpiListState extends EntityState<PredictionHealthKpiListItem> {
  filter: {
    [key: string]: FilterBarValues[];
  };
  config: ListConfigurationInterface;
  selected?: string;
  isLoading: boolean;
  error?: string | null;
}

export interface State {
  storagePredictionModel: StoragePredictionModelInterface | Record<string, unknown>;
  rackPredictionModelList: StoragePredictionModelInterface[] | Record<string, string>[];
  storagePredictionData: StoragePredictionDataInterface | Record<string, unknown>;
  healthKpiList: PredictionHealthKpiListState;
  metadata: MetadataInterface | Record<string, unknown>;

  isLoading: boolean;
  error?: string | null;
}

export const EnergyPredictionAdapter: EntityAdapter<EnergyPrediction> = createEntityAdapter<EnergyPrediction>();

export const HealthKpiListAdapter: EntityAdapter<PredictionHealthKpiListItem> = createEntityAdapter<PredictionHealthKpiListItem>({
  selectId: (model) => model.container_id,
});

export const initialHealthKpiListState = HealthKpiListAdapter.getInitialState({
  filter: {},
  config: {
    limit: 500,
    page: 1,
    totalPages: 1,
  },
  isLoading: false,
});

export const initialState: State = {
  healthKpiList: initialHealthKpiListState,
  storagePredictionData: {},
  storagePredictionModel: {},
  rackPredictionModelList: [],
  metadata: {},
  isLoading: false,
};

const energyPredictionReducer = createReducer(
  initialState,
  on(EnergyPredictionActions.fetchEnergyPredictionData, (state) => ({
    ...state,
    isLoading: true,
    healthKpiList: { ...state.healthKpiList, isLoading: true },
  })),
  on(EnergyPredictionActions.loadEnergyPredictionDataSuccess, (state, { data: { healthKpiList, ...rest } }) => ({
    ...state,
    ...rest,
    isLoading: false,
    healthKpiList: HealthKpiListAdapter.setAll(healthKpiList, {
      ...state.healthKpiList,
      config: {
        ...state.healthKpiList.config,
        page: 1,
        limit: 500,
        totalPages: 1,
        totalItems: healthKpiList.length,
      },
      isLoading: false,
    }),
  })),
  on(EnergyPredictionActions.updatePredictionHealthKpiListConfiguration, (state, { config, isLoading }) => ({
    ...state,
    healthKpiList: {
      ...state.healthKpiList,
      isLoading: isLoading ?? false,
      config: { ...state.healthKpiList.config, ...config },
    },
  })),
  on(EnergyPredictionActions.predictionHealthKpiColumnPicker, (state, { columns }) => ({
    ...state,
    healthKpiList: {
      ...state.healthKpiList,
      config: {
        ...state.healthKpiList.config,
        columns,
      },
    },
  })),
  on(EnergyPredictionActions.filterPredictionHealthKpiList, (state, { filter }) => ({
    ...state,
    healthKpiList: {
      ...state.healthKpiList,
      filter,
    },
  })),
  on(EnergyPredictionActions.selectPredictionContainer, (state, { rackID }) => ({
    ...state,
    healthKpiList: {
      ...state.healthKpiList,
      selected: rackID,
    },
  })),
  on(EnergyPredictionActions.loadEnergyPredictionFailure, (state, { error }) => ({
    ...state,
    ...initialState,
    error: error,
    isLoading: false,
  })),
  on(EnergyPredictionActions.sortHealthPredictionList, (state, { order }) => ({
    ...state,
    healthKpiList: {
      ...state.healthKpiList,
      config: {
        ...state.healthKpiList.config,
        order,
      },
    },
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return energyPredictionReducer(state, action);
}
