import { ConfidenceIntervalData } from '@twaice-fe/frontend/shared/utilities';

// The confidence interval data should be sorted based on timestamps in order to use the bisect function for x values.
export const sortConfidenceIntervalData = (data: ConfidenceIntervalData): ConfidenceIntervalData => {
  const sortedData = {
    x: [],
    y0: [],
    y1: [],
  };

  const combinedData = data.x.map((xVal, i) => ({ x: xVal, y0: data.y0[i], y1: data.y1[i] }));

  combinedData.sort((a, b) => a.x - b.x);

  combinedData.forEach((datum) => {
    sortedData.x.push(datum.x);
    sortedData.y0.push(datum.y0);
    sortedData.y1.push(datum.y1);
  });

  return sortedData;
};
