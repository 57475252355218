import { Injectable } from '@angular/core';
import { select, selectAll, Selection } from 'd3';
import { BehaviorSubject, Observable } from 'rxjs';
import { ChartEventsInterface } from './chart-events.interface';
import { ClickEvent } from './models/chart-view.models';

@Injectable()
export class ClickEventService<DataType> implements ChartEventsInterface {
  private click$: BehaviorSubject<ClickEvent<DataType>> = new BehaviorSubject<ClickEvent<DataType>>(null);

  getClickEvent(): Observable<ClickEvent<DataType>> {
    return this.click$.asObservable();
  }

  attachEvent({
    chartSVGContainer,
    selectedElement,
  }: {
    chartSVGContainer: Selection<SVGGElement, unknown, null, undefined>;
    selectedElement: string;
  }): void {
    chartSVGContainer.selectAll(selectedElement).on('click', (event, eventsInfo: DataType) => {
      selectAll(selectedElement).classed('selected', false);
      select(event.target).classed('selected', true);
      this.emitClickEvent({ data: eventsInfo, cell: select(event.currentTarget) });
    });
  }

  private emitClickEvent({
    data,
    cell,
  }: {
    data?: DataType;
    cell?: Selection<SVGGElement, DataType, SVGElement, unknown>;
  }): void {
    this.click$.next({ data: <DataType>(<unknown>data), cell });
  }
}
