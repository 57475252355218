import { TimeLength } from './time-length';

function diffDays(startDate, endDate) {
  const diffMs = Math.abs(endDate - startDate);
  return Math.ceil(diffMs / TimeLength.DAY);
}

/* This measurements domain specific function returns true, if the data is available in the hot storage */
export function dataAvailableInHotStorage(startTimestamp: number, endTimestamp: number): boolean {
  const currTimestamp = Date.now();

  // raw data is available only if we have less then 6 days of data
  // and it was less then 14 days of the start data point
  return diffDays(startTimestamp, endTimestamp) < 6 && diffDays(startTimestamp, currTimestamp) < 14;
}

/**
 * Function that returns true when the provided string is in a proper date format
 * stringDate: string - string that we are checking for the correctness of the format
 *
 * @returns {boolean} - true when the provided string is in a proper date format
 */
export function isValidStringDate(stringDate: string): boolean {
  const date = new Date(stringDate);
  return !isNaN(date.getTime());
}
