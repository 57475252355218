<div class="w-full p-8">
  <nz-select
    class="w-[250px]"
    [(ngModel)]="selectedValue"
    (ngModelChange)="onSelectionChange($event)"
    [nzPlaceHolder]="filterConfig.label"
    (click)="$event.stopPropagation()"
  >
    <nz-option *ngFor="let option of filterConfig.options" [nzValue]="option" [nzLabel]="option"></nz-option>
  </nz-select>
</div>
