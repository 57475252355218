<mat-chip-list *ngIf="data.tags?.length > 0; else emptyTag" (click)="onEvent($event)" class="flex items-center flex-wrap gap-4">
  <mat-chip *ngFor="let item of data.tags">
    <ng-container *ngIf="item?.link; else basicTag">
      <div class="flex items-center px-8 py-4 gap-4 cursor-pointer" (click)="navigate(item.link)">
        <twaice-fe-svg-icon *ngIf="item.icon" [name]="item.icon" width="20px" height="20px" [color]="item?.iconColor" />
        <span>{{ item.text }}</span>
      </div>
    </ng-container>
    <ng-template #basicTag>
      <div class="flex items-center px-8 py-4 gap-4">
        <twaice-fe-svg-icon *ngIf="item.icon" [name]="item.icon" width="20px" height="20px" [color]="item?.iconColor" />
        <span>{{ item.text }}</span>
      </div>
    </ng-template>
  </mat-chip>
</mat-chip-list>

<ng-template #emptyTag>
  <span>
    <twaice-fe-svg-icon
      *ngIf="data.customOptions?.emptyTagIcon"
      [name]="data.customOptions?.emptyTagIcon"
      width="20px"
      height="20px"
      [color]="item?.iconColor"
    />
  </span>

  <span *ngIf="data.customOptions?.emptyTagText">{{ data.customOptions?.emptyTagText }}</span>
</ng-template>
