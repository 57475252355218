/* eslint-disable @typescript-eslint/no-unused-vars -- mocked functions should not do anything */
/*
 * When we are building Angular applications, we want to use these swagger
 * decorator functions for our application, so we do not import problematic
 * '@nestjs/swagger' library when we do not need it.
 * */
// eslint-disable-next-line @typescript-eslint/naming-convention -- this function should not do anything
export function ArrayNotEmpty(...args): PropertyDecorator {
  return function () {
    // This is an empty function which replaces the class-validator function ArrayNotEmpty for Angular applications
  };
}

export function arrayNotEmpty(...args): boolean {
  // This is an empty function which replaces the class-validator function arrayNotEmpty for Angular applications
  return false;
}

// eslint-disable-next-line @typescript-eslint/naming-convention -- this function should not do anything
export function Max(...args): PropertyDecorator {
  return function () {
    // This is an empty function which replaces the class-validator function Max for Angular applications
  };
}

export function max(...args): boolean {
  // This is an empty function which replaces the class-validator function max for Angular applications
  return false;
}

// eslint-disable-next-line @typescript-eslint/naming-convention -- this function should not do anything
export function Min(...args): PropertyDecorator {
  return function () {
    // This is an empty function which replaces the class-validator function Min for Angular applications
  };
}

export function min(...args): boolean {
  // This is an empty function which replaces the class-validator function min for Angular applications
  return false;
}

// eslint-disable-next-line @typescript-eslint/naming-convention -- this function should not do anything
export function IsArray(...args): PropertyDecorator {
  return function () {
    // This is an empty function which replaces the class-validator function IsArray for Angular applications
  };
}

export function isArray(...args): boolean {
  // This is an empty function which replaces the class-validator function isArray for Angular applications
  return false;
}

// eslint-disable-next-line @typescript-eslint/naming-convention -- this function should not do anything
export function IsBooleanString(...args): PropertyDecorator {
  return function () {
    // This is an empty function which replaces the class-validator function IsBooleanString for Angular applications
  };
}

export function isBooleanString(...args): boolean {
  // This is an empty function which replaces the class-validator function isBooleanString for Angular applications
  return false;
}

// eslint-disable-next-line @typescript-eslint/naming-convention -- this function should not do anything
export function IsDateString(...args): PropertyDecorator {
  return function () {
    // This is an empty function which replaces the class-validator function IsDateString for Angular applications
  };
}

export function isDateString(...args): boolean {
  // This is an empty function which replaces the class-validator function isDateString for Angular applications
  return false;
}

// eslint-disable-next-line @typescript-eslint/naming-convention -- this function should not do anything
export function IsDefined(...args): PropertyDecorator {
  return function () {
    // This is an empty function which replaces the class-validator function IsDefined for Angular applications
  };
}

export function isDefined(...args): boolean {
  // This is an empty function which replaces the class-validator function isDefined for Angular applications
  return false;
}

// eslint-disable-next-line @typescript-eslint/naming-convention -- this function should not do anything
export function IsNegative(...args): PropertyDecorator {
  return function () {
    // This is an empty function which replaces the class-validator function IsNegative for Angular applications
  };
}

export function isNegative(...args): boolean {
  // This is an empty function which replaces the class-validator function isNegative for Angular applications
  return false;
}

// eslint-disable-next-line @typescript-eslint/naming-convention -- this function should not do anything
export function IsNotEmpty(...args): PropertyDecorator {
  return function () {
    // This is an empty function which replaces the class-validator function IsNotEmpty for Angular applications
  };
}

export function isNotEmpty(...args): boolean {
  // This is an empty function which replaces the class-validator function isNotEmpty for Angular applications
  return false;
}

// eslint-disable-next-line @typescript-eslint/naming-convention -- this function should not do anything
export function IsNumberString(...args): PropertyDecorator {
  return function () {
    // This is an empty function which replaces the class-validator function IsNumberString for Angular applications
  };
}

export function isNumberString(...args): boolean {
  // This is an empty function which replaces the class-validator function isNumberString for Angular applications
  return false;
}

// eslint-disable-next-line @typescript-eslint/naming-convention -- this function should not do anything
export function IsUUID(...args): PropertyDecorator {
  return function () {
    // This is an empty function which replaces the class-validator function IsUUID for Angular applications
  };
}

export function isUUID(...args): boolean {
  // This is an empty function which replaces the class-validator function isUUID for Angular applications
  return false;
}

// eslint-disable-next-line @typescript-eslint/naming-convention -- this function should not do anything
export function IsOptional(...args): PropertyDecorator {
  return function () {
    // This is an empty function which replaces the class-validator function IsUUID for Angular applications
  };
}
