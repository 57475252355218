import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class LoggedOutGuardService {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(): boolean {
    const isLoggedIn = this.authService.isLoggedIn();
    // if the user is already logged in and tries to access any of the pages for not-logged-in users, redirect them to homepage
    if (isLoggedIn) {
      this.router.navigate(['/']);
    }
    return !isLoggedIn;
  }
}
