import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SubPageComponent } from './sub-page.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NavigationMenuModule, SideNavLayoutModule } from '@twaice-fe/frontend/shared/ui-components';

@NgModule({
  declarations: [SubPageComponent],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatExpansionModule,
    MatListModule,
    SideNavLayoutModule,
    NavigationMenuModule,
    RouterModule.forChild([]),
  ],
  exports: [SubPageComponent],
})
export class SubPageModule {}
