<div class="spacer"></div>
<div class="card-wrapper">
  <div *ngIf="displayBackButton" class="home-link clickable" [routerLink]="['../']" data-test="back-button">
    <mat-icon>chevron_left</mat-icon>
    <span>Back to login</span>
  </div>
  <mat-card class="logged-out-card drop-shadow rounded">
    <mat-card-content class="flex items-center flex-col">
      <twaice-fe-svg-icon class="logo" name="icon_twaice_logo" color="#1562fc" width="160px" height="32px" />
      <router-outlet class="content"></router-outlet>
    </mat-card-content>
  </mat-card>
</div>
<div class="spacer"></div>
<twaice-fe-footer [appVersion]="appVersion" lightMode="true"></twaice-fe-footer>
