import * as echarts from 'echarts';

import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { CellConfigInterface } from '../../models/cell-config.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'twaice-fe-progress-bar-cell',
  templateUrl: 'progress-bar-cell.component.html',
})
export class ProgressBarCellComponent implements AfterViewInit {
  @Input() data: CellConfigInterface;

  @ViewChild('chartContainer', { static: true }) chartContainer!: ElementRef;

  constructor(private router: Router) {}

  ngAfterViewInit(): void {
    this.initChart();
  }

  navigate(link: string) {
    this.router.navigateByUrl(link);
  }

  onEvent(event) {
    event.stopPropagation();
  }

  private initChart(): void {
    const chartDom = this.chartContainer.nativeElement;
    const myChart = echarts.init(chartDom);

    if (this.data.progressBarOptions) myChart.setOption(this.data.progressBarOptions as any);
  }
}
