<!-- since we want to persist the simple span structure, we apply the copy related actions to every span element.
Using any wrapper div-s would make this component less integrable -->

<!-- if the value is null, it is a valid value, therefore it is in the value section -->
<span
  *ngIf="this.displayValue && sign"
  class="sign"
  data-test="data-display-sign"
  [class.clickable]="allowCopyAction"
  [cdkCopyToClipboard]="allowCopyAction ? displayValue : null"
  (click)="onValueCopied()"
  >{{ sign }}<span class="measurand-spacing">&nbsp;</span></span
>
<span
  *ngIf="displayValue !== undefined"
  class="value"
  data-test="data-display-value"
  [class.single-line]="singleLine"
  [class.clickable]="allowCopyAction"
  [cdkCopyToClipboard]="allowCopyAction ? displayValue : null"
  (click)="onValueCopied()"
  >{{ displayValue === null ? nullText : displayValue }}</span
>
<span class="value" data-test="data-display-not-available" *ngIf="displayValue === undefined">{{ notAvailableText }}</span>
<span
  *ngIf="this.displayValue && measurand"
  class="measurand"
  data-test="data-display-measurand"
  [class.clickable]="allowCopyAction"
  [cdkCopyToClipboard]="allowCopyAction ? displayValue : null"
  (click)="onValueCopied()"
  ><span class="measurand-spacing">&nbsp;</span>{{ measurand }}</span
>
<mat-icon
  *ngIf="allowCopyAction"
  class="copy-icon"
  data-test="data-display-copy"
  [class.clickable]="allowCopyAction"
  (click)="onValueCopied()"
  [cdkCopyToClipboard]="displayValue"
  >content_copy
</mat-icon>
<div class="value-copied" *ngIf="valueCopied">
  <span>Copied!</span>
</div>
