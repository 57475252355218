// Colors
export const colorLight = '#ffffff';
export const blue100 = '#eef6ff';
export const blue200 = '#c0daf9';
export const blue300 = '#89bcf7';
export const blue400 = '#489bff';
export const blue500 = '#1562fc';
export const blue600 = '#0051c6';
export const blue700 = '#044799';
export const blue800 = '#003972';
export const blue900 = '#0c2f4c';
export const blue400A15 = 'rgba(72, 154, 255, 0.15)';
export const blue400A05 = 'rgba(72, 154, 255, 0.05)';
export const blue500A3 = 'rgba(21, 98, 252, 0.3)';
export const blue500A1 = 'rgba(21, 98, 252, 0.1)';
export const blueGray50 = '#f7fafc';
export const blueGray100 = '#f7fafc';
export const blueGray200 = '#edf2f7';
export const blueGray300 = '#e2e8f0';
export const blueGray400 = '#cbd5e0';
export const blueGray500 = '#a0aec0';
export const blueGray600 = '#718096';
export const blueGray700 = '#4a5568';
export const blueGray800 = '#2d3748';
export const blueGray900 = '#1a202c';
export const blueGray400A15 = 'rgba(203, 213, 224, 0.15)';
export const blueGray500A15 = 'rgba(160, 174, 192, 0.15)';
export const blueGray500A40 = 'rgba(160, 174, 192, 0.4)';
export const gray50 = '#ececec';
export const gray100 = '#e6e6e6';
export const gray200 = '#cccccc';
export const gray300 = '#b3b3b3';
export const gray400 = '#999999';
export const gray500 = '#808080';
export const gray600 = '#666666';
export const gray700 = '#4d4d4d';
export const gray800 = '#333333';
export const gray900 = '#1a1a1a';
export const green100 = '#f0fff4';
export const green200 = '#c6f6d5';
export const green300 = '#9ae6b4';
export const green400 = '#68d391';
export const green500 = '#48bb78';
export const green600 = '#38a169';
export const green700 = '#2f855a';
export const green800 = '#276749';
export const green900 = '#22543d';
export const red100 = '#fff5f5';
export const red200 = '#fed7d7';
export const red300 = '#feb2b2';
export const red400 = '#fc8181';
export const red500 = '#f56565';
export const red600 = '#e53e3e';
export const red700 = '#c53030';
export const red800 = '#9b2c2c';
export const red900 = '#742a2a';
export const red500A15 = 'rgba(245, 101, 101, 0.15)';
export const orange100 = '#fffaf0';
export const orange200 = '#feebc8';
export const orange300 = '#fbd38d';
export const orange400 = '#f6ad55';
export const orange500 = '#ed8936';
export const orange600 = '#dd6b20';
export const orange700 = '#c05621';
export const orange800 = '#9c4221';
export const orange900 = '#7b341e';
export const navyBlue = '#040f26';
export const colorWarning = orange500;
export const colorDanger = red500;
export const colorSuccess = green500;
export const colorLink = blue500;
export const bgColorDanger = red300;
export const bgColorSuccess = green300;
export const colorDarkSemitransparent = 'rgba(34, 49, 74, 0.8)';
export const colorTransparent = 'transparent';
export const severityCritical = '#FF0000';
export const severityMedium = '#F0E442';
export const severityNormal = '#009E73';
export const severityLow = '#CDCFD4';
// Font sizes
export const fontSizeXs = 10;
export const fontSizeSm = 13;
export const fontSizeBase = 16;
export const fontSizeLg = 18;
export const fontSizeXl = 22;
export const fontSize2xl = 24;
export const fontSize3xl = 40;
export const fontFamilyProximaNova = 'proxima-nova, sans-serif';
// This font family is here to be used for plotly, as in plotly you can not define font-weight, so we declare font as bold instead
export const fontFamilyProximaNovaBold = 'proxima-nova-bold, sans-serif';
// Font weights
export const fontWeightLight = 300;
export const fontWeightNormal = 400;
export const fontWeightSemibold = 600;
export const fontWeightBold = 700;
// Base spacing
export const spacerXxs = 2;
export const spacerXs = 4;
export const spacerS = 8;
export const spacer = 16;
export const spacerM = 24;
export const spacerMd = 32;
export const spacerL = 64;
export const spacerXl = 72;
export const boxXs = 96;
export const boxS = 128;
export const boxM = 256;
export const boxL = 320;
// Content widths spacing
export const contentNarrow = 400;
export const contentMedium = 600;
export const contentRegular = 900;
export const contentLarge = 1100;
// Other
export const borderRadius = 1;
export const borderThin = 1;
