//TODO refactor all the places that uses this logic by importing this function
export const capitalizeFirstLetter = (input: string | string[]): string => {
  if (Array.isArray(input)) {
    const firstElement = input[0];
    return firstElement?.length ? firstElement.charAt(0).toUpperCase() + firstElement.slice(1) + ', ...' : '';
  } else {
    return input?.length ? input.charAt(0).toUpperCase() + input.slice(1) : '';
  }
};

export const capitalizeWords = (text: string): string =>
  text
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
