import { Injectable } from '@angular/core';
import {
  Axis,
  axisBottom,
  axisLeft,
  axisRight,
  axisTop,
  extent,
  NumberValue,
  scaleLinear,
  ScaleLinear,
  scaleTime,
  ScaleTime,
  select,
  Selection,
  timeFormat,
} from 'd3';
import {
  AxisInfo,
  AxisType,
  ChartDimensions,
  TickFormatOption,
  XAxisDirection,
  YAxisDirection,
} from './models/chart-utility.model';
import { tickFormat } from './time-scale-utiltiy';

@Injectable()
export class InitializeChartUtility {
  // Variables to be used in the parent class --> they are returned via getters
  // Convertors from response value to chart value
  private xScale: ScaleTime<number, number> | ScaleLinear<number, number>;

  private yScale: ScaleTime<number, number> | ScaleLinear<number, number>;

  private xAxisContainer: HTMLElement = document.createElement('div');

  private yAxisContainer: HTMLElement = document.createElement('div');

  private xGridContainer: HTMLElement = document.createElement('div');

  private yGridContainer: HTMLElement = document.createElement('div');

  private chartTitle: HTMLElement = document.createElement('div');

  private xAxisLabel: HTMLElement = document.createElement('div');

  private yAxisLabel: HTMLElement = document.createElement('div');

  private tooltipContainer: HTMLElement = document.createElement('div');

  // private use
  private xAxis;

  private yAxis;

  private tooltip: Selection<HTMLElement, unknown, null, undefined>;

  private dimensions: ChartDimensions;

  private xAxisInfo: AxisInfo;

  private yAxisInfo: AxisInfo;

  private static getXAxisDirection(
    scale: ScaleTime<number, number> | ScaleLinear<number, number>,
    axisDirection: XAxisDirection
  ): Axis<NumberValue> {
    return axisDirection === XAxisDirection.TOP ? axisTop(scale) : axisBottom(scale);
  }

  private static getYAxisDirection(
    scale: ScaleTime<number, number> | ScaleLinear<number, number>,
    axisDirection: YAxisDirection
  ): Axis<NumberValue> {
    return axisDirection === YAxisDirection.RIGHT ? axisRight(scale) : axisLeft(scale);
  }

  setChartDimensions(chartDimensions: ChartDimensions): void {
    this.dimensions = chartDimensions;
  }

  getChartDimensions(): ChartDimensions {
    return this.dimensions;
  }

  setXAxisInfo(xAxisInfo: AxisInfo): void {
    this.xAxisInfo = xAxisInfo;
  }

  getXAxisInfo(): AxisInfo {
    return this.xAxisInfo;
  }

  setYAxisInfo(yAxisInfo: AxisInfo): void {
    this.yAxisInfo = yAxisInfo;
  }

  getYAxisInfo(): AxisInfo {
    return this.yAxisInfo;
  }

  getXScale(): ScaleTime<number, number> | ScaleLinear<number, number> {
    return this.xScale;
  }

  setXScale(scale: ScaleTime<number, number> | ScaleLinear<number, number>) {
    this.xScale = scale;
  }

  getYScale(): ScaleTime<number, number> | ScaleLinear<number, number> {
    return this.yScale;
  }

  getXAxis(): HTMLElement {
    return this.xAxisContainer;
  }

  getYAxis(): HTMLElement {
    return this.yAxisContainer;
  }

  getXAxisGrid(): HTMLElement {
    return this.xGridContainer;
  }

  getYAxisGrid(): HTMLElement {
    return this.yGridContainer;
  }

  getChartTitle(): HTMLElement {
    return this.chartTitle;
  }

  getXAxisLabel(): HTMLElement {
    return this.xAxisLabel;
  }

  getYAxisLabel(): HTMLElement {
    return this.yAxisLabel;
  }

  getTooltip(): HTMLElement {
    return this.tooltipContainer;
  }

  setXAxis() {
    const xAxisGroup = select(this.xAxisContainer)
      .append('svg')
      .append('g')
      .attr('id', 'x_axis')
      .attr('class', 'x-axis')
      .attr('data-test', 'x-axis');

    this.translateAxis(xAxisGroup, AxisType.X_AXIS);

    this.xAxis = this.xAxisInfo.isAxisInTime
      ? this.scaleAxisTime(this.xAxisInfo.valueRange, AxisType.X_AXIS, this.xAxisInfo.axisDirection)
      : this.scaleAxisLinear(this.xAxisInfo.valueRange, AxisType.X_AXIS, this.xAxisInfo.axisDirection);

    const padding = this.xAxisInfo.innerPadding;

    if (padding) {
      xAxisGroup
        .call(this.xAxis)
        .selectAll('text')
        .each(function () {
          const label = select(this);
          const words = [...label.text().split('\n')];
          const lineHeight = 24;
          let dy = 0;
          label.text(null);

          while (words.length > 0) {
            const word = words.pop();
            label.append('tspan').attr('x', -padding).attr('dy', dy).attr('text-anchor', 'middle').text(word.trim());
            dy += lineHeight;
          }
        });
    } else {
      xAxisGroup.call(this.xAxis);
    }
  }

  setYAxis() {
    const yAxisGroup = select(this.yAxisContainer)
      .append('svg')
      .append('g')
      .attr('id', 'y_axis')
      .attr('class', 'y-axis')
      .attr('data-test', 'y-axis');

    this.translateAxis(yAxisGroup, AxisType.Y_AXIS);

    this.yAxis = this.yAxisInfo.isAxisInTime
      ? this.scaleAxisTime(this.yAxisInfo.valueRange, AxisType.Y_AXIS, this.yAxisInfo.axisDirection)
      : this.scaleAxisLinear(this.yAxisInfo.valueRange, AxisType.Y_AXIS, this.yAxisInfo.axisDirection);

    yAxisGroup.call(this.yAxis);
  }

  setXAxisGrid() {
    const xGridLines = select(this.xGridContainer).append('svg').append('g').attr('class', 'grid').attr('data-test', 'x-grid');

    this.translateAxis(xGridLines, AxisType.X_AXIS, true);

    xGridLines.call(
      this.xAxis
        .ticks(this.xAxisInfo.ticksCount)
        .tickSize(-this.dimensions.height + this.dimensions.axisOffsetBottom + this.dimensions.axisOffsetTop)
    );
  }

  setYAxisGrid() {
    const yGridLines = select(this.yGridContainer).append('svg').append('g').attr('class', 'grid').attr('data-test', 'y-grid');

    this.translateAxis(yGridLines, AxisType.Y_AXIS, true);

    yGridLines.call(
      this.yAxis
        .ticks(this.yAxisInfo.ticksCount)
        .tickSize(-this.dimensions.width + this.dimensions.axisOffsetLeft + this.dimensions.axisOffsetRight)
    );
  }

  setChartTitle(title: string, hasMarginLeft: boolean) {
    select(this.chartTitle)
      .append('svg')
      .append('text')
      .attr('x', hasMarginLeft ? (3 / 2) * this.dimensions.marginLeft : 0)
      .attr('y', (1 / 2) * this.dimensions.marginTop)
      .attr('class', 'chart-title')
      .attr('text-anchor', 'start')
      .text(title);
  }

  setXAxisLabel(label: string) {
    const xLabel = select(this.xAxisLabel)
      .append('svg')
      .append('text')
      .attr('class', 'x-axis-label')
      .attr('text-anchor', 'middle')
      .text(label);
    if (this.xAxisInfo.axisDirection === XAxisDirection.BOTTOM) {
      xLabel
        .attr('x', (this.dimensions.width + this.dimensions.marginLeft + this.dimensions.marginRight) / 2)
        .attr(
          'y',
          this.dimensions.height + this.dimensions.marginTop + this.dimensions.marginBottom - this.dimensions.marginBottom / 3
        );
    } else {
      xLabel
        .attr('x', (this.dimensions.width + this.dimensions.marginLeft + this.dimensions.marginRight) / 2)
        .attr('y', this.dimensions.marginTop);
    }
  }

  setYAxisLabel(label: string) {
    const extraPadding = this.dimensions.marginTop / 4;

    const yLabel = select(this.yAxisLabel)
      .append('svg')
      .append('text')
      .attr('class', 'y-axis-label')
      .attr('text-anchor', 'middle')
      .text(label);

    if (this.yAxisInfo.axisDirection === YAxisDirection.RIGHT) {
      yLabel
        .attr('x', (this.dimensions.height + this.dimensions.marginTop + this.dimensions.marginBottom) / 2 + extraPadding)
        .attr(
          'y',
          -(
            this.dimensions.width +
            this.dimensions.marginLeft +
            this.dimensions.marginRight -
            (this.dimensions.marginRight * 2) / 3
          )
        )
        .attr('transform', 'rotate(90)');
    } else {
      yLabel
        .attr('x', -(this.dimensions.height + this.dimensions.marginTop + this.dimensions.marginBottom) / 2 - extraPadding)
        .attr('y', (this.dimensions.marginLeft * 2) / 3)
        .attr('transform', 'rotate(-90)');
    }
  }

  drawTooltip() {
    this.tooltip = select(this.tooltipContainer).attr('class', 'tooltip').attr('data-test', 'tooltip');
  }

  onTooltipMouseover(bar: SVGGElement, text: string) {
    const el = select(bar).node().getBoundingClientRect();
    const tooltip = this.tooltip.node();

    this.tooltip.html(`${text}`).style('visibility', 'visible').style('opacity', 1);

    const halfTooltip = tooltip.clientWidth / 2;

    // Set x position
    let x = el.x + (this.dimensions.axisOffsetLeft * 2) / 3 - halfTooltip;

    if (x < this.dimensions.marginLeft + this.dimensions.axisOffsetLeft) {
      // When position is outside from the left border
      x = this.dimensions.marginLeft + this.dimensions.axisOffsetLeft;
      this.tooltip.style('right', '');
      this.tooltip.style('left', `${Math.round(x)}px`);
    } else if (x + tooltip.clientWidth > this.dimensions.width + this.dimensions.axisOffsetLeft) {
      // When position is outside from the right border
      x = this.dimensions.marginRight + this.dimensions.axisOffsetRight;
      this.tooltip.style('left', '');
      this.tooltip.style('right', `${Math.round(x)}px`);
    } else {
      this.tooltip.style('right', '');
      this.tooltip.style('left', `${Math.round(x)}px`);
    }

    // Set y position
    const y = this.dimensions.height;

    this.tooltip.style('bottom', `${Math.round(y)}px`);

    select(bar).classed('active', true);
  }

  onTooltipMouseleave(bar: SVGGElement) {
    this.tooltip.style('visibility', 'hidden').style('opacity', 0);

    select(bar).classed('active', false);
  }

  private scaleAxisTime(valueRange: number[], axisType: AxisType, axisDirection: XAxisDirection | YAxisDirection) {
    const tickSize = 4;

    const domain = [Math.min(...valueRange), Math.max(...valueRange)];

    const interval = domain[1] - domain[0];

    let range;

    let axis;

    if (axisType === AxisType.X_AXIS) {
      range = [this.dimensions.axisOffsetLeft, this.dimensions.width - this.dimensions.axisOffsetRight];
      this.xScale = <ScaleTime<number, number>>scaleTime(extent(domain), range);
      const customTickFormat = this.xAxisInfo.tickFormat !== TickFormatOption.D3_DEFAULT;
      axis = InitializeChartUtility.getXAxisDirection(this.xScale, <XAxisDirection>axisDirection)
        .ticks(this.xAxisInfo.ticksCount)
        .tickValues(this.xAxisInfo.tickValues)
        .tickFormat(
          customTickFormat
            ? (d: number | Date, i) => {
                if (this.xAxisInfo.tickFormat === TickFormatOption.D3_DEFAULT) return;
                if (this.xAxisInfo.tickFormat === TickFormatOption.TWO_LiNES) {
                  const date = typeof d === 'number' ? new Date(d * 1000) : d;
                  const formattedDay = timeFormat('%e')(date);
                  const formattedMonth = timeFormat('%b')(date);
                  return date.getDate() === 1 || i === 0
                    ? `${formattedDay}\n${formattedMonth}`
                    : (this.xAxisInfo.reduceTicks && i % 2 ? '' : formattedDay) + '\n';
                }
                return tickFormat(interval, d as Date, i === 0);
              }
            : undefined
        )
        .tickSize(tickSize)
        .tickPadding(5);
    } else {
      range = [this.dimensions.height - this.dimensions.axisOffsetBottom, this.dimensions.axisOffsetTop];
      this.yScale = <ScaleTime<number, number>>scaleTime(extent(domain), range);
      const customTickFormat = this.yAxisInfo.tickFormat !== TickFormatOption.D3_DEFAULT;
      axis = InitializeChartUtility.getYAxisDirection(this.yScale, <YAxisDirection>axisDirection)
        .ticks(this.yAxisInfo.ticksCount)
        .tickValues(this.yAxisInfo.tickValues)
        .tickFormat(
          customTickFormat
            ? (d: number | Date, i) => {
                if (this.xAxisInfo.tickFormat === TickFormatOption.D3_DEFAULT) return;
                if (this.xAxisInfo.tickFormat === TickFormatOption.TWO_LiNES) {
                  const date = typeof d === 'number' ? new Date(d * 1000) : d;
                  const formattedDay = timeFormat('%e')(date);
                  const formattedMonth = timeFormat('%b')(date);
                  return date.getDate() === 1 || i === 0
                    ? `${formattedDay}\n${formattedMonth}`
                    : (this.xAxisInfo.reduceTicks && i % 2 ? '' : formattedDay) + '\n';
                }
                return tickFormat(interval, d as Date, i === 0);
              }
            : undefined
        )
        .tickSize(tickSize)
        .tickPadding(5);
    }

    return axis;
  }

  private scaleAxisLinear(valueRange: number[], axisType: AxisType, axisDirection: XAxisDirection | YAxisDirection) {
    const tickSize = 4;

    const domain = [Math.min(...valueRange), Math.max(...valueRange)];

    let range;

    if (axisType === AxisType.X_AXIS) {
      range = [this.dimensions.axisOffsetLeft, this.dimensions.width - this.dimensions.axisOffsetRight];
      this.xScale = scaleLinear(extent(domain), range);
      return InitializeChartUtility.getXAxisDirection(this.xScale, <XAxisDirection>axisDirection)
        .ticks(this.xAxisInfo.ticksCount)
        .tickValues(this.xAxisInfo.tickValues)
        .tickFormat(typeof this.xAxisInfo.tickFormat == 'function' ? this.xAxisInfo.tickFormat : undefined)
        .tickSize(tickSize)
        .tickPadding(5);
    } else {
      range = [this.dimensions.height - this.dimensions.axisOffsetBottom, this.dimensions.axisOffsetTop];
      this.yScale = scaleLinear(extent(domain), range);
      return InitializeChartUtility.getYAxisDirection(this.yScale, <YAxisDirection>axisDirection)
        .ticks(this.yAxisInfo.ticksCount)
        .tickValues(this.yAxisInfo.tickValues)
        .tickFormat(typeof this.yAxisInfo.tickFormat == 'function' ? this.yAxisInfo.tickFormat : undefined)
        .tickSize(tickSize)
        .tickPadding(5);
    }
  }

  private translateAxis(axis: Selection<SVGGElement, unknown, null, undefined>, axisType: AxisType, isGrid?: boolean) {
    const translateX = () => {
      if (this.yAxisInfo.axisDirection === YAxisDirection.LEFT) {
        return isGrid ? this.dimensions.axisOffsetLeft : this.dimensions.marginLeft / 2;
      } else {
        const baseWidth = this.dimensions.width - this.dimensions.marginRight / 2;
        return isGrid ? baseWidth + this.dimensions.marginRight / 2 - this.dimensions.axisOffsetRight : baseWidth;
      }
    };

    const translateY = () => {
      if (this.xAxisInfo.axisDirection === XAxisDirection.BOTTOM) {
        return isGrid ? this.dimensions.height - this.dimensions.axisOffsetBottom : this.dimensions.height;
      } else {
        return isGrid ? this.dimensions.axisOffsetTop : this.dimensions.marginTop / 2;
      }
    };

    if (axisType === AxisType.X_AXIS) {
      axis.attr('transform', `translate(0, ${translateY()})`);
    } else {
      axis.attr('transform', `translate(${translateX()}, 0)`);
    }
  }
}
