import { ChartSection } from './models/chart-section';

export class PredictionChartCalculations {
  /*
   * This function takes the two points - (t1, v1), (t2, v2) - and the target value
   * and with a help of tangens value calculates the timestamp for the target value
   * on the line between the defined points
   * */
  static getTimestampForValueInLine(t1: number, t2: number, v1: number, v2: number, targetValue: number): number {
    const timeDiff = t2 - t1;
    const valDiff = v2 - v1;
    const deltaGoalVal = v2 - targetValue;

    return Math.round(t2 - (timeDiff / valDiff) * deltaGoalVal);
  }

  /*
   * This function takes the two points - (t1, v1), (t2, v2) - and the target timestamp
   * and with a help of tangens value calculates the value for the target timestamp
   * on the line between the defined points
   * */
  static getValueForTimestampInLine(t1: number, t2: number, v1: number, v2: number, targetTimestamp: number): number {
    const timeDiff = t2 - t1;
    const valDiff = v2 - v1;
    const deltaGoalTimestamp = targetTimestamp - t1;

    return v1 + (valDiff * deltaGoalTimestamp) / timeDiff;
  }

  /*
   * This function takes the provided values and the limit and provides a ChartSection mapping,
   * which provides maps to the sections above the limit (e.g.:EOL SOH) and under the limit,
   * that are useful in multiple calculations.
   *
   * If we provide the timestamps we also define the passingLimitTimestamp, which is the timestamp
   * where the graph line pases through the limit - which is used in multiple places as well.
   *
   * This enables us to only calculate these things once and reuse them when needed
   * */
  static splitIntoSectionsByLimit(values: number[], limit: number, timestamps?: number[]): ChartSection[] {
    if (!values || !values.length) {
      return [];
    }
    const sections: ChartSection[] = [{ start: 0, underLimit: values[0] <= limit }];
    let prevVal: number = values[0];
    let currVal: number;

    for (let i = 1; i < values.length; i++) {
      currVal = values[i];
      if (currVal <= limit && prevVal > limit) {
        // if this is the first value in a stream to get bellow limit value,
        // push new section with curr index to array
        sections[sections.length - 1].end = i - 1;
        sections.push({ start: i, underLimit: true });
      } else if (prevVal <= limit && currVal > limit) {
        // if this is the first value in a stream to get back above the limit value,
        // push the previous index to last existing section
        sections[sections.length - 1].end = i - 1;
        sections.push({ start: i, underLimit: false });
      }
      prevVal = values[i];
    }
    sections[sections.length - 1].end = values.length - 1;

    // If timestamps are provided, we also calculate at what timestamp each section passes the limit value
    if (timestamps && timestamps.length) {
      sections.forEach((section: ChartSection, i) => {
        if (i !== 0) {
          const endOfPrevIndex = sections[i - 1].end;
          section.passingLimitTimestamp = PredictionChartCalculations.getTimestampForValueInLine(
            timestamps[endOfPrevIndex],
            timestamps[endOfPrevIndex + 1],
            values[endOfPrevIndex],
            values[endOfPrevIndex + 1],
            limit
          );
        }
      });
    }

    return sections;
  }
}
