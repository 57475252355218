import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { MenuTriggerDirective } from './menu-trigger.directive';
import { MenuComponent } from './menu.component';

@NgModule({
  declarations: [MenuComponent, MenuTriggerDirective, MenuItemComponent],
  imports: [CommonModule, OverlayModule],
  exports: [MenuComponent, MenuTriggerDirective, MenuItemComponent],
})
export class MenuModule {}
