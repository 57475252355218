import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from '@twaice-fe/shared/models';
import { AUTH_FEATURE_KEY } from '../reducers/auth.reducer';

export const getAuthState = createFeatureSelector<AuthState>(AUTH_FEATURE_KEY);

export const getUser = createSelector(getAuthState, (state: AuthState) => state.user);

export const getMfaState = createSelector(getAuthState, (state: AuthState) => state.mfa);

export const getErrors = createSelector(getAuthState, (state: AuthState) => state.errors);
