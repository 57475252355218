<div class="stacked-bar-chart-wrapper" twFakeLoading [twFakeLoadingState]="isLoading">
  <div [class.loaded]="!isLoading && !alert" class="stacked-bar-chart" #stackedBarChart data-test="stacked-bar-chart"></div>
  <div class="bg-white shadow-lg p-16 rounded-lg w-fit" #tooltip id="tooltip">
    <div class="flex items-start flex-col">
      <div class="text-base font-semibold">{{ tooltipContent?.x * 1000 | date: 'MMM dd, yyyy' }}</div>
      <div *ngFor="let item of info | keyvalue: keepOrder" class="flex items-center gap-16">
        <div [style.background]="item.value.color" class="w-8 h-8"></div>
        <div class="font-normal text-sm leading-base text-tertiary-text ml-2">{{ item.value.label }}</div>
        <div class="text-base font-semibold">{{ tooltipContent?.y[item.key] ?? 0 | number: '1.0-0' }}</div>
      </div>
    </div>
  </div>
  <twaice-fe-alert-panel
    *ngIf="alert"
    [type]="alert.type"
    [title]="alert.title"
    [subTitle]="alert.subTitle"
    data-test="alert-panel"
  ></twaice-fe-alert-panel>
</div>
