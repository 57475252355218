import { createAction, props } from '@ngrx/store';
import { CustomerType, FeatureFlagsEnum, Solution } from '@twaice-fe/shared/models';

export const loadFeatureFlagsSuccess = createAction(
  '[Config/Feature flags] Load configs Success',
  props<{ featureFlags: string[] | FeatureFlagsEnum[] }>()
);

export const loadSolutionConfigSuccess = createAction(
  '[Config/Solutions] Load configs success',
  props<{ solutions: Solution[] }>()
);

export const loadConfigSuccess = createAction(
  '[Config] Load configs success',
  props<{
    featureFlags: string[] | FeatureFlagsEnum[];
    solutions: Solution[];
    customerType: CustomerType;
    baseUrl: string;
    customerBk: string;
  }>()
);

export const internalUIConfigChanged = createAction('[Config] Internal UI config changed');
