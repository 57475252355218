import { Component } from '@angular/core';
import { environment } from '@env/fe';
import { ActivatedRoute, UrlSegment } from '@angular/router';

@Component({
  selector: 'twaice-fe-logged-out',
  templateUrl: './logged-out.component.html',
  styleUrls: ['./logged-out.component.scss'],
})
export class LoggedOutComponent {
  appVersion: string;
  displayBackButton: boolean;

  constructor(public route: ActivatedRoute) {
    this.appVersion = environment.version;
    this.route.url.subscribe((url: UrlSegment[]) => {
      this.displayBackButton = !!url.length;
    });
  }
}
