import { Component, DestroyRef, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EventType, Router } from '@angular/router';
import { environment } from '@env/fe';
import { Store } from '@ngrx/store';
import { AuthService } from '@twaice-fe/frontend/shared/services';
import { actions, selectors } from '@twaice-fe/frontend/shared/store';
import {
  ACCOUNT_SETTINGS_ROUTE,
  ANALYTICS_UPSELL_ROUTE,
  ENERGY_ASSET_DASHBOARD_ROUTE,
  ENERGY_OVERVIEW_ROUTE,
  ENERGY_MONITORING_ROUTE,
  ENERGY_PERFORMANCE_MANAGER_ROUTE,
  MOBILITY_BASE_ROUTE,
  MOBILITY_DEPRECATED_BASE_ROUTE,
  MODEL_LIBRARY_BASE_ROUTE,
  ON_DEMAND_SERVICES_ROUTE,
  PRIVACY_POLICY_ROUTE,
  REPORTING_ROUTE,
  ENERGY_DETAIL_INCIDENT_ROUTE,
  ENERGY_SAFETY_ROUTE,
} from '@twaice-fe/shared/constants';
import { CustomerType } from '@twaice-fe/shared/models';
import { distinctUntilChanged, filter, first, switchMap, tap } from 'rxjs';

const { incidentsDetectionActions, systemActions, performanceManagerActions } = actions;
const { configsSelectors, systemSelectors } = selectors;
@Component({
  selector: 'twaice-fe-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss'],
})
export class DashboardLayoutComponent implements OnInit, OnDestroy {
  appVersion: string;
  showNavigationMenu = false;
  showGlobalNavigatioMenu = false;
  isFleet = false;
  isPerformanceManagerSolution = false;
  isAssetDashboard = false;
  isSafetySolution = false;
  isIncidentDetails = false;
  isMonitoringSolution = false;

  fetchedSystemIds: { id: string; calculationLevel: number }[] = [];

  private customerType: CustomerType;

  constructor(private destroy$: DestroyRef, protected store: Store, private authService: AuthService, private router: Router) {
    this.appVersion = environment.version;

    this.router.events
      .pipe(
        filter((routerEvent) => routerEvent.type === EventType.NavigationEnd),
        switchMap(() => this.authService.isInternalUser()),
        takeUntilDestroyed(this.destroy$)
      )
      .subscribe((isInternalUser) => {
        this.isPerformanceManagerSolution = this.router.url.includes(ENERGY_PERFORMANCE_MANAGER_ROUTE);
        this.isFleet = this.router.url.includes(MOBILITY_BASE_ROUTE) || this.router.url.includes(MOBILITY_DEPRECATED_BASE_ROUTE);
        this.isAssetDashboard = this.router.url.includes(ENERGY_ASSET_DASHBOARD_ROUTE);
        this.isSafetySolution = this.router.url.includes(ENERGY_SAFETY_ROUTE);
        this.isMonitoringSolution = this.router.url.includes(ENERGY_MONITORING_ROUTE);
        this.isIncidentDetails = this.router.url.includes(ENERGY_DETAIL_INCIDENT_ROUTE);
        this.showNavigationMenu = !this.isSolutionWithoutNavigationMenu();
        this.showGlobalNavigatioMenu = this.isSolutionWithGlobalSystemScope();

        // for internal users in fleet we always show the navigation menut
        if (isInternalUser && this.router.url.includes(MOBILITY_BASE_ROUTE)) {
          this.showNavigationMenu = true;
        }
      });

    this.store
      .select(configsSelectors.getCustomerType)
      .pipe(takeUntilDestroyed())
      .subscribe((customerType) => (this.customerType = customerType));
  }

  ngOnInit(): void {
    if (this.canFetchAssetsData()) {
      this.fetchGlobalAssetData();
    }
  }

  ngOnDestroy(): void {
    if (!this.isFleet) {
      this.store.dispatch(incidentsDetectionActions.cancelIncidentsRequest());
    }
  }

  private canFetchAssetsData(): boolean {
    return !(
      this.router.url.includes(ACCOUNT_SETTINGS_ROUTE) ||
      this.router.url.includes(PRIVACY_POLICY_ROUTE) ||
      this.router.url.includes(ANALYTICS_UPSELL_ROUTE) ||
      this.router.url.includes(MODEL_LIBRARY_BASE_ROUTE)
    );
  }

  private isSolutionWithGlobalSystemScope(): boolean {
    return (
      this.router.url.includes(REPORTING_ROUTE) ||
      this.router.url.includes(ON_DEMAND_SERVICES_ROUTE) ||
      this.router.url.includes(MODEL_LIBRARY_BASE_ROUTE)
    );
  }

  private isSolutionWithoutNavigationMenu(): boolean {
    return (
      this.isSolutionWithGlobalSystemScope() ||
      this.router.url.includes(ENERGY_OVERVIEW_ROUTE) ||
      this.router.url.includes(ANALYTICS_UPSELL_ROUTE) ||
      this.router.url.includes(MOBILITY_BASE_ROUTE) ||
      this.router.url.includes(MOBILITY_DEPRECATED_BASE_ROUTE) ||
      (this.router.url.includes(REPORTING_ROUTE) && this.customerType === CustomerType.MOBILITY) ||
      this.router.url.includes(PRIVACY_POLICY_ROUTE) ||
      this.router.url.includes(ACCOUNT_SETTINGS_ROUTE)
    );
  }

  private fetchGlobalAssetData() {
    const includes: Record<string, boolean> = this.isFleet
      ? { includeMetadata: true, onlySystems: true }
      : {
          includeMetadata: true,
          includeSafetyScore: true,
          includeSystemStatistics: true,
          onlySystems: false,
        };
    this.store.dispatch(systemActions.fetchSystems(includes));

    if (!this.isFleet) {
      this.store.dispatch(incidentsDetectionActions.fetchIncidents());
      this.store.dispatch(incidentsDetectionActions.fetchIncidentsAnalytics());
      this.store.dispatch(systemActions.fetchSystemsHealthKpiData({ includeMetadata: true, includeHealthStateKpis: true }));
      this.store.dispatch(performanceManagerActions.fetchEnergyLossData());
      this.fetchEfcData();
    }
  }

  private fetchEfcData() {
    this.store
      .select(systemSelectors.isSystemFetchLoading)
      .pipe(
        distinctUntilChanged(),
        tap((isLoading) => {
          if (isLoading) {
            this.store.dispatch(systemActions.systemLoadingSuccess());
          }
        }),
        filter((isLoading) => !isLoading),
        first(),
        switchMap(() => this.store.select(systemSelectors.getSystemList)),
        tap((systemList) => {
          const systemIds = systemList
            .filter((system) => !system?.kpis?.efcSum && system?.metadata)
            .map(({ id, metadata }) => ({ id, calculationLevel: metadata.calculationLevel ?? 2 }));

          if (systemIds.length > 0 && (this.fetchedSystemIds.length === 0 || this.fetchedSystemIds === systemIds)) {
            this.fetchedSystemIds = systemIds;
            this.store.dispatch(systemActions.fetchSystemsEfcData({ systemIds }));
          }
        })
      )
      .subscribe();
  }
}
