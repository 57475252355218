<div class="svg-icon">
  <ng-container [ngSwitch]="source">
    <mat-icon
      *ngSwitchCase="'material'"
      [class]="classList"
      [attr.fill]="fill"
      [style.color]="color"
      [style.stroke]="stroke"
      [style.width]="size ?? width"
      [style.height]="size ?? height"
      >{{ name }}</mat-icon
    >

    <i-tabler
      *ngSwitchCase="'tabler'"
      [name]="name"
      [class]="classList"
      [attr.fill]="fill"
      [style.color]="color"
      [style.stroke]="stroke"
      [style.width]="size ?? width"
      [style.height]="size ?? height"
    ></i-tabler>

    <mat-icon
      *ngSwitchDefault
      [class]="classList"
      [style.fill]="fill"
      [style.color]="color"
      [style.stroke]="stroke"
      [style.width]="size ?? width"
      [style.height]="size ?? height"
      [style.paint-order]="'stroke'"
      [svgIcon]="name"
    ></mat-icon>
  </ng-container>
</div>
