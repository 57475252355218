<div
  class="w-full h-full flex flex-col items-center justify-center text-gray opacity-60 text-center text-sm font-bold"
  [ngClass]="{ 'absolute top-0': overlay }"
>
  <ng-container *ngIf="show">
    @for (tip of tips; let index = $index; track index) {
    <div [@transitionTip] *ngIf="index === tipIndex" class="flex items-center h-0">Tip: {{ tip }}</div>
    }
  </ng-container>
</div>
